import { bemName } from "../util/bemName";
import "./ToggleSwitch.scss";

const componentName = "ToggleSwitch";

type Props = {
    checked: boolean;
    left?: string;
    right?: string;
    toggle: () => void;
};

export const ToggleSwitch = ({ checked, left, right, toggle }: Props) => {
    const handleClick = () => {
        toggle();
    }

    return (
        <div className={bemName(componentName) }>
            <label className={bemName(componentName, "label", "left")}>{left}</label>
            <span className={bemName(componentName, "switch")} onClick={handleClick}>
                <input type="checkbox" className={bemName(componentName, "input")} checked={checked}/>
                <span className={bemName(componentName, "visual")}></span>
            </span>
            <label className={bemName(componentName, "label", "right")}>{right}</label>
        </div>
    );
}