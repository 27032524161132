import React, { useState } from "react"

import { bemName } from "../util/bemName";
import "./Checkbox.scss";

const componentName = "Checkbox";

interface Props {
    blockName?: string;
    checked?: boolean;
    text?: string;
    checkedText?: string;
    onChange?: (checked: boolean) => Promise<void>|void;
}

export const Checkbox: React.FunctionComponent<Props> = ({ blockName, text, checkedText, onChange, ...props }) => {
    const [checked, setChecked] = useState<boolean>(props.checked === true);

    const handleClick = () => {
        setChecked(!checked);

        onChange && onChange(!checked);
    }

    const block = blockName || componentName;

    return (
        <div className={bemName(block, "container", checked ? "checked" : undefined)} onClick={handleClick}>
            <div className={bemName(block, "boxContainer")}>
                <div className={bemName(block, "box", checked ? "checked" : undefined)} />
            </div>
            <div className={bemName(block, "text")}>{checked && checkedText ? checkedText : text}</div>
        </div>
    );
}