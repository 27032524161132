/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserStatus {
    ACTIVE = 'Active',
    CANCELLED = 'Cancelled',
    COMPROMISED = 'Compromised',
    INACTIVE = 'Inactive',
    PENDING = 'Pending',
    PROFILED = 'Profiled',
    UNVERIFIED = 'Unverified',
    VERIFIED = 'Verified',
}
