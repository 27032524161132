import { useEffect, useState } from 'react';
import Cards, { CallbackArgument, Focused } from 'react-credit-cards-2';
import { FormProps } from '../forms/Form';
import { bemName } from '../util/bemName';
import { Input } from './Input';

import "react-credit-cards-2/dist/es/styles.scss";
import "./CreditCardInput.scss";

const componentName = "CreditCardInput";

export type CreditCardData = {
    number?: string;
    name?: string;
    expiry?: string;
    cvc?: string;
    valid: boolean;
};

type CreditCardProps = FormProps<CreditCardData>;

export const useCreditCardInput = (props: CreditCardProps) => {
    const { value, updateValue: updateData } = props;

    const [data, setData] = useState<CreditCardData>(value ?? { valid: false });
    const [dataValid, setDataValid] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(false);

    useEffect(
        () => {
            const valid = dataValid && validate();

            const newData = {
                ...data,
                valid,
            }

            setValid(valid);

            updateData?.(newData, valid);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, dataValid]
    )

    const validate = () => {
        return !!data?.name && data.name.length > 2 &&
            !!data?.expiry && data.expiry.length >= 4 && data.expiry.length <= 5 &&
            !!data?.cvc && data.cvc.length >= 3 && data.cvc.length <= 4;
    }

    return {
        data,
        setData,
        dataValid,
        setDataValid,
        valid,
    };
}

export const CreditCardInput = (props: CreditCardProps) => {
    const {
        data,
        setData,
        dataValid,
        setDataValid,
    } = useCreditCardInput(props);

    const [focused, setFocused] = useState<Focused>("number");
    const [maxLength, setMaxLength] = useState<number>(16);

    const handleInputChange = (evt: any) => {
        const { name, value } = evt.target;

        const newData = { ...data, [name]: value };

        setData?.(newData);
    }

    const handleInputFocus = (evt: any) => {
        setFocused((prev) => evt.target.name);
    }

    const handleCallback = (type: CallbackArgument, isValid: boolean) => {
        // console.log("@@@@ CC callback", type, isValid);

        setDataValid(isValid);

        setMaxLength(type.maxLength);
    }

    return (
        <div className={bemName(componentName, "container")}>
            <div className={bemName(componentName, "image")}>
                <Cards
                    number={data?.number ?? ""}
                    expiry={data?.expiry ?? ""}
                    cvc={data?.cvc ?? ""}
                    name={data?.name ?? ""}
                    focused={focused ?? ""}
                    callback={handleCallback}
                />
            </div>
            <div className={bemName(componentName, "form")}>
                <Input
                    className={bemName(componentName, "creditCard", focused !== "number" && !dataValid ? "error" : undefined)}
                    type="string"
                    name="number"
                    placeholder="Card Number"
                    format={"#### #### #### #### ###"}
                    maxLength={maxLength + (maxLength > 16 ? 4 : 3)}
                    value={data?.number}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                />
                <Input
                    type="string"
                    name="name"
                    placeholder="Name"
                    value={data?.name}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                />
                <Input
                    type="string"
                    name="expiry"
                    placeholder="Valid Thru"
                    format={"##/##"}
                    maxLength={5}
                    value={data?.expiry}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                />
                <Input
                    type="string"
                    name="cvc"
                    placeholder="CVC"
                    format={"####"}
                    maxLength={4}
                    value={data?.cvc}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                />
            </div>
        </div>
    );
};