import GotQuestion from "../components/GotQuestion";
import ReferAFriend from "../components/ReferAFriend";
import { FormControl, NavigationButtons } from "../panel/BuyPanelsPanel";
import { withComponentName } from "../util/bemName";

import "./BuyPanelsCompleteForm.scss";

type BuyPanelsCompleteFormProps = {
    formControl: FormControl;
    email?: string; 
};

const componentName = "BuyPanelsCompleteForm";
const bemClass = withComponentName(componentName);

export const BuyPanelsCompleteForm = (props: BuyPanelsCompleteFormProps) => {
    const { formControl, email } = props;     
    return (
        <div className={bemClass("container")}>
            <div className={bemClass("textSection")}>
                <p className={bemClass("text")}>Oops, we forgot to mention that our CEO has eagerly accepted a challenge to personally call our first 1,000 customers! We say 'oops' because he loves a good conversation and is known to enthusiastically talk about SolarCloud. Enjoy the chat!</p>
                <p className={bemClass("subText")}>1. Copy of the Replacement PDS</p>
                <p className={bemClass("subText")}>2. Receipt of Payment</p>
                <p className={bemClass("subText")}>3. Next steps email</p>
                <p className={bemClass("subText")}>4. Email from our CEO</p>
                    <div className={bemClass("wrapperEmail")}>
                        <p className={bemClass("subText")}>Paperwork sent to </p>
                        <p className={bemClass("subText")}>{email}</p>
                    </div>
                <p className={bemClass("availableText")}>The above paperwork is also available in your dashboard 24/7</p>
            </div>
            <div className={bemClass("navButton")}>
                <NavigationButtons formControl={formControl} />
            </div>
            <GotQuestion/>
            <ReferAFriend/>
        </div>
    );
}
