/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrderPaymentType {
    DEPOSIT = 'Deposit',
    HOLD = 'Hold',
    PAYMENT = 'Payment',
    REFUND = 'Refund',
}
