import { bemName } from "../util/bemName";
import { EnabledFunction, SidebarItem, SidebarItemProps, SidebarSelectProps } from "./SidebarItem";

import { ReactComponent as LogoIcon } from "../res/logos/solarcloud/logo-icon.svg";

import { useContext } from "react";
import ProfileContext, { Profile } from "../contexts/ProfileContext";
import { SolarCloudIconType } from "../data/SolarCloudIcon";
import { useLocalStorageMutation, useLocalStorageQuery } from "../hooks/LocalStorage";
import { useAuthenticationQuery } from "../util/authentication";
// import i18next from '../i18n';

import "./Sidebar.scss";
// import { useTranslation } from "react-i18next";
import { LanguageSelect } from "./LanguageSelect";
// import { logger } from "../util/logger";

type SidebarSpacerProps = {
    type: "spacer";
}

export type SidebarEntry = SidebarItemProps | SidebarSpacerProps | SidebarSelectProps;

export const sidebarEntry = (icon: SolarCloudIconType, titleId: string, to: string, enabled?: boolean | EnabledFunction): SidebarEntry => {
    return {
        type: "item",
        icon,
        titleId,
        to,
        enabled,
    };
}

export const sidebarSpacer = (): SidebarEntry => {
    return {
        type: "spacer",
    };
}

interface Props {
    profile: Profile;
    entries: Array<SidebarEntry>;
    pageName: string;
}

export function Sidebar(props: Props) {
    const profile = useContext(ProfileContext);
    const { data: open } = useLocalStorageQuery<boolean>("SidebarOpen", true);
    const { mutateAsync: setOpen } = useLocalStorageMutation<boolean>("SidebarOpen");
    const { data: authentication } = useAuthenticationQuery();

    const user = authentication?.user;
    const federatedUser = authentication?.loggedIn && authentication?.federated;

    const enablement = { profile, user, federatedUser };

    // logger.log(`@@@@ open: ${open}`);

    if (!open) {
        return (
            <SidebarButton toggle={() => setOpen(!open)} />
        )
    }

    const items = props.entries.map(
        (entry, index) => {
            switch (entry.type) {
            case "item":
                return (
                    <SidebarItem key={`item-${index}`} {...entry} enablement={enablement} />
                );

            case "select":
                return (
                    <></>
                )

            case "spacer":
                return (
                    <SidebarSpacer key={"spacer-" + index} />
                );
            }

            return <></>;
        }
    );

    return (
        <div className={bemName("Sidebar", undefined, open ? "open" : "closed")}>
            <SidebarHeader pageName={"SolarCloud"} open={open} toggle={() => setOpen(!open)} />
            <div className={bemName("Sidebar", "container", open ? "open" : "closed")}>
                <div></div>
                <div>{items}</div>
                <div></div>
            </div>
        </div>
    )
}

function SidebarSpacer() {
    return (
        <div className={bemName("SidebarSpacer")}>
            <div className={bemName("SidebarSpacer", "container")}>
                <div className={bemName("SidebarSpacer", "line")} />
            </div>
        </div>
    )
}

function SidebarHeader(props: { pageName: string, open: boolean, toggle?: () => void }) {
    return (
        <div className={bemName("SidebarHeader", undefined, props.open ? "open" : "closed")}>
            <div className={bemName("SidebarHeader", "container", props.open ? "open" : "closed")}>
                <button className={bemName("SidebarHeader", "iconButton", props.open ? "open" : "closed")} onClick={() => props.toggle && props.toggle() } >
                    <LogoIcon className={bemName("SidebarHeader", "icon", props.open ? "open" : "closed")} />
                </button>
                {
                    props.open &&
                    <div className={bemName("SidebarHeader", "pageName", props.open ? "open" : "closed")}>{props.pageName}</div>
                }
            </div>
            <LanguageSelect />
        </div>
    )
}

function SidebarButton(props: { toggle?: () => void }) {
    return (
        <div className={bemName("SidebarButton")}>
            <div className={bemName("SidebarButton", "container")}>
                <button className={bemName("SidebarButton", "iconButton")} onClick={() => props.toggle && props.toggle() } >
                    <LogoIcon className={bemName("SidebarButton", "icon")} />
                </button>
            </div>
        </div>
    )
}
