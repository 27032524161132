import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Validator from "validator";
import { Contact, EmailType, PhoneNumberType, DefaultService as UsersService } from "../api/SolarCloudApi";
import { ContactType } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import { Input } from "../components/Input";
import { error, infoWithTimeout } from "../components/Messages";
import { Page } from "../components/Page";
import ProfileContext from "../contexts/ProfileContext";
import { bemName } from "../util/bemName";
import "./ContactPage.scss";
import { useAuthenticationQuery } from "../util/authentication";

export const ContactPage = () => {
    const profile = useContext(ProfileContext);
    const [contact, setContact] = useState<Contact>({});
    const [selections, setSelections] = useState<string[]>([]);
    const [complete, setComplete] = useState<boolean>(false);
    const navigate = useNavigate();

    const { data: authentication } = useAuthenticationQuery();
    const user = authentication?.user;

    useEffect(
        () => {
            const updatedContact: Contact = {
                type: ContactType.INQUIRY,
                email: user?.emails?.filter(email => email.type === EmailType.DEFAULT)[0]?.address,
                phoneNumber: user?.phoneNumbers?.filter(phoneNumber => phoneNumber.type === PhoneNumberType.DEFAULT)[0]?.number,
                givenName: user?.givenName,
                familyName: user?.familyName,
                country: profile.country,
                user: user,
            };

            setContact(updatedContact);

            validate(updatedContact);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const updateSelection = (selected: boolean, item: string): void => {
        if (!selected) {
            setSelections(selections.filter(selection => selection !== item));
        } else if (selections.filter(selection => selection === item).length === 0) {
            setSelections([ ...selections, item ]);
        }
    };

    const updateContact = (updatedContact: Contact): void => {
        setContact(updatedContact);

        validate(updatedContact);
    }

    const updateComment = (event: any): void => {
        updateContact({
            ...contact,
            comment: event.target.value
        });
    };

    const updatePhoneNumber = (event: any): void => {
        updateContact({
            ...contact,
            phoneNumber: event.target.value
        });
    };

    const updateEmail = (event: any): void => {
        updateContact({
            ...contact,
            email: event.target.value
        });
    };

    const validate = (updatedContact: Contact) => {
        const valid =
            Validator.isEmail(updatedContact.email || "") ||
            (updatedContact.phoneNumber !== undefined && updatedContact.phoneNumber !== "");

        setComplete(valid);
    }

    const submitForm = async (event: any): Promise<void> => {
        const finalComment =
            (
                contact.comment ?
                    contact.comment : 
                    ""
            ) + (
                selections.length !== 0 ?
                    selections.reduce((previous, current) => previous ? previous + "\n* " + current : "\n* " + current, "\n\nAreas of Interest:") :
                    ""
            );

        const finalContact: Contact = {
            ...contact,

            comment: finalComment.trim()
        }

        // logger.log("@@@@ form submitted", finalContact);

        await UsersService.registerContact(finalContact)
        .catch(
            (err) => {
                error(err.body?.message || err.message, "Inquiry failed");
            }
        );

        infoWithTimeout(3000, "Thank you for your enquiry. Someone will be in contact soon.");

        navigate(-1);
    };

    return (
        <Page title="Contact us">
            <h4 className={bemName("ContactPage", "text")}>
                We get it, sometimes it’s nice to talk to a real human.
            </h4>
            <label className={bemName("ContactPage", "listTitle")}>You want us to provide more information:</label>
            <ul className={bemName("ContactPage", "listContainer")}>
                <li className={bemName("ContactPage", "listItem")}>
                    <input className={bemName("ContactPage", "checkbox")} type="checkbox" id="reason-r01" onChange={(e) => updateSelection(e.target.checked, 'On our pricing')} />
                    <label className={bemName("ContactPage", "listLabel")} htmlFor="reason-r01">On our pricing</label>
                </li>
                <li className={bemName("ContactPage", "listItem")}>
                    <input className={bemName("ContactPage", "checkbox")} type="checkbox" id="reason-r02" onChange={(e) => updateSelection(e.target.checked, 'On the environmental impact')} />
                    <label className={bemName("ContactPage", "listLabel")} htmlFor="reason-r02">On the environmental impact</label>
                </li>
                <li className={bemName("ContactPage", "listItem")}>
                    <input className={bemName("ContactPage", "checkbox")} type="checkbox" id="reason-r03" onChange={(e) => updateSelection(e.target.checked, 'On how it works')} />
                    <label className={bemName("ContactPage", "listLabel")} htmlFor="reason-r03">On how it works</label>
                </li>
                <li className={bemName("ContactPage", "listItem")}>
                    <input className={bemName("ContactPage", "checkbox")} type="checkbox" id="reason-r04" onChange={(e) => updateSelection(e.target.checked, 'About SolarCloud')} />
                    <label className={bemName("ContactPage", "listLabel")} htmlFor="reason-r04">About SolarCloud</label>
                </li>
                <li className={bemName("ContactPage", "listItem")}>
                    <input className={bemName("ContactPage", "checkbox")} type="checkbox" id="reason-r05" onChange={(e) => updateSelection(e.target.checked, 'On the next steps for account activation')} />
                    <label className={bemName("ContactPage", "listLabel")} htmlFor="reason-r05">On the next steps for account activation</label>
                </li>
                <li className={bemName("ContactPage", "listItem")}>
                    <input className={bemName("ContactPage", "checkbox")} type="checkbox" id="reason-r06" onChange={(e) => updateSelection(e.target.checked, 'About how we pay your bill')} />
                    <label className={bemName("ContactPage", "listLabel")} htmlFor="reason-r06">About how we pay your bill</label>
                </li>
            </ul>
            <div className={bemName("ContactPage", "commentContainer")}>
                <label htmlFor="inputBrief" className={bemName("ContactPage", "commentLabel")}>Anything else we can help you with:</label>
                <textarea className={bemName("ContactPage", "comment")} name="requestBrief" id="inputBrief" onChange={updateComment} placeholder="Brief description about your request" required autoFocus></textarea>
            </div>
            <div className={bemName("ContactPage", "phoneContainer")}>
                <Input placeholder="Phone number" value={contact.phoneNumber} onChange={updatePhoneNumber}/>
            </div>
            <div className={bemName("ContactPage", "emailContainer")}>
                <Input placeholder="Email" value={contact.email} onChange={updateEmail} />
            </div>
            <div className={bemName("ContactPage", "buttonContainer")}>
                <Button text="Request information" disabled={!complete} onClick={submitForm} />
                <button className={bemName("ContactPage", "cancelLink")} onClick={() => navigate(-1)}>Cancel</button>
            </div>
        </Page>
    );
}

export default ContactPage;
