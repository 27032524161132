// import { useTranslation } from "react-i18next";
// import { bemName } from "../util/bemName";
// import { ChangeEvent, useEffect, useState } from "react";
// import { languageInfo } from "../i18n";

// import "./LanguageSelect.scss";

// const componentName = "LanguageSelect";

export const LanguageSelect = () => {
    return (<></>);

    // const [language, setLanguage] = useState<string>();

    // const { i18n, ready } = useTranslation();

    // console.log("@@@@ ", language, ready, i18n.language);

    // useEffect(
    //     () => {
    //         if (ready) {
    //             setLanguage(i18n.language);
    //         }
    //     },
    //     [ ready, i18n.language ]
    // )

    // const languageSelected = async (event: ChangeEvent<HTMLSelectElement>) => {
    //     const newLanguage = event.target.value;

    //     setLanguage(newLanguage);

    //     await i18n.changeLanguage(newLanguage);
    // }

    // const options = languageInfo.map(
    //     (language, index) => 
    //         <option key={`language-${index}`} value={language.code}>{language.icon} {language.name}</option>
    // )

    // return (
    //     <div className={bemName(componentName)}>
    //         <select className={bemName(componentName, "select")} value={language} onChange={languageSelected}>
    //             {options}
    //         </select>
    //     </div>
    // );
}