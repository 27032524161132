import { useEffect, useState } from "react";
import { Input } from "../components/Input";
import { bemName } from "../util/bemName";

import { useEnergyBillInfoMutation, useEnergyBillInfoQuery } from "../hooks/LocalStorage";
import { useQuerySummary } from "../hooks/QuerySummary";
import { FormProps } from "./Form";

import { FormControl, NavigationButtons } from "../panel/BuyPanelsPanel";
import "./EnergyBillForm.scss";

const componentName = "EnergyBillForm";

export type EnergyBillInfo = {
    energyBill?: number;
    energyBills: (number | undefined)[];
    averageBill?: number;
    valid: boolean;
}

export const emptyEnergyBillInfo: EnergyBillInfo = {
    energyBills: [undefined, undefined, undefined, undefined],
    valid: false,
};

type EnergyBillProps = Omit<FormProps<EnergyBillInfo, "energyBillInfo", "updateEnergyBillInfo">, "energyBillInfo"> & {
    formControl: FormControl;
};

const useEnergyBillsController = ({ updateEnergyBillInfo, formControl }: EnergyBillProps) => {
    const [energyBillInfo, setEnergyBillInfo] = useState<EnergyBillInfo>(emptyEnergyBillInfo);

    const querySummary = useQuerySummary();
    const { data: savedEnergyBillInfo, isLoading: isEnergyBillInfoLoading } = useEnergyBillInfoQuery(querySummary);
    const { mutate: saveEnergyBillInfo } = useEnergyBillInfoMutation(querySummary);

    useEffect(
        () => {
            if (!isEnergyBillInfoLoading && savedEnergyBillInfo) {
                const updatedEnergyBillInfo = calculate(savedEnergyBillInfo, true);
                setEnergyBillInfo(updatedEnergyBillInfo);
                updateEnergyBillInfo?.(updatedEnergyBillInfo);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ isEnergyBillInfoLoading ]
    )

    const updateEnergyBills = (value: number, index?: number) => {
        if (!Number.isNaN(value)) {
            const updatedEnergyBillInfo: EnergyBillInfo = calculate(
                index === undefined ?
                    {
                        ...energyBillInfo,
                        energyBill: value,
                    }
                    :
                    {
                        ...energyBillInfo,
                        energyBills: 
                            energyBillInfo
                                .energyBills
                                .map(
                                    (energyBill, i) => i === index ? value : energyBill
                                )
                    },
                index !== undefined
            );

            setEnergyBillInfo(updatedEnergyBillInfo);

            updateEnergyBillInfo?.(updatedEnergyBillInfo, updatedEnergyBillInfo.valid);
        }
    }

    const calculate = (energyBillInfo: EnergyBillInfo | undefined, fromBills: boolean): EnergyBillInfo => {
        if (!energyBillInfo) {
            return emptyEnergyBillInfo;
        }

        const { energyBill, energyBills } = energyBillInfo;

        const billSum = energyBills
            ?.reduce(
                (previous, current) => 
                    previous !== undefined && current !== undefined ? 
                        previous + current 
                        : 
                        undefined, 
                 0
            );

        if (energyBills && billSum) {
            return {
                ...energyBillInfo,
                averageBill: fromBills || energyBillInfo.energyBill === undefined ? billSum / energyBills.length : energyBillInfo.energyBill,
                valid: true,
            };
        }

        return {
            ...energyBillInfo,
            averageBill: energyBillInfo.energyBill,
            valid: !!energyBill,
        };
    }

    const updateSavedEnergyBillInfo = () => {
        saveEnergyBillInfo(energyBillInfo);
    }

    return {
        energyBillInfo,
        updateEnergyBills,
        updateSavedEnergyBillInfo,
        formControl,
    }
}

const EnergyBillForm = (props: EnergyBillProps) => {
    const {
        energyBillInfo: { energyBills },
        updateEnergyBills,
        updateSavedEnergyBillInfo,
        formControl,
    } =  useEnergyBillsController(props);

    const historicBills = energyBills.map(
        (lastBill, index) => {
            return (
                <div key={`energyBill-${index}`} className={bemName(componentName, "bill")}>
                    {/* <div className={bemName(componentName, "billLabel")}>Quarterly energy bill (A$)</div> */}
                    {/* <div className={bemName(componentName, "billValue")}> */}
                        <Input type="number" value={lastBill ?? ""} onChange={(event) => updateEnergyBills(parseInt(event.target.value), index)} />
                    {/* </div> */}
                </div>
            );
        }
    );

    return (
        <div className={bemName(componentName, "energyBillContainer")}>
            <div className={bemName(componentName, "energyBillDescription")}>
                <p>To help you calculate the best number of panels for you, you can either your average quarterly energy bill or up to four of your quarterly energy bills.</p>
                <p>This is totaly optional and you can skip this step if you like.</p>
            </div>
            {/* <div className={bemName(componentName, "energyBillGroup")}>
                <div className={bemName(componentName, "energyBillLabel")}>Please enter your average quarterly energy bill (A$)</div>
                <div className={bemName(componentName, "energyBill")}>
                    <Input type="number" value={energyBill ?? ""} min={1} onChange={(event) => updateEnergyBills(parseInt(event.target.value))} />
                </div>
            </div> */}
            {/* <div className={bemName(componentName, "divider")}>Or enter your last 4 quarterly energy bills</div> */}
            <div className={bemName(componentName, "bills")}>
            {
                historicBills
            }
            </div>
            <NavigationButtons formControl={formControl} preNext={updateSavedEnergyBillInfo} />
        </div>
    );
}

export default EnergyBillForm;