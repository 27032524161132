import { DateTime } from "luxon";
import { bemName } from "../util/bemName";
import "./MonthSpinner.scss"

const componentName = "MonthSpinner";

interface Props {
    blockName?: string;
    month?: number;
    year?: number;
    onDecrement: () => Promise<void>|void;
    onIncrement: () => Promise<void>|void;
}

export const MonthSpinner: React.FunctionComponent<Props> = ({ blockName, month, year, onDecrement, onIncrement }) => {
    const block = blockName || componentName;

    const now = DateTime.now().toLocal();
    const monthYear = DateTime.local(year || now.year, month || now.month, 1);
    const rightArrowModifier = monthYear.year === now.year && monthYear.month === now.month ? "disabled" : undefined;

    return (
        <div className={bemName(block)}>
            <div className={bemName(block, "arrow", "left")} onClick={onDecrement}>{"<"}</div>
            <div className={bemName(block, "text")}>{monthYear.monthLong} {monthYear.year}</div>
            <div className={bemName(block, "arrow", "right", rightArrowModifier)} onClick={onIncrement}>{">"}</div>
        </div>
    );
}