import React from 'react';
import { withComponentName } from '../util/bemName';
import './EnjoyFlexibility.scss';

const EnjoyFlexibility = () => {
    const componentName = 'EnjoyFlexibility';
    const bemClass = withComponentName(componentName);

    return (
        <div className={bemClass('text')}>
            Enjoy the flexibility of your refundable 10% deposit within the initial three months of payment.
        </div>
    );
};

export default EnjoyFlexibility;
