import { useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { DefaultService as ReferenceService } from '../api/SolarCloudApi';
import { errorHandler } from "./Error";
import { useGeoCountryQuery } from "./IpGeoLocation";
import { useCountryOverrideQuery } from "./LocalStorage";
import { QuerySummary } from "./QuerySummary";
import { useUuid } from "./Utils";

// ReferenceApi.BASE = "";

export const useActiveCountriesQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();
    
    const query = useQuery(
        "countries",
        async () => {
            // ReferenceApi.TOKEN = undefined;

            const countries = await ReferenceService.countryInfoSearchActive();

            return countries;
        },
        {
            staleTime: Infinity,
            onError: errorHandler,
        }
    );

    querySummary?.updateQuery(id, "countries", query);

    return query;
}

export const useAvailableCountriesQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const { data: countries, isLoading } = useActiveCountriesQuery(querySummary);

    const queryClient = useQueryClient();

    useEffect(
        () => {
            if (isLoading) {
                return;
            }

            queryClient.invalidateQueries("availableCountries");
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading]
    )
    
    const query = useQuery(
        "availableCountries",
        async () => {
            return countries?.filter(country => country?.available);
        },
        {
            staleTime: Infinity,
            onError: errorHandler,
        }
    );

    querySummary?.updateQuery(id, "availableCountries", query);

    return query;
}

export const useCountryQuery = (querySummary?: QuerySummary) => {
    const { data: geoCountry } = useGeoCountryQuery(querySummary);
    const { data: countryOverride } = useCountryOverrideQuery(querySummary);

    return countryOverride || geoCountry;
}

export const useCountryAvailable = (querySummary?: QuerySummary) => {
    const { data: countries } = useActiveCountriesQuery(querySummary);
    const { data: geoCountry } = useGeoCountryQuery(querySummary);
    const { data: countryOverride } = useCountryOverrideQuery(querySummary);

    // logger.log("@@@@", "countryOverride:", countryOverride, "geoCountry:", geoCountry, "countries:", countries);

    return (
        (countries ?? [])
            .filter(country => country.code === geoCountry?.code || country.code === countryOverride?.code)
    )[0] !== undefined;
}

export const useOrganisationTypesQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();
    
    const query = useQuery(
        "organisationTypes",
        async () => {
            // ReferenceApi.TOKEN = undefined;

            const referenceData = await ReferenceService.organisationType();

            return referenceData;
        },
        {
            staleTime: Infinity,
            onError: errorHandler,
            onSettled(data, error) {
                // logger.log("Get organisation types. data:", data, "error:", error);
            },
        }
    );

    querySummary?.updateQuery(id, "organisationTypes", query);

    return query;
}
