import { TermOrCondition } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import { Checkbox } from "../components/Checkbox";
import { error } from "../components/Messages";
import { useTermsAndConditionsMutation } from "../hooks/User";

import { bemName } from "../util/bemName";
import "./TermsAndConditionsForm.scss";

const componentName = "TermsAndConditionsForm";

interface ItemProps {
    blockName: string;
    termOrCondition: TermOrCondition;
}

export const TermOrConditionItem = ({ blockName, termOrCondition }: ItemProps) => {
    const { mutateAsync: updateTermOrCondition } = useTermsAndConditionsMutation();

    const downloadPdf = async () => {
        const url = termOrCondition.downloadUrl!;
        const names = url.split("/");
        const name = names[names.length - 1];

        const anchor = document.createElement("a");
        anchor.download = name;
        anchor.target = "_blank";
        anchor.href = url;
        try {
            document.body.append(anchor);

            // logger.log(`@@@@ anchor: ${anchor}`);

            anchor.click();
        } finally {
            document.body.removeChild(anchor);
        }
    }

    const handleChange = async (checked: boolean) => {
        try {
            await updateTermOrCondition(
                {
                    ...termOrCondition,
                    accepted: checked,
                }
            );
        } catch (err: any) {
            error(err.body?.message || err.message, "Terms and Conditions Update Error")
        }
    }

    return (
        <div key={termOrCondition.uuid} className={bemName(blockName, "item")}>
            <div className={bemName(blockName, "checkbox")}>
                <Checkbox text="Tick me" checkedText="Accepted" checked={termOrCondition.accepted} onChange={handleChange} />
            </div>
            <div className={bemName(blockName, "textBlock")} >
                <div className={bemName(blockName, "text")}>
                    {termOrCondition.text}
                </div>
                {
                    termOrCondition.downloadUrl &&
                    <div className={bemName(blockName, "button")}>
                        <Button text="Download" onClick={() => downloadPdf()} />
                    </div>
                }
            </div>
        </div>
    )
}

interface Props {
    blockName?: string;
    termsAndConditions: TermOrCondition[];
}

export const TermsAndConditionsForm = ({ blockName, termsAndConditions }: Props) => {
    const block = blockName || componentName;

    if (!termsAndConditions) {
        return <div />;
    }

    const termsAndConditionItems = termsAndConditions.map(
        (termOrCondition, index) => {
            return (<TermOrConditionItem key={index} blockName={block} termOrCondition={termOrCondition} />);
        }
    );

    return (
        <div className={bemName(block, "container")}>
            <div className={bemName(block, "preamble")}>
                But this is where the lawyers step in. We just need you to agree to some important product and financial terms and conditions. In lay-mans terms we legally can not transfer any money back to you to reduce your energy bill 'enough though you own the panel(s)' unless you read the below and tick all the boxes.
            </div>
            {termsAndConditionItems}
        </div>
    );
}
