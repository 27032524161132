import { Auth } from "aws-amplify";
import { useMutation, useQuery } from "react-query";
import { Allocation, Contact, Organisation, TermOrCondition, User, DefaultService as UsersService } from '../api/SolarCloudApi';
import { invalidateMutateDependencies } from "./Dependencies";
import { errorHandler } from "./Error";
import { QuerySummary } from "./QuerySummary";
import { useUuid } from "./Utils";

// UsersApi.BASE = "";

// export const useProfileQuery = (querySummary?: QuerySummary) => {
//    const id = useUuid();

//     const query = useQuery(
//         "user",
//         async () => {
//             try {
//                 UsersApi.TOKEN = getIdToken;

//                 const user = await UsersService.getProfile();
    
//                 return user;
//             } catch (error: unknown) {
//                 return undefined;
//             }
//         },
//         {
//             staleTime: Infinity,
//         }
//     );

//     querySummary?.updateQuery(id, "useProfileQuery", query);

//     return query;
// }

export type CreateUserProps = {
    email: string;
    password: string;
};

export const useCreateCognitoUserMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const mutationFunction = async ({ email, password }: CreateUserProps): Promise<void> => {
        await Auth.signUp({
            username: email!,
            password: password!,
            attributes: {
                email: email!
            }
        });
    }

    const mutation = useMutation(
        "cognitoUser",
        mutationFunction,
        {
            onSuccess: () => {
                invalidateMutateDependencies("cognitoUser");
            }
        }
    );

    querySummary?.updateMutation(id, "cognitoUser", mutation);

    return mutationFunction;
}

export const useUserMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const mutationFunction = async (user: User): Promise<User> => {
        // UsersApi.TOKEN = getAccessToken;

        const updatedUser = await UsersService.updateProfile(user);

        return updatedUser;
    };

    const mutation = useMutation(
        mutationFunction,
        {
            onSuccess: (data, variables, context) => {
                invalidateMutateDependencies("user");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "user", mutation);

    return mutation;
}

export const useCreateOrganisationMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const mutationFunction = async (organisation: Organisation): Promise<Organisation> => {
        // UsersApi.TOKEN = getAccessToken;

        const updatedOrganisation = await UsersService.createOrganisation(organisation);

        return updatedOrganisation;
    };

    const mutation = useMutation(
        mutationFunction,
        {
            onSuccess: (data, variables, context) => {
                invalidateMutateDependencies("organisations");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "organisations", mutation);

    return mutation;
}

export const useTermsAndConditionsQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const query = useQuery(
        "termsAndConditions",
        async () => {
            // UsersApi.TOKEN = getAccessToken;

            const response = await UsersService.termsAndConditionsGetAcive();

            return response.collection;
        },
        {
            staleTime: Infinity,
            onError: errorHandler,
        }
    );

    querySummary?.updateQuery(id, "termsAndConditions", query);

    return query;
}

export const useTermsAndConditionsMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const mutationFunction = async (termOrCondition: TermOrCondition): Promise<void> => {
        // UsersApi.TOKEN = getAccessToken;

        if (termOrCondition.accepted)
            await UsersService.termsAndConditionsAccept(termOrCondition.uuid!);
        else
            await UsersService.termsAndConditionsReject(termOrCondition.uuid!);
    };

    const mutation = useMutation(
        mutationFunction,
        {
            onSuccess: (data, variables, context) => {
                invalidateMutateDependencies("termsAndConditions", undefined, { invalidateSelf: true });
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "termsAndConditions", mutation);

    return mutation;
}

export const useEnergyCompaniesQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const query = useQuery(
        "energyCompanies",
        async () => {
            // UsersApi.TOKEN = getAccessToken;

            const response = await UsersService.getActiveEnergyCompanies();

            return response.collection;
        },
        {
            staleTime: Infinity,
            onError: errorHandler,
        }
    );

    querySummary?.updateQuery(id, "energyCompanies", query);

    return query;
}

export const usePersonalAllocationsQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const query = useQuery(
        "personalAllocations",
        async () => {
            // UsersApi.TOKEN = getAccessToken;

            const response = await UsersService.getPersonalAllocations();

            return response.collection;
        },
        {
            staleTime: Infinity,
            onError: errorHandler,
        }
    );

    querySummary?.updateQuery(id, "personalAllocations", query);

    return query;
}

export const usePersonalAllocationsMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const mutationFunction = async (allocations: Allocation[]): Promise<void> => {
        // UsersApi.TOKEN = getAccessToken;

        await UsersService.updatePersonalAllocations(allocations);
    };

    const mutation = useMutation(
        mutationFunction,
        {
            onSuccess: (data, variables, context) => {
                invalidateMutateDependencies("personalAllocations");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "personalAllocations", mutation);

    return mutation;
}

export const useRegisterContactMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const mutation = useMutation(
        async (contact: Contact) => {
            // UsersApi.TOKEN = undefined;

            await UsersService.registerContact(contact);
        },
        {
            onSuccess: (data, variables, context) => {
                invalidateMutateDependencies("registerContact");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "registerContact", mutation);

    return mutation;
}