/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TotalPeriod {
    FOREVER = 'Forever',
    LAST_MONTH = 'LastMonth',
    LAST_QUARTER = 'LastQuarter',
    MONTH = 'Month',
    THIS_MONTH = 'ThisMonth',
    THIS_QUARTER = 'ThisQuarter',
}
