import { useNavigate } from "react-router-dom";
import { TrackerEventPriority } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import { Panel } from "../components/Panel";
import { bemName } from "../util/bemName";

import { ReactComponent as AllocationsIcon, ReactComponent as DocumentsIcon, ReactComponent as OrderIcon } from "../res/icons/Document.svg";
import { ReactComponent as PanelsIcon } from "../res/icons/Panels.svg";
import { ReactComponent as PaymentIcon, ReactComponent as PayoutIcon } from "../res/icons/Payment.svg";
import { ReactComponent as ProfileIcon } from "../res/icons/Settings.svg";

import { useTrackerEventQuery } from "../hooks/Tracker";
import "./NotificationPanel.scss";

const componentName = "Notification";

type EventTypeInfo = {
    label: string;
    icon: React.ReactNode;
    link: string;
}

const eventTypeInfo: { [key: string]: EventTypeInfo } = {
    "Agreements": {
        label: "Review",
        icon: <PanelsIcon />,
        link: "/dashboard/termsandconditions"
    },
    "Documents": {
        label: "Review", 
        icon: <DocumentsIcon />,
        link: "/dashboard/termsandconditions" 
    },
    "Order": { 
        label: "View", 
        icon: <OrderIcon />,
        link: "/dashboard/orders" 
    },
    "OrganisationAllocations": {
        label: "Allocate", 
        icon: <AllocationsIcon />,
        link: "/dashboard/allocations" 
    },
    "Payment": {
        label: "View", 
        icon: <PaymentIcon />,
        link: "/dashboard/payments" 
    },
    "Payout": {
        label: "Allocate", 
        icon: <PayoutIcon />,
        link: "/dashboard/allocations" 
    },
    "Profile": {
        label: "Update", 
        icon: <ProfileIcon />,
        link: "/dashboard/profile" 
    },
    "UserAllocations": {
        label: "Allocate", 
        icon: <AllocationsIcon />,
        link: "/dashboard/allocations" 
    },
};

export const NotificationPanel = () => {
    const { data: trackerEvents } = useTrackerEventQuery();
    const navigate = useNavigate();

    if (
        !trackerEvents ||
        trackerEvents.length === 0 ||
        (
            trackerEvents[0].priority !== TrackerEventPriority.CRITICAL &&
            trackerEvents[0].priority !== TrackerEventPriority.HIGH
        )
    ) {
        return null;
    }

    const event = trackerEvents[0];

    const info = eventTypeInfo[event.type as string]

    return (
        <Panel title={event.title} blockName="Notification">
            <div className={bemName(componentName, "bodyContainer")}>
                <div className={bemName(componentName, "bodyContent")}>
                    {event.messages?.map((message, index) => <p key={`p_${index}`} className={bemName(componentName, "bodyItem")}>{message}</p>)}
                </div>
                {
                    info &&
                    <div className={bemName(componentName, "links")}>
                        <div className={bemName(componentName, "link")}>
                            <Button text={info.label} onClick={() => navigate(info.link)}/>
                        </div>
                    </div>
                }
            </div>
            <div className={bemName(componentName, "iconContainer")} style={{stroke: "black"}}>
                {info.icon}
            </div>
        </Panel>
    );
}

export default NotificationPanel;
