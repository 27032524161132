import { UseMutationResult, UseQueryResult } from "react-query";
import { QueryName } from "./Dependencies";

enum BemModifier {
    Loading = "loading",
    Fetching = "fetching",
    Mutating = "mutating",
    Success = "success",
    Error = "error",
}

export type Summary = {
    isLoading: boolean;
    isFetching: boolean;
    isMutating: boolean;
    isSuccess: boolean;
    isError: boolean;
    errors?: unknown[];
    summaries?: Summary[];
    bemModifiers: string[];
};

type SummaryWithId = {
    id: string;
    name: QueryName;
    error?: unknown;
} & Summary;

export type QuerySummary = { 
    get: () => Summary;
    updateQuery: <TData = unknown, TError = unknown> (id: string, name: QueryName, queryResult: UseQueryResult<TData, TError>, qualifier?: string) => void;
    updateMutation: <TData = unknown, TError = unknown, TVariables = unknown, TContext = unknown> (id: string, name: QueryName, mutationResult: UseMutationResult<TData, TError, TVariables, TContext>, qualifier?: string) => void;
};

const updateBemModifiers = <T extends Summary>(summary: Omit<T, "bemModifiers">): T => {
    const newSummary = { ...summary, bemModifiers: [] as string[] } as T;

    if (newSummary.isLoading) {
        newSummary.bemModifiers.push(BemModifier.Loading);
    }
    if (newSummary.isFetching) {
        newSummary.bemModifiers.push(BemModifier.Fetching);
    }
    if (newSummary.isMutating) {
        newSummary.bemModifiers.push(BemModifier.Fetching);
    }
    if (newSummary.isSuccess) {
        newSummary.bemModifiers.push(BemModifier.Success);
    }
    if (newSummary.isError) {
        newSummary.bemModifiers.push(BemModifier.Error);
    }

    return newSummary;
}

export const useQuerySummary = (): QuerySummary => {
    // const summariesRef = useRef<Summary[]>([]);
    const summaries: { [id: string]: SummaryWithId } = {};

    const getSummary = (): Summary => {
        // logger.log("@@@@ summaries", summaries, summaries.length);

        const combined: Summary = {
            isLoading: false,
            isFetching: false,
            isMutating: false,
            isSuccess: false,
            isError: false,
            summaries: Object.values(summaries),
            bemModifiers: [],
        };

        (Object.values(summaries)).forEach(
            summary => {
                combined.isLoading ||= summary.isLoading === true;
                combined.isFetching ||= summary.isFetching === true;
                combined.isMutating ||= summary.isMutating === true;
                combined.isSuccess ||= summary.isSuccess === true;
                combined.isError ||= summary.isError === true;
                
                if (summary.errors) {
                    combined.errors = combined.errors ? combined.errors?.concat(summary.errors) : summary.errors;
                } else if (summary.error) {
                    combined.errors?.push(summary.error);
                }
            }
        );

        return updateBemModifiers(combined);
    };

    const updateQuery = <TData = unknown, TError = unknown> (id: string, name: QueryName, queryResult: UseQueryResult<TData, TError>, qualifier?: string) => {
        // logger.log("@@@@ updateQuery", id, name, queryResult);

        const { isLoading, ...remaining } = queryResult;

        const qualifiedId = qualifier ? `${id}_${qualifier}`: id;

        summaries[qualifiedId] = updateBemModifiers(
            {
                id: qualifiedId,
                name,
                isLoading,
                ...remaining,
                isMutating: false,
            }
        );
    };

    const updateMutation = <TData = unknown, TError = unknown, TVariables = unknown, TContext = unknown> (id: string, name: QueryName, mutationResult: UseMutationResult<TData, TError, TVariables, TContext>, qualifier?: string) => {
        // logger.log("@@@@ updateMutation", id, name, mutationResult);
        
        const { isLoading, ...remaining } = mutationResult;

        const qualifiedId = qualifier ? `${id}_${qualifier}`: id;

        summaries[qualifiedId] = updateBemModifiers(
            {
                id: qualifiedId,
                name,
                isMutating: isLoading,
                ...remaining,
                isLoading: false,
                isFetching: false,
            }
        );
    };

    const querySummary = {
        get: getSummary,
        updateQuery,
        updateMutation,
    }

    return querySummary;
}