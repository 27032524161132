export type Validated<T> = T & {
    valid?: boolean;
}

export const stripValidated = <T>(obj?: Validated<T>): T | undefined => {
    if (!obj) {
        return undefined;
    }

    const raw = { ...obj };
    delete raw.valid;

    return raw;
}