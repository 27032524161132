import { QueryClient } from "react-query";

export const queryClient = new QueryClient();

export type InvalidOptions = {
    invalidate?: boolean;
    invalidateSelf?: boolean;
}

const dependencies = {
    authentication: [ /^.*$/ ],
    catalog: [],
    cognitoUser: [],
    countries: [ "availableCountries"],
    availableCountries: [],
    energyCompanies: [],
    environment: [],
    geoCountryCode: [],
    isNewOrderUpdating: [],
    localStorage: [],
    localStorage_buyPanels: [ "newOrder" ],
    loginForm: [],
    myOrders: [],
    newOrder: [],
    newOrderCheckout: [ "newOrder" ],
    newOrderMintPayment: [ "authentication", "newOrder", "user" ],
    organisations: [ "user" ],
    organisationTypes: [],
    personalAllocations: [ "tracker" ],
    registerContact: [],
    signupData: [],
    signupForm: [],
    termsAndConditions: [ "tracker" ],
    totals: [],
    tracker: [],
    user: [],
    verficiationCode: [],
};

export type QueryName = keyof typeof dependencies;

export const invalidateDependencies = (name: QueryName, id?: string, options?: InvalidOptions): void => {
    const queryKeys = queryClient.getQueryCache().getAll().map(query => query.queryKey.toString());
    console.log(`@@@@ >>> invalidateDependencies: name=${name}`, queryKeys);

    const queryDependencies = dependencies[name];

    if (queryDependencies) {
        for (const dependency of queryDependencies) {
            if (typeof(dependency) === "string") {
                queryClient.invalidateQueries(dependency);
                if (id) {
                    queryClient.invalidateQueries(`${dependency}_${id}`);
                }    
            } else {
                const regex = dependency as RegExp;

                for (const queryKey of queryKeys) {
                    if (queryKey.match(regex)) {
                        queryClient.invalidateQueries(queryKey);
                    }
                }
            }
        }
    }

    if (options?.invalidateSelf !== false) {
        queryClient.invalidateQueries(`${name}`);
        if (id) {
            queryClient.invalidateQueries(`${name}_${id}`);
        }
    }

    console.log(`@@@@ <<<< invalidateDependencies: name=${name}`, queryClient.getQueryCache().getAll().map(query => query.queryKey.toString()));
} 

export const invalidateMutateDependencies = (name: QueryName, id?: string, options?: InvalidOptions): void => {
    invalidateDependencies(name, id, options);
} 

export const invalidateQueryDependencies = (name: QueryName, id?: string, options?: InvalidOptions): void => {
    invalidateDependencies(name, id, { ...options, invalidateSelf: false });
}