import { useContext } from "react";
import ProfileContext from "../contexts/ProfileContext";
import { bemName } from "../util/bemName";
import "./FederatedLogins.scss";

import googleNormal from "../res/logos/google/normal_light.svg";
import appleNormal from "../res/logos/apple-logo.svg";
import facebookNormal from "../res/logos/facebook-logo.svg"

interface Props {
    oauthState?: string;
    prefix?: string;
}

export const FederatedLogins = ({ oauthState, prefix }: Props) => {
    const profile = useContext(ProfileContext);
    const authentication = profile.authentication;
    const labelPrefix = prefix || "Login with";

    return (
        <div className={bemName("FederatedLogins", "container")}>
            <button
                className={[bemName("FederatedLogins", "separator"), bemName("FederatedLogins", "oauthButton", "google")].join(" ")}
                onClick={() => !authentication?.loggedIn && authentication?.googleSignIn()}
            >
                <img className={bemName("FederatedLogins", "oauthLogo", "google")} src={googleNormal} alt={labelPrefix + " Google"} />
                <span className={bemName("FederatedLogins", "oauthLabel", "google")}>{labelPrefix} Google</span>
            </button>
            <button
                className={[bemName("FederatedLogins", "separator"), bemName("FederatedLogins", "oauthButton", "apple")].join(" ")}
                onClick={() => !authentication?.loggedIn && authentication?.appleSignIn()}
            >
                <img className={bemName("FederatedLogins", "oauthLogo", "apple")} src={appleNormal} alt={labelPrefix + " Apple"} />
                <span className={bemName("FederatedLogins", "oauthLabel", "apple")}>{labelPrefix} Apple</span>
            </button>
            <button
                className={[bemName("FederatedLogins", "separator"), bemName("FederatedLogins", "oauthButton", "facebook")].join(" ")}
                onClick={() => !authentication?.loggedIn && authentication?.facebookSignIn()}
            >
                <img className={bemName("FederatedLogins", "oauthLogo", "facebook")} src={facebookNormal} alt={labelPrefix + " Facebook"} />
                <span className={bemName("FederatedLogins", "oauthLabel", "facebook")}>{labelPrefix} Facebook</span>
            </button>
        </div>
    );
}