import { QuerySummary } from "../hooks/QuerySummary";

export type FormProps<T, TName extends string = "value", TSetter extends string = "updateValue"> = {
    id?: string;
    key?: string;
    querySummary?: QuerySummary;
} & {
    [Name in TName]?: T;
} & {
    [Setter in TSetter]?: (newValue: T | undefined, valid?: boolean) => Promise<void> | void;
};

export enum MutatorStatus {
    Error = "Error",
    Success = "Success",
    PasswordReset = "PasswordReset",
    VerificationRequired = "VerificationRequired",
}
