import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import Validator from "validator";
import { Button } from "../components/Button";
import EnjoyFlexibility from "../components/EnjoyFlexibility";
import { FederatedLogins } from "../components/FederatedLogins";
import GotQuestion from "../components/GotQuestion";
import { Input } from "../components/Input";
import ReferAFriend from "../components/ReferAFriend";
import { Separator } from "../components/Separator";
import { errorHandler } from "../hooks/Error";
import { useLoginEmailMutation, useLoginEmailQuery } from "../hooks/LocalStorage";
import { QuerySummary, useQuerySummary } from "../hooks/QuerySummary";
import { useUuid } from "../hooks/Utils";
import { FormControl, NavigationButtons } from "../panel/BuyPanelsPanel";
import { bemName } from "../util/bemName";
import { FormProps } from "./Form";
import { Credentials } from "./LoginForm";
import { PaymentTypes } from "./PaymentTypes";
import "./SignupForm.scss";
import { TestimonialForm } from "./TestimonialForm";

type SignupFormProps = FormProps<Credentials> & {
    loginAction?: () => void;

    formControl: FormControl;
};

const componentName = "SignupForm";

function validText(value?: string, minLength?: number): boolean {
    if (!value) {
        return false;
    }

    if (minLength) {
        if (value.length < minLength) {
            return false;
        }
    }

    return true;
}

function validateCredentials({ email, password }: Credentials) {
    var valid = true;

    if (email === undefined || !Validator.isEmail(email)) {
        valid = false;
    }

    if (!validText(password, 8)) {
        valid = false;
    }

    return valid;
}

const useSignupFormController = (props: SignupFormProps) => {
    const { updateValue, formControl } = props;

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>();
    const [verifyPassword, setVerifyPassword] = useState<string>();
    const [valid, setValid] = useState<boolean>(false);

    const querySummary = useQuerySummary();
    const { data: loginEmail } = useLoginEmailQuery(querySummary);
    const { mutateAsync: setLoginEmail } = useLoginEmailMutation(querySummary);
    const isLoading = querySummary.get().isLoading;

    const verifyPasswordValid = password === verifyPassword;
    // console.log(`@@@@ verifyPasswordValid: ${verifyPasswordValid}`);

    useEffect(
        () => {
            if (!isLoading) {
                setEmail(loginEmail ?? "");
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading]
    );

    useEffect(
        () => {
            const credentials = { email: email, password: password, valid: false };

            credentials.valid = validateCredentials(credentials) && verifyPasswordValid;

            setValid(credentials.valid);

            updateValue?.(credentials, credentials.valid);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [email, password, verifyPassword]
    );

    const updateEmail = (email: string) => {
        setEmail(email);
        setLoginEmail(email);
    };

    return {
        ...props,
        email,
        setEmail: updateEmail,
        password,
        setPassword,
        verifyPassword,
        setVerifyPassword,
        verifyPasswordValid,
        valid,
        formControl,
    }
}

type SignupResult = {
    status: "Success" | "UsernameExists" | "Error";
    error?: any;
}

export const useSignupFormMutator = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const mutation = useMutation(
        async (credentials: Credentials): Promise<SignupResult> => {
            try {
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                const signUpResponse = await Auth.signUp({
                    username: credentials.email!,
                    password: credentials.password!,
                    attributes: {
                        email: credentials.email!
                    }
                });

                return { status: "Success" };
            } catch (error: any) {
                if (error.code === "UsernameExistsException") {
                    return { status: "UsernameExists", error };
                } else {
                    return { status: "Error", error };
                }
            }
        },
        {
            onSuccess: () => {
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "signupForm", mutation);

    return mutation;
}

export const SignupForm = (props: SignupFormProps) => {
    const {
        email,
        setEmail,
        password,
        setPassword,
        verifyPassword,
        setVerifyPassword,
        verifyPasswordValid,
        loginAction,
        formControl,
    } = useSignupFormController(props);

    return (
        <div className={bemName(componentName, "container")}>
            <FederatedLogins prefix="Register using " />
            <Separator text={"or"} />
            <div className={bemName(componentName, "text")}>
                <p>Sign up with an email address and password. We will send you an email with a code to verify that we have the correct email address.</p>
            </div>
            <div className={bemName(componentName, "inputContainer")}>
                <Input placeholder="Email" type="email" value={email} autoComplete="email" onChange={(event) => setEmail(event.target.value)} />
                <Input placeholder="New password" type="password" value={password} autoComplete="new-password" onChange={(event) => setPassword(event.target.value)} />
                <Input error={!verifyPasswordValid} placeholder="Verify password" type="password" value={verifyPassword} autoComplete="new-password" onChange={(event) => setVerifyPassword(event.target.value)} />
            </div>
            <div className={bemName(componentName, "links")}>
                <Button asLink onClick={loginAction}>Sign in with existing account</Button>
            </div>
            <NavigationButtons formControl={formControl} />
            <EnjoyFlexibility />
            <GotQuestion />
            <PaymentTypes />
            <TestimonialForm />
            <ReferAFriend />
        </div>
    );
}
