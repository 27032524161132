import { TrackerEvent, TrackerEventType } from "../api/SolarCloudApi";

import { v4 as uuid } from "uuid";

import "./TrackerEventView.scss";
import { bemName } from "../util/bemName";
import { currencyFormat } from "../util/formats";
import { ReactComponent as AgreementIcon } from "../res/icons/Document.svg";
import { ReactComponent as AllocationIcon } from "../res/icons/Payment.svg";

const iconLookup: { [key in TrackerEventType]: React.ReactNode|undefined } = {
    [TrackerEventType.AGREEMENTS]: <AgreementIcon />,
    [TrackerEventType.DOCUMENTS]: undefined,
    [TrackerEventType.ORDER]: undefined,
    [TrackerEventType.ORGANISATION_ALLOCATIONS]: <AllocationIcon />,
    [TrackerEventType.PAYMENT]: undefined,
    [TrackerEventType.PAYOUT]: undefined,
    [TrackerEventType.PROFILE]: undefined,
    [TrackerEventType.REFUND_ORDER]: undefined,
    [TrackerEventType.REFUND_PANEL]: undefined,
    [TrackerEventType.USER_ALLOCATIONS]: <AllocationIcon />,
};

type LabelType = "normal" | "warning" | "important";

const labelTypeLookup: { [key in TrackerEventType]: LabelType } = {
    [TrackerEventType.AGREEMENTS]:"important",
    [TrackerEventType.DOCUMENTS]: "normal",
    [TrackerEventType.ORDER]: "normal",
    [TrackerEventType.ORGANISATION_ALLOCATIONS]: "important",
    [TrackerEventType.PAYMENT]: "normal",
    [TrackerEventType.PAYOUT]: "normal",
    [TrackerEventType.PROFILE]: "normal",
    [TrackerEventType.REFUND_ORDER]: "normal",
    [TrackerEventType.REFUND_PANEL]: "normal",
    [TrackerEventType.USER_ALLOCATIONS]: "important",
};

export enum TrackerSeparatorType  {
    Normal = "normal",
    More = "more",
    Last = "none",
}

interface Props<T> {
    index: number;
    separator: TrackerSeparatorType;
    event: T;
}

export const TrackerEventView = ({ index, separator, event }: Props<TrackerEvent>) => {
    const icon = event.type ? iconLookup[event.type!] : undefined;
    const labelType = event.type ? labelTypeLookup[event.type!] : "normal";

    return (
        <div key={index} className={"TrackerEvent"}>
            <TrackerIcon 
                index={index}
                icon={icon}
                date={event.timestamp ? new Date(event.timestamp) : undefined}
            />
            <TrackerBody 
                index={index}
                title={event.title!}
                labelType={labelType}
                label={event.label!}
                amount={event.amount}
                details={event.messages!}
            />
            <TrackerSeparator
                index={index}
                separator={separator}
            />
        </div>
    );
}

interface TrackerIconProps {
    index: number;
    date?: Date;
    icon?: React.ReactNode;
}

const TrackerIcon = (props: TrackerIconProps) => {
    const { index, date, icon } = props;

    if (Number(date !== undefined) + Number(icon !== undefined) !== 1) {
        throw new Error("Exactly one TrackerIcon property must be provided.");
    }

    const style = { gridColumn: "1 / 2", gridRow: `${index * 2 + 1} / ${index * 2 + 2}` };

    if (date) {
        return (
            <div className={"TrackerEvent__dateContainer"} style={style}>
                <div className={"TrackerEvent__month"}>
                    {date.toLocaleString("default", { month: "short" })}&nbsp;
                    {date.toLocaleString("default", { year: "numeric" })}
                </div>
                <div className={"TrackerEvent__day"}>
                    {date.toLocaleString("default", { day: "2-digit" })}
                </div>
            </div>
        )
    } else if (icon) {
        return (
            <div className={"TrackerEvent__iconContainer"} style={style}>
                {icon}
            </div>
        )
    } else {
        return null;
    }
}

interface TrackerBodyProps {
    index: number;
    title?: string;
    labelType?: LabelType;
    label?: string;
    amount?: number;
    details?: (string|undefined)[];
}

const TrackerBody = (props: TrackerBodyProps) => {
    const { index, title, labelType, label, amount, details } = props;
    
    const amountJSX = amount
        ? (
            <div className={"TrackerEvent__amount"}>
                {currencyFormat(amount, { excludeCurrencyId: true })}
            </div>
        )
        : null;

    const detailsJSX = details?.filter(detail => detail !== undefined).map((detail, index) => (
        <div key={`detail-${index}-${uuid()}`} className={"TrackerEvent__detail"}>
            {detail}
        </div>
    ));

    const style = { gridColumn: "2 / 3", gridRow: `${index * 2 + 1} / ${index * 2 + 3}` };

    return (
        <div className={"TrackerEvent__bodyContainer"} style={style}>
            <div className={"TrackerEvent__title"}>
                {title}
            </div>
            <div className={bemName("TrackerEvent", "label", labelType)}>
                {label}
            </div>
            {amountJSX}
            <div className={"TrackerEvent__detailsContainer"}>
                {detailsJSX}
            </div>
        </div>
    );
}

interface TrackerSeparatorProps {
    index: number;
    separator: TrackerSeparatorType;
}

const TrackerSeparator = (props: TrackerSeparatorProps) => {
    const { index, separator } = props;

    const style = { gridColumn: "1 / 2", gridRow: `${index * 2 + 2} / ${index * 2 + 3}` };

    return (
        <div className={bemName("TrackerEvent", "separatorContainer", separator)} style={style}>
            <div className={bemName("TrackerEvent", "separator", separator)}></div>
        </div>
    );
}