import { useQuery } from "react-query";
import { DefaultService as DashboardService } from '../api/SolarCloudApi';
import { errorHandler } from "./Error";
import { QuerySummary } from "./QuerySummary";
import { useUuid } from "./Utils";

// DashboardApi.BASE = "";

export const useTotalsQuery = (querySummary?: QuerySummary, ignoreErrors?: boolean) => {
    const id = useUuid();

    const query = useQuery(
        "totals",
        async () => {
            // DashboardApi.TOKEN = getAccessToken;

            const response = await DashboardService.totalsGet();

            return response.collection;
        },
        {
            staleTime: 60 * 60 * 1000, // 1 hour
            onError: ignoreErrors ? undefined : errorHandler,
            retry: ignoreErrors ? false : true,
            retryDelay: ignoreErrors ? 0 : undefined,
        }
    );

    querySummary?.updateQuery(id, "totals", query);

    return query;
}
