import { useEffect, useRef, useState } from "react";
import { bemName } from "../util/bemName";
import "./Slider.scss";

const componentName = "Slider";

type Props = {
    value: number;
    setValue: (value: number) => void;
    units?: string;
    text?: string;
    min?: number;
    max?: number;
    step?: number;
    allowEndOfRangeEntry?: boolean;
}

export const Slider = ({ value, setValue, units, text, min, max, step, allowEndOfRangeEntry }: Props) => {
    const rangeRef = useRef<HTMLInputElement>(null);
    const valueRef = useRef<HTMLDivElement>(null);
    const unitsRef = useRef<HTMLDivElement>(null);

    const [ rangeWidth, setRangeWidth ] = useState(0);
    const [ valueWidth, setValueWidth ] = useState(0);
    const [ unitsWidth, setUnitsWidth ] = useState(0);

    useEffect(
        () => {
            if (rangeRef.current !== null) {
                const width = rangeRef.current.offsetWidth;
                setRangeWidth(width);
            }
            
            if (valueRef.current !== null) {
                const width = valueRef.current.offsetWidth;
                setValueWidth(width);
            }
            
            if (unitsRef.current !== null) {
                const width = unitsRef.current.offsetWidth;
                setUnitsWidth(width);
            }
        },
        [ rangeRef.current?.offsetWidth, valueRef.current?.offsetWidth, unitsRef.current?.offsetWidth ]
    );

    // logger.log("@@@@ widths", rangeWidth, valueWidth, unitsWidth);

    const displayText = text || value;

    const updateValue = (value: string) => {
        setValue(parseFloat(value));
    }

    const minValue = min ?? 0;
    const maxValue = max ?? 100;

    const clampedValue = Math.min(value, maxValue);

    const valueLeft = String((clampedValue - minValue) / (maxValue - minValue) * (rangeWidth - 26) + 13 - (valueWidth / 2)) + "px";
    const valueStyle = { left: valueLeft };

    const unitsLeft = String((clampedValue - minValue) / (maxValue - minValue) * (rangeWidth - 26) + 13 - (unitsWidth / 2)) + "px";
    const unitsStyle = { left: unitsLeft };

    return (
        <div className={bemName(componentName, "sliderContainer")}>
            <div ref={valueRef} className={bemName(componentName, "sliderValue")} style={valueStyle}>{displayText}</div>
            <input 
                ref={rangeRef} 
                className={bemName(componentName, "slider")} 
                type="range" 
                min={min ?? 0} 
                max={max ?? 100} 
                step={step ?? 1} 
                value={clampedValue} 
                onChange={event => updateValue(event.target.value)} 
            />
            {
                units &&
                <div ref={unitsRef} className={bemName(componentName, "sliderUnits")} style={unitsStyle}>{units}</div>
            }
            {
                allowEndOfRangeEntry && value >= maxValue &&
                <div className={bemName(componentName, "sliderEntryContainer")}>
                    <input className={bemName(componentName, "sliderEntry", units ? "units": "noUnits")} type="number" value={value} onChange={event => updateValue(event.target.value)}/>
                </div>
            }
            
        </div>
    );
}

export default Slider;