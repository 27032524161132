import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { Allocation, PayeeType } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import { error } from "../components/Messages";
import { Modal } from "../components/Modal";
import { usePowerGenerationInfo } from "../hooks/PowerGeneration";
import {
  useEnergyCompaniesQuery,
  usePersonalAllocationsMutation,
  usePersonalAllocationsQuery,
} from "../hooks/User";
import { ReactComponent as MoveDownIcon } from "../res/icons/angles-down.svg";
import { ReactComponent as MoveUpIcon } from "../res/icons/angles-up.svg";
import { ReactComponent as EditIcon } from "../res/icons/pen.svg";
import { ReactComponent as AddIcon } from "../res/icons/plus.svg";
import { ReactComponent as UndoIcon } from "../res/icons/rotate-left.svg";
import { ReactComponent as RemoveIcon } from "../res/icons/trash-can.svg";
import allocated from "../res/images/allocated.png";
import unallocated from "../res/images/unallocated.png";
import { bemName } from "../util/bemName";
import { WATTS_PER_PANEL } from "../util/calculator";
import { metricFormat, metricFormatTextAndSuffix } from "../util/formats";
import { AllocationForm } from "./AllocationForm";
import "./AllocationsForm.scss";
const componentName = "AllocationsForm";

interface AllocationProps {
  blockName?: string;
  maxAllocation?: number;
  allocation: Allocation;
  updateAllocation: (allocation: Allocation) => Promise<void> | void;
  edit: () => void;
  remove: () => void;
  up?: () => void;
  down?: () => void;
}

const getPayeeType = (allocation?: Allocation) => {
  if (!allocation) {
    return undefined;
  }

  return allocation.bankPayee?.type || allocation.bpayPayee?.type || allocation.energyCompanyPayee?.type || allocation.payPalPayee?.type;
}

const getAllocationTypeName = (allocation?: Allocation) => {
  switch (getPayeeType(allocation)) {
    case PayeeType.BANK:
        return "Bank account";
    case PayeeType.BPAY:
        return "BPay account";
    case PayeeType.ENERGY_COMPANY:
        return allocation?.energyCompanyPayee?.energyCompany?.name;
    case PayeeType.PAY_PAL:
        return "Paypal";
  }
}

const AllocationView: React.FunctionComponent<AllocationProps> = ({
  blockName,
  maxAllocation,
  allocation,
  updateAllocation,
  edit,
  remove,
  up,
  down,
}) => {
  const block = blockName || componentName;

  const percentage = Math.round(
    (allocation.wattCapacity! * 100) / maxAllocation!
  );

  const numberOfPanels = (allocation.wattCapacity! / 100);

  const allocationTypeName = getAllocationTypeName(allocation);

  return (
    <div key={allocation.uuid} className={bemName(block, "item")}>
      <div className={bemName(block, "section1")}>
        <div className={bemName(block, "name")}>{allocation.nickname}</div>
        <div className={bemName(block, "payment")}>{allocationTypeName}</div>
        <div className={bemName(block, "panelsnumber")}> {numberOfPanels} Panels</div>
      </div>
      <div className={bemName(block, "section2")}>
        <div className={bemName(block, "capacity")}>
          {metricFormat(allocation.wattCapacity, { significantDigits: 10, unit: "W", multiplier: 1000 })}
        </div>
      </div>
      <div className={bemName(block, "allocationActions")}>
        <div className={bemName(block, "iconContainer")}>
          <div >
            <button
              className={bemName(block, "iconButton")}
              onClick={edit}
              title="Edit"
            >
              <EditIcon className={bemName(block, "icon", "edit")} />
            </button>
          </div>
          <div >
            {up && (
              <button
                className={bemName(block, "iconButton")}
                onClick={up}
                title="Move up"
              >
                <MoveUpIcon className={bemName(block, "icon", "up")} />
              </button>
            )}
          </div>
          <div >
            {down && (
              <button
                className={bemName(block, "iconButton")}
                onClick={down}
                title="Move down"
              >
                <MoveDownIcon className={bemName(block, "icon", "down")} />
              </button>
            )}
          </div>
          <div >
            <button
              className={bemName(block, "iconButton")}
              onClick={remove}
              title="Delete"
            >
              <RemoveIcon className={bemName(block, "icon", "delete")} />
            </button>
          </div>
        </div>
        <div className={bemName(block, "percentage")}>{percentage} %</div>
      </div>
    </div>
  );
};

interface Props {
  blockName?: string;
}

export const AllocationsForm: React.FunctionComponent<Props> = ({
  blockName,
}) => {
  const [allocations, setAllocations] = useState<Allocation[]>();
  const [allocation, setAllocation] = useState<Allocation>();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const { data: powerGenerationInfo } = usePowerGenerationInfo();
  const { data: originalAllocations } = usePersonalAllocationsQuery();
  const { mutateAsync: updateAllocations } = usePersonalAllocationsMutation();
  const { data: energyCompanies } = useEnergyCompaniesQuery();

  useEffect(() => {
    if (originalAllocations && !allocations) {
      setAllocations(originalAllocations);
    }
  }, [allocations, originalAllocations]);

  const saveAllocations = async () => {
    try {
      await updateAllocations(allocations!);

      setUnsavedChanges(false);
    } catch (err) {
      error(
        "Your allocations couldn't be saved. Please try again later.",
        "Payout allocations"
      );
    }
  };

  const showAllocationEditor = (allocation: Allocation) => {
    setAllocation(allocation);
    setShowEditor(true);
  };

  const allocationsAreDifferent = (
    allocations: Allocation[] | undefined,
    originalAllocations: Allocation[] | undefined
  ) => {
    if (!allocations || !originalAllocations) {
      return false;
    }

    if (allocations.length !== originalAllocations.length) {
      return true;
    }

    for (let index = 0; index < allocations.length; ++index) {
      const allocation = allocations[index];
      const originalAllocation = originalAllocations[index];

      let key: keyof Allocation;
      for (key in allocation) {
        if (allocation[key] !== originalAllocation[key]) {
          return true;
        }
      }
    }

    return false;
  };

  const checkForChanges = (newAllocations?: Allocation[]) => {
    const changed = allocationsAreDifferent(
      newAllocations ? newAllocations : allocations,
      originalAllocations
    );

    setUnsavedChanges(changed);
  };

  const updateAllocation = (allocation: Allocation) => {
    var newAllocations =
      allocations?.map((existing) =>
        existing.uuid === allocation.uuid ? allocation : existing
      ) || [];

    if (allocation.uuid === undefined) {
      allocation.uuid = uuid();
      newAllocations = newAllocations.concat([allocation]);
    }

    setAllocations(newAllocations);
    setShowEditor(false);

    checkForChanges(newAllocations);
  };

  const cancelEditor = () => {
    setShowEditor(false);
  };

  const removeAllocation = (allocation: Allocation) => {
    const newAllocations = allocations?.filter(
      (item) => item.uuid !== allocation.uuid
    );

    setAllocations(newAllocations);

    checkForChanges(newAllocations);
  };

  const reorderAllocation = (allocation: Allocation, direction: number) => {
    const index = allocations!.indexOf(allocation);

    const newIndex = index + direction;

    if (newIndex === index || newIndex < 0 || newIndex >= allocations!.length) {
      return;
    }

    const newAllocations = [...allocations!];

    newAllocations.splice(index, 1);

    newAllocations.splice(newIndex, 0, allocation);

    setAllocations(newAllocations);

    checkForChanges(newAllocations);
  };

  const addAllocation = () => {
    setAllocation(undefined);
    setShowEditor(true);
  };

  const resetAllocations = () => {
    setAllocations(originalAllocations);
  };

  const block = blockName || componentName;

  const maxAllocation =
    powerGenerationInfo &&
    powerGenerationInfo.user!.active!;

  const totalAllocation =
    (allocations || [])
      .filter((allocation) => allocation.wattCapacity !== undefined)
      .map((allocation) => allocation.wattCapacity)
      .reduce((total, capacity) => total! + capacity!, 0);

  const totalPanels = maxAllocation !== undefined ? maxAllocation / WATTS_PER_PANEL : undefined;
  const allocatedPanels = totalAllocation !== undefined ? totalAllocation / WATTS_PER_PANEL : undefined;
  const unallocatedPanels = totalPanels !== undefined && allocatedPanels !== undefined ? totalPanels - allocatedPanels : undefined;

  const maxAllocationTextAndSuffix = maxAllocation !== undefined ? metricFormatTextAndSuffix(maxAllocation, { significantDigits: 3, unit: "W", multiplier: 1000 }) : { text: "-", suffix: "" };

  const isFullyAllocated = unallocatedPanels !== undefined && unallocatedPanels === 0;

  // console.log("@@@@ powerGenerationInfo:", powerGenerationInfo, isFullyAllocated);
  
  const allocationList = allocations?.map((allocation, index) => {
    return (
      <AllocationView
        key={`allocationview-${index}`}
        maxAllocation={maxAllocation}
        allocation={allocation}
        updateAllocation={updateAllocation}
        edit={() => showAllocationEditor(allocation)}
        remove={() => removeAllocation(allocation)}
        up={index !== 0 ? () => reorderAllocation(allocation, -1) : undefined}
        down={
          index + 1 < allocations.length
            ? () => reorderAllocation(allocation, 1)
            : undefined
        }
      />
    );
  });

  return (
    <>
      <div className={bemName(block)}>
        {
          !isFullyAllocated ?
            <div className={bemName(block, "heading")}>
              <div className={bemName(block, "panels")}>{unallocatedPanels}</div>
              <div className={bemName(block, "text")}>
                Panels <span>Un</span>allocated
              </div>
            </div>
            :
            <div className={bemName(block, "headingtwo")}>
              <div className={bemName(block, "panelstwo")}>All allocated</div>
              <div className={bemName(block, "texttwo")}>
                you legend....
              </div>
            </div>
        }
        <div className={bemName(block, "summary")}>
          <div className={bemName(block, "totalLabel")}>
            {maxAllocationTextAndSuffix?.text}<sup>{maxAllocationTextAndSuffix?.suffix}</sup>/{totalPanels}<sup>panels</sup>
          </div>
          {
            !isFullyAllocated ? 
              <div className={bemName(block, "maxWatts")}>
                <img src={unallocated} height={27} alt="img" />
                <div className={bemName(block, "unallocatedtext")}>Unallocated</div>
                <div className={bemName(block, "paneltext")}>{unallocatedPanels}<sup>panels</sup></div>
              </div>
              : 
              <></>
          }
          <div className={bemName(block, "currentPercentage")}>
            <img src={allocated} height={27} alt="img" />
            <div className={bemName(block, "allocatedtext")}>Allocated</div>
            <div className={bemName(block, "paneltext")}>{allocatedPanels}<sup>panels</sup></div>
          </div>
        </div>
        <div className={bemName(block, "allocations")}>{allocationList}</div>
        <div className={bemName(block, "actions")}>
          <Button
            text="Save"
            disabled={!unsavedChanges}
            onClick={saveAllocations}
          />
          <Button className={bemName(block, "button")} disabled={unallocatedPanels === undefined || unallocatedPanels === 0} onClick={addAllocation}>
            <AddIcon className={bemName(block, "icon", "add")} />
          </Button>
          <Button
            className={bemName(block, "button")}
            onClick={resetAllocations}
          >
            <UndoIcon className={bemName(block, "icon", "reset")} />
          </Button>
        </div>
      </div>
      <Modal
        open={showEditor}
        title="Payee allocation"
        onClose={() => setShowEditor(false)}
      >
        <AllocationForm
          allocation={allocation}
          maxAllocation={((unallocatedPanels ?? 0) * WATTS_PER_PANEL) + (allocation?.wattCapacity ?? 0)}
          energyCompanies={energyCompanies}
          onSave={(allocation) => updateAllocation(allocation)}
          onCancel={cancelEditor}
        />
      </Modal>
    </>
  );
};
