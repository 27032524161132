import { useGeoCountryQuery } from "./IpGeoLocation";
import { useCountryOverrideMutation, useCountryOverrideQuery } from "./LocalStorage";
import { QuerySummary } from "./QuerySummary";
import { useActiveCountriesQuery, useAvailableCountriesQuery } from "./Reference";

export type CountryOptions = {
    onlyAvailable?: boolean;
    allowOverride?: boolean;
    sort?: boolean;
};

export const useCountries = (
    options?: CountryOptions,
    querySummary?: QuerySummary
) => {
    const { data: activeCountries } = useActiveCountriesQuery(querySummary);
    const { data: availableCountries } = useAvailableCountriesQuery(querySummary);
    const { data: geoCountry } = useGeoCountryQuery(querySummary);
    const { data: countryOverride } = useCountryOverrideQuery(querySummary);
    const { mutate: setCountryOverride } = useCountryOverrideMutation(querySummary);
  
    const countriesUnsorted = options?.onlyAvailable
      ? availableCountries
      : activeCountries;

    const countries = options?.sort === true ? countriesUnsorted?.sort((a, b) => a.name!.localeCompare(b.name!) || 0) : countriesUnsorted;

    const defaultCountry = countryOverride ?? geoCountry;
  
    const countryFromCode = (code?: string) => {
      if (!code) {
        return undefined;
      }
  
      return countries?.find((country) => country.code === code);
    };
  
    return {
      defaultCountry,
      setDefaultCountry: setCountryOverride,
      countries,
      countryFromCode,
    };
  };
