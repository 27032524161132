/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum State {
    ALLOCATED = 'Allocated',
    DISBURSED = 'Disbursed',
    PROCESSING = 'Processing',
    UNALLOCATED = 'Unallocated',
}
