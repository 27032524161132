import React from "react";
import { withComponentName } from "../util/bemName";
import "./TestimonialForm.scss";

const componentName = "TestimonialForm";
const bemClass = withComponentName(componentName);

export const TestimonialForm = () => {
  const messages = [
    {
      text: "No roof no problem, love it. Works for me as well ....",
      name: "John",
      location: "Sydney",
    },
    {
      text: "So easy, Why hasn’t someone done this before",
      name: "Greg",
      location: "ACT",
    },
    {
      text: "Bought 2 panels and now I have put a deposit down for more",
      name: "Emma",
      location: "Sydney",
    },
  ];

  const testimonialElements = messages.map((message, index) => (
    <div key={index} className={bemClass("container")}>
      <div key={index} className={bemClass("testimonialContainer")}>
        <div className={bemClass("testimonial")}>
          <div className={bemClass("text")}>{message.text}</div>
          <div className={bemClass("name")}>{message.name}</div>
          <div className={bemClass("location")}>{message.location}</div>
        </div>
      </div>
      <Stars key={`stars-${index}`} count={5} />
    </div>
  ));

  return (
    <div className={bemClass()}>
      <div className={bemClass("testimonials")}>{testimonialElements}</div>
    </div>
  );
};

const Stars = ({ count }: { count: number }) => {
  const starElements = [];
  for (let i = 0; i < count; i++) {
    starElements.push(
      <span
        key={i}
        className="star-element"
        style={{ color: "#E9A837", fontSize: "16px",textAlign:'center' }}
      >
        &#9733;
      </span>
    );
  }
  return <div className="stars">{starElements}</div>;
};
