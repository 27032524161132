import {
    OrganisationAddress,
    UserAddress
} from "../api/SolarCloudApi";
import { bemName } from "../util/bemName";
import { Country } from "./Country";
import { Input } from "./Input";

import { FormProps } from "../forms/Form";
import { CountryOptions } from "../hooks/Countries";
import "./Address.scss";

type AddressType = UserAddress | OrganisationAddress;

type AddressProps = FormProps<AddressType, "address", "updateAddress"> & {
    options?: CountryOptions;
};

const componentName = "Address";

export const Address = ({ address, updateAddress, options }: AddressProps) => {
    return (
        <div className={bemName(componentName, "container")}>
            <div className={bemName(componentName, "inputGroup")}>
                <label htmlFor="line1">Line 1:</label>
                <Input
                    id="line1"
                    className={bemName(componentName, "line1")}
                    placeholder="Line 1"
                    value={address && address.line1 ? address.line1 : ""}
                    autoComplete="address-line1"
                    onChange={(e) =>
                        updateAddress?.({ ...address, line1: e.target.value })

                    }
                />
            </div>
            <div className={bemName(componentName, "inputGroup")}>
                <label htmlFor="line2">Line 2:</label>
                <Input
                    id="line2"
                    className={bemName(componentName, "line2")}
                    placeholder="Line2"
                    value={address && address.line2 ? address.line2 : ""}
                    autoComplete="address-line2"
                    onChange={(e) =>
                        updateAddress?.({ ...address, line2: e.target.value })
                    }
                />
            </div>
            <div className={bemName(componentName, "inputGroup")}>
                <label htmlFor="city">Suburb/Town:</label>
                <Input
                    id="city"
                    className={bemName(componentName, "city")}
                    placeholder="Suburb/Town"
                    value={address && address.city ? address.city : ""}
                    autoComplete="address-level2"
                    onChange={(e) =>
                        updateAddress?.({ ...address, city: e.target.value })
                    }
                />
            </div>

            <div className={bemName(componentName, "regionGroup")}>
                <div className={bemName(componentName, "inputGroup")}>
                    <label htmlFor="state">State/Territory:</label>
                    <Input
                        id="state"
                        className={bemName(componentName, "state")}
                        placeholder="State/Territory"
                        value={address && address.state ? address.state : ""}
                        autoComplete="address-level1"
                        onChange={(e) =>
                            updateAddress?.({ ...address, state: e.target.value })
                        }
                    />
                </div>
                <div className={bemName(componentName, "inputGroup")}>
                    <label htmlFor="postcode">Post code:</label>
                    <Input
                        id="postcode"
                        className={bemName(componentName, "postcode")}
                        placeholder="Post code"
                        value={address && address.postcode ? address.postcode : ""}
                        autoComplete="postal-code"
                        onChange={(e) =>
                            updateAddress?.({ ...address, postcode: e.target.value })
                        }
                    />
                </div>
                <div className={bemName(componentName, "country")}>
                    <label htmlFor="country">Country:</label>
                    <Country id="country" country={address?.country} setCountry={(country) => updateAddress?.({ ...address, country: country?.code })} />
                </div>
            </div>
            <div className={bemName(componentName, "text")}>
                We have used the above information to produce the information that will appear on the receipt, is this correct? Please edit until correct.
            </div>
        </div>
    );
};
