import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import { Panel } from "../components/Panel";
import Slider from "../components/Slider";
import { ToggleSwitch } from "../components/ToggleSwitch";
import { CalcMode, calculateCapacityFromBill, calculateEstimatedSavings, COST_PER_PANEL } from "../model/Calculator";
import { bemName } from "../util/bemName";
import { currencyFormat } from "../util/formats";
import "./CalculatorPanel.scss";

const componentName = "CalculatorPanel";

export const CalculatorPanel = () => {
    const [ mode, setMode ] = useState<CalcMode>(CalcMode.Bill);
    const [ billValue, setBillValue ] = useState<number>(125);
    const [ capacityValue, setCapacityValue ] = useState<number>(25);

    const navigate = useNavigate();

    const toggle = () => {
        setMode((mode) => mode === CalcMode.Bill ? CalcMode.Capacity : CalcMode.Bill);
    }

    const getValue = () => {
        switch (mode) {
        case CalcMode.Bill:
            return billValue;
        case CalcMode.Capacity:
            return capacityValue;
        default:
            return 0;
        }
    }

    const updateValue = (value: number) => {
        switch (mode) {
            case CalcMode.Bill:
                setBillValue(value);
                break;
            case CalcMode.Capacity:
                setCapacityValue(value);
                break;
            }
        }

    const formatValue = () => {
        switch (mode) {
        case CalcMode.Bill:
            return `$${billValue}`;
        case CalcMode.Capacity:
            return `${capacityValue} kW`;
        default:
            return undefined;
        }
    }

    const maxValue = () => {
        switch (mode) {
        case CalcMode.Bill:
            return 750;
        case CalcMode.Capacity:
            return 50;
        default:
            return undefined;
        }
    }

    const stepValue = () => {
        switch (mode) {
        case CalcMode.Bill:
            return 1;
        case CalcMode.Capacity:
            return 0.1;
        default:
            return undefined;
        }
    }

    const formatSavings = (percentage?: number) => {
        const savings = calculateEstimatedSavings(mode, mode === CalcMode.Bill ? billValue : capacityValue, 25, percentage ?? 100);

        return currencyFormat(savings, { excludeCurrencyId: true });
    }

    const formatCapacity = (percentage?: number) => {
        if (mode !== CalcMode.Bill) {
            return undefined;
        }

        const capacity = calculateCapacityFromBill(billValue) * (percentage ?? 100) / 100;

        return `${capacity} kW`;
    }

    const calculatePanels = (percentage?: number) => {
        const capacity = calculateCapacityFromBill(billValue) * (percentage ?? 100) / 100;

        const panels = Math.ceil(capacity * 10);

        return panels;
    }

    const formatPanelCapacity = (percentage?: number) => {
        const panels = calculatePanels(percentage);

        const capacity = panels / 10;

        return `${capacity} kW`;
    }

    const formatDeposit = (percentage?: number) => {
        const panels = calculatePanels(percentage);

        const cost = panels * COST_PER_PANEL * 0.10;

        return currencyFormat(cost, { excludeCurrencyId: true });
    }
    
    return (
        <Panel title="Savings Calculator"> 
            <ToggleSwitch 
                checked={mode !== CalcMode.Bill} 
                left="My bill" 
                right="System size" 
                toggle={toggle}
            />
            <Slider 
                value={getValue()} 
                setValue={updateValue} 
                text={formatValue()}
                units={mode === CalcMode.Bill ? "per quarter" : undefined}
                max={maxValue()}
                step={stepValue()}
                allowEndOfRangeEntry
            />
            <div className={bemName(componentName, "results")}>
                <div className={bemName(componentName, "savings")}>
                    <div className={bemName(componentName, "savingsLabel")}>*I could save</div>
                    <div className={bemName(componentName, "savingsValue")}>{formatSavings()}</div>
                </div>
                {
                    mode === CalcMode.Bill &&
                    <div className={bemName(componentName, "capacity")}>
                        <div className={bemName(componentName, "capacityLabel")}>System size</div>
                        <div className={bemName(componentName, "capacityValue")}>{formatCapacity()}</div>
                    </div>
                }
            </div>
            <div className={bemName(componentName, "buyContainer")}>
                {
                    [25, 50, 100].map(
                        percentage => {
                            return (
                                <div key={`percentage_${percentage}`} className={bemName(componentName, "buy")}>
                                    <div className={bemName(componentName, "buyPercentage")}>{percentage}%</div>
                                    <div className={bemName(componentName, "buySavings")}>Save {formatSavings(percentage)}</div>
                                    <div className={bemName(componentName, "buyPanels")}>{calculatePanels(percentage)} panels</div>
                                    <div className={bemName(componentName, "buyPanels")}>{formatPanelCapacity(percentage)}</div>
                                    <div className={bemName(componentName, "buyDeposit")}>10% deposit only</div>
                                    <div className={bemName(componentName, "buyCost")}>{formatDeposit(percentage)}</div>
                                    <Button text={"Buy"} onClick={() => navigate(`/buypanels?panels=${calculatePanels(percentage)}`)}/>
                                </div>
                            );
                        }
                    )
                }
            </div>
        </Panel>
    );
}

export default CalculatorPanel;
