import { Panel } from "../components/Panel";
import { TermsAndConditionsForm } from "../forms/TermsAndConditionsForm";
import { useQuerySummary } from "../hooks/QuerySummary";
import { useTermsAndConditionsQuery } from "../hooks/User";

import "./TermsAndConditionsPanel.scss";

interface Props {
}

export const TermsAndConditionsPanel: React.FunctionComponent<Props> = (props: Props) => {
    const querySummary = useQuerySummary();
    
    const { data: termsAndConditions } = useTermsAndConditionsQuery(querySummary);

    const { isLoading } = querySummary.get();

    return (
        <Panel title="Terms and conditions" isLoading={isLoading}>
            <TermsAndConditionsForm termsAndConditions={termsAndConditions!}/>
        </Panel>
    );
}

export default TermsAndConditionsPanel;
