import { useQuery } from "react-query";
import { DefaultService as TrackerService } from '../api/SolarCloudApi';
import { errorHandler } from "./Error";
import { QuerySummary } from "./QuerySummary";
import { useUuid } from "./Utils";

// TrackerApi.BASE = "";
// TrackerApi.TOKEN = getAccessToken;

export const useTrackerEventQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();
    
    const query = useQuery(
        "tracker",
        async () => {
            const response = await TrackerService.trackerEventsGet();

            return response.collection;
        },
        {
            staleTime: 60 * 60 * 1000, // 1 hour
            onError: errorHandler,
        }
    )

    querySummary?.updateQuery(id, "tracker", query);

    return query;
}
