import { useState } from "react";
import Validators from "validator";
import { Contact, ContactType, CountryInfo } from '../api/SolarCloudApi';
import { Button } from '../components/Button';
import { Country } from '../components/Country';
import { Input } from "../components/Input";
import { Separator } from '../components/Separator';
import { useGeoCountryQuery } from "../hooks/IpGeoLocation";
import { useCountryOverrideMutation, useCountryOverrideQuery } from "../hooks/LocalStorage";
import { QuerySummary, useQuerySummary } from "../hooks/QuerySummary";
import { useRegisterContactMutation } from "../hooks/User";
import { FormControl, NavigationButtons } from "../panel/BuyPanelsPanel";
import { bemName } from '../util/bemName';
import "./SignupUnavailableForm.scss";

type SignupUnavailableFormProps = {
    saveContact: (contact: Contact) => Promise<void> | void;
    overrideCountry: (countryInfo: CountryInfo | undefined) => Promise<void> | void;
    formControl: FormControl;
};

const useSignupUnavailableFormController = () => {
    const querySummary = useQuerySummary();
    const { data: geoCountry } = useGeoCountryQuery(querySummary);
    const { data: countryOverride } = useCountryOverrideQuery(querySummary);
    const { isLoading } = querySummary.get();

    const [country, setCountry] = useState<CountryInfo|undefined>(countryOverride);
    const [contact, setContact] = useState<Contact>({});

    const contactValid = Validators.isEmail(contact.email || "");

    return {
        contact,
        contactValid,
        setContact,
        geoCountry,
        country,
        setCountry,
        isLoading,
    }
}

export const useSignupUnavailableFormMutator = (querySummary?: QuerySummary) => {
    const { data: geoCountry } = useGeoCountryQuery(querySummary);
    const { mutateAsync: setCountryOverride } = useCountryOverrideMutation(querySummary);
    const { mutateAsync: registerContact } = useRegisterContactMutation(querySummary);

    async function createContact(contact: Contact) {
        contact.type = ContactType.INQUIRY;
        contact.comment = `Registering interest for country (${geoCountry?.name ?? "unknown"})`;
        contact.country = geoCountry?.code ?? "(unknown)";

        await registerContact(contact);
    }

    const overrideCountry = async (country: CountryInfo | undefined) => {
        setCountryOverride(country);
    }

    return {
        createContact,
        overrideCountry,
    }
}

const componentName = "SignupUnavailableForm";

export const SignupUnavailableForm = ({ saveContact, overrideCountry, formControl }: SignupUnavailableFormProps) => {
    const {
        contact,
        contactValid,
        setContact,
        geoCountry,
        country,
        setCountry,
    } = useSignupUnavailableFormController();

    // console.log(`@@@@@ country:`, country);

    return (
        <div className={bemName(componentName, "form")}>
            <div className={bemName(componentName, "countrySection")}>
                The Internet thinks you are in {geoCountry?.name ?? "some unknown country"}. 
                If you are located in one of the following countries, please update your location.
                <div className={bemName(componentName, "spacer")} />
                <Country country={country} setCountry={setCountry} placeholder="Select your country"/>
                <div className={bemName(componentName, "spacer")} />
                <Button text={"Update"} disabled={!country} onClick={() => overrideCountry(country)}/>
            </div>
            <Separator text={"or"} />
            <div className={bemName(componentName, "unavailableSection")}>
                Otherwise, we are in process of starting SolarCloud services in your country. Please leave your details and we'll be in touch when we are ready to go.
                <div className={bemName(componentName, "spacer")} />
                <div className={bemName(componentName, "container")}>
                    <Input className={bemName(componentName, "input", "email")} placeholder="Email" type="email" value={contact?.email} autoComplete="email" onChange={(event) => setContact({...contact, email: event.target.value})}/>
                    <Input className={bemName(componentName, "input", "givenName")} placeholder="Give name" type="text" value={contact?.givenName} autoComplete="given-name" onChange={(event) => setContact({...contact, givenName: event.target.value})}/>
                    <Input className={bemName(componentName, "input", "familyName")} placeholder="Family name" type="text" value={contact?.familyName} autoComplete="family-name" onChange={(event) => setContact({...contact, familyName: event.target.value})}/>
                    <Input className={bemName(componentName, "input", "phoneNumber")} placeholder="Phone number" type="tel" value={contact?.phoneNumber} autoComplete="tel" onChange={(event) => setContact({...contact, phoneNumber: event.target.value})}/>
                </div>
                <div className={bemName(componentName, "spacer")} />
                <Button text={"Register for updates"} disabled={!contactValid} onClick={() => saveContact(contact)}/>
            </div>
            <NavigationButtons formControl={formControl}/>
        </div>
    );
}
