import { withComponentName } from "../util/bemName";

import "./PaymentTypes.scss";

import ApplePayLogo from "../res/logos/Apple-Pay-Mark/apple-pay-payment-mark-logo.png";
import GooglePayLogo from "../res/logos/Google-Pay-Mark/google-pay-logo.png";
import PayPalLogo from "../res/logos/PayPal_Logo_2022/PayPal_Logo_Horizontal_Full_Color_RGB.png";
import MintLogo from "../res/logos/mint/mint.webp";

const componentName = "PaymentTypes";

const bemClass = withComponentName(componentName);
export const PaymentTypes = () => {
    return (
        <div className={bemClass()}>
            <div className={bemClass("logos")}>
                <div className={bemClass("logo", "paypal")}>
                    <img src={PayPalLogo} alt="PayPal"/>
                </div>
                <div className={bemClass("logo", "google")}>
                    <img src={GooglePayLogo} alt="Google Pay"/>
                </div>
                <div className={bemClass("logo", "apple")}>
                    <img src={ApplePayLogo} alt="Apple Pay"/>
                </div>
                <div className={bemClass("logo", "mint")}>
                    <img src={MintLogo} alt="Mint Payments"/>
                </div>
            </div>
            <div className={bemClass("text")}>
                More payment options coming soon
            </div>
        </div>
    );
}