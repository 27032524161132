import { DataBox } from "../components/DataBox";
import { Panel } from "../components/Panel";
import { bemName } from "../util/bemName";

import { useEffect, useState } from "react";
import { Input } from "../components/Input";
import { SavingsGraph } from "../components/SavingsGraph";
import { useTotalsQuery } from "../hooks/Dashboard";
import { currencyFormat, percentageFormatter } from "../util/formats";
import { getFormattedTotal, savingsCriteria, selectTotal } from "../util/totals";
import "./MySavingsPanel.scss";
import { useAuthenticationQuery } from "../util/authentication";

const componentName = "MySavingsPanel";

interface Props {
}

export const MySavingsPanel: React.FunctionComponent<Props> = (props: Props) => {
    const [bill, setBill] = useState<number>();
    const { data: authentication } = useAuthenticationQuery();
    const { data: totals } = useTotalsQuery();

    const user = authentication?.user;

    useEffect(
        () => {
            const energyBill = localStorage.getItem("energy_bill");

            if (energyBill !== undefined && energyBill !== null && energyBill !== "NaN") {
                setBill(parseInt(energyBill));
            }
        },
        []
    )

    const updateBill = (bill: number) => {
        if (!Number.isNaN(bill)) {
            localStorage.setItem("energy_bill", bill.toString());

            setBill(bill);
        }
    }

    const joined = user?.joined && user.joined.replace(/\[.*$/, "");

    const joinedDate = joined ? Intl.DateTimeFormat(undefined, { dateStyle: "medium", timeZone: "Australia/Sydney" }).format(new Date(joined)) : "—";

    const quarterlySavings = selectTotal("Savings", "ThisQuarter", totals)?.value;

    const percentage = quarterlySavings && bill ? quarterlySavings * 100 / bill : undefined;

    const percentageElement =
        percentage !== undefined ?
            <>{percentageFormatter(percentage)} %<br />saving</>
            :
            "—";

    return (
        <Panel>
            <div className={bemName(componentName, "header")}>
                <div className={bemName(componentName, "title")}>My savings</div>
            </div>
            <div className={bemName(componentName, "calculator")}>
                <div className={bemName(componentName, "billContainer")}>
                    <div className={bemName(componentName, "billGroup")}>
                        <div className={bemName(componentName, "billLabel")}>
                            Enter in your Electricity Bill
                        </div>
                        <div className={bemName(componentName, "billInput")}>
                            <div className={bemName(componentName, "dollarSign")}>$</div> 
                            <Input type="number" value={bill ?? ""} onChange={(event) => updateBill(parseInt(event.target.value))} />
                        </div>
                    </div>
                    <div className={bemName(componentName, "joinedContainer")}>
                        <span className={bemName(componentName, "joinedLabel")}>Customer since</span>
                        <span className={bemName(componentName, "joinedValue")}>{joinedDate}</span>
                    </div>
                </div>
                <div className={bemName(componentName, "graphContainer")}>
                    <div className={bemName(componentName, "savingsGraph")}>
                        <SavingsGraph savings={quarterlySavings} energyBill={bill} />
                    </div>
                    <div className={bemName(componentName, "savingsGraphOverlay")}>
                        {percentageElement}
                    </div>
                </div>
                <div className={bemName(componentName, "informationContainer")}>
                    <div className={bemName(componentName, "informationGroup")}>
                        <div className={bemName(componentName, "infoBillLabel")}>Electricity bill</div>
                        <div className={bemName(componentName, "infoBill")}>{currencyFormat(bill, { excludeCurrencyId: true })}</div>
                        <div className={bemName(componentName, "infoSavingsLabel")}>Solar savings</div>
                        <div className={bemName(componentName, "infoSavings")}>{currencyFormat(quarterlySavings, { excludeCurrencyId: true })}</div>
                    </div>
                </div>
            </div>
            <div className={bemName(componentName, "data")}>
                {
                    savingsCriteria
                        .map(
                            (criteria, index) => {
                                const value = getFormattedTotal(criteria.type, criteria.period, totals, savingsCriteria);

                                return <DataBox key={index} title={criteria.title} value={value} icon={criteria.icon} />
                            }
                        )
                }
            </div>
        </Panel>
    );
}

export default MySavingsPanel;
