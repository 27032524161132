import { Panel } from "../components/Panel"
import { ContactForm } from "../forms/ContactForm";

import "./ContactPanel.scss";

interface Props {
}

export const ContactPanel: React.FunctionComponent<Props> = () => {
    return (
        <Panel title="ContactUs">
            <ContactForm />
        </Panel>
    )
}

export default ContactPanel;
