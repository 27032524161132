import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DefaultService as UsersService } from "../api/SolarCloudApi";
import { bemName } from "../util/bemName";

import { Button } from "../components/Button";
import { Input } from "../components/Input";
import { error, info } from "../components/Messages";
import ProfileContext from "../contexts/ProfileContext";
import "./ResetPasswordForm.scss";
import { logger } from "../util/logger";

const componentName = "ResetPasswordForm";

export const ResetPasswordForm = () => {
    const [password, setPassword] = useState("");
    const [verificationCodeRequired, setVerificationCodeRequired] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const profile = useContext(ProfileContext);
    const navigate = useNavigate();

    async function handlePreparePasswordReset() {
        try {
            const message = await UsersService.resetPassword(profile.email);
            info(message.message!);
            setVerificationCodeRequired(true);
        } catch (err: any) {
            if (err.body?.code === "NOT_VERIFIED") {
                navigate("/email/verify")
            } else {
                // logger.log("@@@@ error", err, err.url, err.status, err.statusText, err.body);
                error(err.body?.message || err.message);
            }
        }
    }

    async function handleResetPassword() {
        try {
            await UsersService.resetPasswordComplete({ confirmationCode: verificationCode, email: profile.email, password });
            info("Your password has been reset", "Password reset");
            // logger.log(message);
            navigate("/", { replace: true});
        } catch (err) {
            // error(err.body?.message || err.message, "Password reset failed");
            logger.error("Error resetting password:", error);
        }
    }

    const enableForgotPassword = !verificationCodeRequired && profile.email !== undefined && profile.email !== "";

    const enableSubmit =
        verificationCodeRequired &&
        (
            profile.email !== undefined && profile.email !== "" && password !== ""
        );

    return (
        <div className={bemName(componentName, "container")}>
            {
                !verificationCodeRequired &&
                <div>
                    <div className={bemName(componentName, "requestForm")}>
                        <div className={bemName(componentName, "input")}>
                            <Input type="text" placeholder="Email" value={profile.email} icon="user" iconPosition="left" autoComplete="email" onChange={(e) => profile.setEmail(e.target.value)} />
                        </div>
                        <div className={bemName(componentName, "actions")}>
                            <div className={bemName(componentName, "button")}>
                                <Button type="submit" text="Reset Password" disabled={!enableForgotPassword} onClick={() => handlePreparePasswordReset()} />
                            </div>
                            <div className={bemName(componentName, "links")}>
                                <Link to="/login">Cancel</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                verificationCodeRequired &&
                <div className={bemName(componentName, "resetForm")}>
                    <div className={bemName(componentName, "input")}>
                        <Input type="text" placeholder="Email" disabled={true} value={profile.email} autoComplete="email" onChange={(e) => profile.setEmail(e.target.value)} />
                    </div>
                    <div className={bemName(componentName, "input")}>
                        <Input type="text" placeholder="Email Verification Code" value={verificationCode} autoComplete="off" onChange={(e) => setVerificationCode(e.target.value)} />
                    </div>
                    <div className={bemName(componentName, "input")}>
                        <Input type="password" placeholder="New password" value={password} autoComplete="new-password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className={bemName(componentName, "actions")}>
                        <div className={bemName(componentName, "button")}>
                            <Button text="Reset password" disabled={!enableSubmit} onClick={() => handleResetPassword()} />
                        </div>
                        <div className={bemName(componentName, "links")}>
                            <Link to="/login">Cancel</Link>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
