import React from 'react';
import { withComponentName } from '../util/bemName';
import "./GotQuestion.scss"

const GotQuestion = () => {
    const componentName = 'GotQuestion';
    const bemClass = withComponentName(componentName);
    return (
        <>
          <div className={bemClass("text2")}>
            <div >Got a Question?</div>
            <div>02 9258 4010</div>
            </div>
            
        </>
    );
};

export default GotQuestion;
