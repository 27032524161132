import React, { useState } from "react";
import { withComponentName } from "../util/bemName";
import "./ReferAFriend.scss";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaWhatsapp,
  FaTelegram,
  FaEnvelope,
  FaReddit,
  FaPinterest,
  FaTumblr,
  FaSlack,
  FaBlogger,
  FaFacebookMessenger,
} from "react-icons/fa"; // Importing icons from react-icons/fa
import ShareIcon from "../res/icons/shareIcon.png";
import HumanIcon from "../res/icons/humanIcon.png";

const ReferAFriend = () => {
  const componentName = "ReferAFriend";
  const bemClass = withComponentName(componentName);

  const [showShareOptions, setShowShareOptions] = useState(false);

  const shareUrl = encodeURIComponent(window.location.href);
  const message = encodeURIComponent(
    `Check out this link: ${decodeURIComponent(shareUrl)}`
  );
  const [copySuccess, setCopySuccess] = useState("");
  const toggleShareOptions = () => {
    setShowShareOptions(!showShareOptions);
  };

  const shareViaPlatform = (platform: any) => {
    let url = "";

    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
        break;
      case "messenger":
        url = `fb-messenger://share/?link=${shareUrl}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${message}`;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`;
        break;
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=${message}`;
        break;
      case "telegram":
        url = `https://t.me/share/url?url=${shareUrl}&text=${message}`;
        break;
      case "email":
        url = `mailto:?subject=Check this out&body=${message}`;
        break;
      case "reddit":
        url = `https://reddit.com/submit?url=${shareUrl}&title=${message}`;
        break;
      case "pinterest":
        url = `https://pinterest.com/pin/create/button/?url=${shareUrl}&description=${message}`;
        break;
      case "tumblr":
        url = `https://www.tumblr.com/widgets/share/tool?canonicalUrl=${shareUrl}&title=${message}`;
        break;
      case "slack":
        url = `https://slack.com/intl/en-ru/app_redirect?channel=&team=`;
        break;
      case "blogger":
        url = `https://www.blogger.com/blog-this.g?u=${shareUrl}&n=${message}`;
        break;
      default:
        break;
    }

    if (url) {
      window.open(url, "_blank");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(decodeURIComponent(shareUrl));
    setCopySuccess("Copied!");
    setTimeout(() => setCopySuccess(""), 2000);
  };

  return (
    <div className={bemClass()}>
      <div className={bemClass("referWrapper")} onClick={toggleShareOptions}>
        <div className={bemClass("logo", "human")}>
          <img src={HumanIcon} alt="Human Icon" />
        </div>
        <div className={bemClass("logo", "referText")}>Refer a friend</div>
        <div className={bemClass("logo", "share")}>
          <img src={ShareIcon} alt="Share Icon" />
        </div>
      </div>
      {showShareOptions && (
        <div className={bemClass("modal")}>
          <div className={bemClass("modalContent")}>
            <div className={bemClass("share")}>
              <div className={bemClass("sharetext")}>Share</div>
              <div
                className={bemClass("closeBtn")}
                onClick={toggleShareOptions}
              >
                ×
              </div>
            </div>
            <div className={bemClass("shareOptionsWrapper")}>
              <div className={bemClass("shareOptions")}>
                <div
                  className={`${bemClass("shareOption")}`}
                  onClick={() => shareViaPlatform("facebook")}
                >
                  <div
                    className={`${bemClass("iconWrapper")}  ${bemClass(
                      "iconWrapper--facebook"
                    )}`}
                  >
                    <FaFacebook />
                  </div>
                  <span>Facebook</span>
                </div>
                <div
                  className={`${bemClass("shareOption")}`}
                  onClick={() => shareViaPlatform("messenger")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--messenger"
                    )}`}
                  >
                    <FaFacebookMessenger />
                  </div>
                  <span>Messenger</span>
                </div>
                <div
                  className={`${bemClass("shareOption")}`}
                  onClick={() => shareViaPlatform("twitter")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--twitter"
                    )}`}
                  >
                    <FaTwitter />
                  </div>
                  <span>Twitter</span>
                </div>
                <div
                  className={`${bemClass("shareOption")} `}
                  onClick={() => shareViaPlatform("linkedin")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--linkedin"
                    )}`}
                  >
                    <FaLinkedin />
                  </div>
                  <span>LinkedIn</span>
                </div>
                <div
                  className={`${bemClass("shareOption")} `}
                  onClick={() => shareViaPlatform("whatsapp")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--whatsapp"
                    )}`}
                  >
                    <FaWhatsapp />
                  </div>
                  <span>WhatsApp</span>
                </div>
                <div
                  className={`${bemClass("shareOption")} `}
                  onClick={() => shareViaPlatform("telegram")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--telegram"
                    )}`}
                  >
                    <FaTelegram />
                  </div>
                  <span>Telegram</span>
                </div>
                <div
                  className={`${bemClass("shareOption")}`}
                  onClick={() => shareViaPlatform("email")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--email"
                    )}`}
                  >
                    <FaEnvelope />
                  </div>
                  <span>Email</span>
                </div>
                <div
                  className={`${bemClass("shareOption")} `}
                  onClick={() => shareViaPlatform("reddit")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--reddit"
                    )}`}
                  >
                    <FaReddit />
                  </div>
                  <span>Reddit</span>
                </div>
                <div
                  className={`${bemClass("shareOption")} `}
                  onClick={() => shareViaPlatform("pinterest")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--pinterest"
                    )}`}
                  >
                    <FaPinterest />
                  </div>
                  <span>Pinterest</span>
                </div>
                <div
                  className={`${bemClass("shareOption")}`}
                  onClick={() => shareViaPlatform("tumblr")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--tumblr"
                    )}`}
                  >
                    <FaTumblr />
                  </div>
                  <span>Tumblr</span>
                </div>
                <div
                  className={`${bemClass("shareOption")}`}
                  onClick={() => shareViaPlatform("slack")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--slack"
                    )}`}
                  >
                    <FaSlack />
                  </div>
                  <span>Slack</span>
                </div>

                <div
                  className={`${bemClass("shareOption")} `}
                  onClick={() => shareViaPlatform("blogger")}
                >
                  <div
                    className={`${bemClass("iconWrapper")} ${bemClass(
                      "iconWrapper--blogger"
                    )}`}
                  >
                    <FaBlogger />
                  </div>
                  <span>Blogger</span>
                </div>
              </div>
            </div>
            <div className={bemClass("linkSection")}>
              <input
                type="text"
                value={decodeURIComponent(shareUrl)}
                readOnly
                className={bemClass("linkInput")}
              />
              <button
                className={bemClass("copyButton")}
                onClick={copyToClipboard}
              >
                Copy
              </button>
              {copySuccess && (
                <span className={bemClass("copySuccess")}>{copySuccess}</span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReferAFriend;
