import { Page } from "../components/Page";
import { ResetPasswordForm } from "../forms/ResetPasswordForm";

import "./ForgotPasswordPage.scss";

export function ForgotPasswordPage() {
    return (
        <Page title="Forgotten password">
            <ResetPasswordForm />
        </Page>
    );
}

export default ForgotPasswordPage;
