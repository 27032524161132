import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { DefaultService as UsersService } from "../api/SolarCloudApi";
import { FederatedLogins } from "../components/FederatedLogins";
import { Input } from "../components/Input";
import { Separator } from "../components/Separator";
import { errorHandler } from "../hooks/Error";
import { useLoginEmailMutation, useLoginEmailQuery } from "../hooks/LocalStorage";
import { QuerySummary } from "../hooks/QuerySummary";
import { useIdMutation } from "../hooks/Utils";
import { FormControl, NavigationButtons } from "../panel/BuyPanelsPanel";
import { bemName } from "../util/bemName";
import { FormProps, MutatorStatus } from "./Form";
import "./LoginForm.scss";

export type Credentials = {
    email?: string;
    password?: string;
    valid: boolean;
};

export type LoginResult = {
    status: MutatorStatus;
    error?: unknown;
}

type LoginFormProps = Omit<FormProps<Credentials>, "value"> & {
    signupAction: () => void;
    formControl: FormControl;
};

const componentName = "LoginForm";

export const useLoginFormMutator = (querySummary?: QuerySummary) => {
    const mutate = useIdMutation(
        "loginForm",
        async (credentials: Credentials) => {
            try {
                const response = await Auth.signIn(credentials.email!, credentials.password);

                // logger.log("@@@@ signIn response", response);

                if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
                    return { status: MutatorStatus.PasswordReset };
                } else {
                    return { status: MutatorStatus.Success };
                }
            } catch (err: any) {
                if (err.code === "UserNotConfirmedException") {

                    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                    const response = await UsersService.verificationCode(credentials.email);

                    return { status: MutatorStatus.VerificationRequired };
                } else if (err.code === "PasswordResetRequiredException") {
                    return { status: MutatorStatus.PasswordReset };
                } else {
                    return { status: MutatorStatus.Error, err };
                }
            }
        },
        {
            onError: errorHandler
        }
    );

    return mutate;
};

const useLoginFormController = (props: LoginFormProps) => {
    const { updateValue } = props;

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();

    const { data: loginEmail, isLoading } = useLoginEmailQuery();
    const { mutate: setLoginEmail } = useLoginEmailMutation();

    useEffect(
        () => {
            if (!isLoading) {
                setEmail(loginEmail);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading]
    );

    useEffect(
        () => {
            const valid =
                !!email &&
                email?.length !== 0 &&
                !!password &&
                password.length !== 0;

            setLoginEmail(email);

            updateValue?.(
                {
                    email,
                    password,
                    valid
                },
                valid
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [email, password]
    )

    return {
        ...props,
        email,
        setEmail,
        password,
        setPassword,
    }
};

export function LoginForm(props: LoginFormProps) {
    const { email, setEmail, setPassword, formControl } = useLoginFormController(props);

    return (
        <div className={bemName(componentName, "container")}>
            <FederatedLogins prefix="Login using " />
            <Separator text={"or"} />
            <Input placeholder="Email" autoComplete="username" value={email} onChange={(event) => setEmail(event.target.value)} />
            <Input type="password" placeholder="Password" autoComplete="password" onChange={(event) => setPassword(event.target.value)} />
            {/* <div className={bemName(componentName, "links")}>
                <Button asLink onClick={signupAction}>Register</Button>
            </div> */}
            <> <NavigationButtons formControl={formControl} /></>
        </div>
    );
}
