import { UserStatus } from "../api/SolarCloudApi";
import { useQuerySummary } from "../hooks/QuerySummary";
import { useAuthenticationQuery } from "../util/authentication";
import { EnergyPanel } from "./EnergyPanel";
import { MySavingsPanel } from "./MySavingsPanel";
import { NotificationPanel } from "./NotificationPanel";
import { TrackerPanel } from "./TrackerPanel";

export const DashboardPanel = () => {
    const querySummary = useQuerySummary();

    const { data: authentication } = useAuthenticationQuery(querySummary);

    const user = authentication?.user;

    return (
        <>
            {/* <Avatar /> */}
            <NotificationPanel />
            {
                user?.status === UserStatus.ACTIVE &&
                <>
                    <MySavingsPanel />
                    <EnergyPanel />
                </>
            }
            <TrackerPanel maxEntries={5} />
        </>
    );
}

export default DashboardPanel;
