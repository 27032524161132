/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrganisationType {
    AUSTRALIAN_PROPRIETY_COMPANY = 'AustralianProprietyCompany',
    AUSTRALIAN_PUBLIC_COMPANY = 'AustralianPublicCompany',
    INDIVIDUAL = 'Individual',
    JOINT_HOLDING = 'JointHolding',
    OTHER = 'Other',
    REGULATED_TRUST_WITH_CORPORATE_TRUSTEE = 'RegulatedTrustWithCorporateTrustee',
    REGULATED_TRUST_WITH_INDIVIDUAL_TRUSTEE = 'RegulatedTrustWithIndividualTrustee',
    UNREGULATED_TRUST_WITH_CORPORATE_TRUSTEE = 'UnregulatedTrustWithCorporateTrustee',
    UNREGULATED_TRUST_WITH_INDIVIDUAL_TRUSTEE = 'UnregulatedTrustWithIndividualTrustee',
}
