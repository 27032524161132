import { useMutation, useQuery, useQueryClient } from "react-query";
import { CountryInfo, Organisation, User } from "../api/SolarCloudApi";
import { EnergyBillInfo } from "../forms/EnergyBillForm";
import { InvalidOptions, invalidateQueryDependencies } from "./Dependencies";
import { QuerySummary } from "./QuerySummary";
import { useUuid } from "./Utils";

const getLocalStorage = <T>(id: string, defaultValue?: T) => {
    const fullId = `localStorage_${id}`;

    const valueString = localStorage.getItem(fullId);
    if (valueString === null || valueString === undefined) {
        return defaultValue;
    }

    const value = JSON.parse(valueString) as T;

    return value;
}

const setLocalStorage = <T>(id: string, value: T | undefined) => {
    const fullId = `localStorage_${id}`;

    if (value)
        localStorage.setItem(fullId, JSON.stringify(value));
    else
        localStorage.removeItem(fullId);

    return value;
}

export const useLocalStorageQuery = <T>(id: string, defaultValue?: T, querySummary?: QuerySummary) => {
    const fullId = `localStorage_${id}`;

    const query = useQuery(
        fullId,
        () => {
            const value = getLocalStorage(id, defaultValue);

            return value;
        }
    );

    querySummary?.updateQuery(id, "localStorage", query, id);

    return query;
}

export const useLocalStorageMutation = <T>(id: string, querySummary?: QuerySummary, options?: InvalidOptions) => {
    const fullId = `localStorage_${id}`;

    const queryClient = useQueryClient();

    const mutation = useMutation(
        async (value: T | undefined) => {
            return setLocalStorage(id, value);
        },
        {
            onSuccess: (value) => {
                if (options?.invalidate !== false) {
                    if (options?.invalidateSelf !== false) {
                        queryClient.setQueryData(fullId, value);
                    }

                    invalidateQueryDependencies("localStorage", id, options);
                }
            }
        }
    );

    querySummary?.updateMutation(id, "localStorage", mutation, id);

    return mutation;
}

export const useLoginEmailQuery = (querySummary?: QuerySummary) => {
    return useLocalStorageQuery<string>("loginEmail", undefined, querySummary);
}

export const useLoginEmailMutation = (querySummary?: QuerySummary) => {
    return useLocalStorageMutation<string>("loginEmail", querySummary);
}

export const useCountryOverrideQuery = (querySummary?: QuerySummary) => {
    return useLocalStorageQuery<CountryInfo>("countryOverride", undefined, querySummary);
}

export const useCountryOverrideMutation = (querySummary?: QuerySummary) => {
    return useLocalStorageMutation<CountryInfo>("countryOverride", querySummary);
}

export const getBuyPanels = () => {
    return getLocalStorage<number>("buyPanels");
}

export const setBuyPanels = (value: number | undefined) => {
    return setLocalStorage<number>("buyPanels", value);
}

export const useBuyPanelsQuery = (querySummary?: QuerySummary) => {
    return useLocalStorageQuery<number>("buyPanels", undefined, querySummary);
}

export const useBuyPanelsMutation = (querySummary?: QuerySummary) => {
    const mutation = useLocalStorageMutation<number>("buyPanels", querySummary);

    return mutation;
}

export const useEnergyBillQuery = (querySummary?: QuerySummary) => {
    const energyBill = useLocalStorageQuery<number>("energyBill", undefined, querySummary);

    return energyBill;
}

export const useEnergyBillMutation = (querySummary?: QuerySummary) => {
    const mutation = useLocalStorageMutation<number | null>("energyBill", querySummary, { invalidate: false });

    return {
        ...mutation,
        mutate: (energyBill: number | undefined) => {
            const energyBillForStorage = energyBill === undefined ? null : energyBill;

            const result = mutation.mutate(energyBillForStorage);

            return result;
        },
        mutateAsync: async (energyBill: number | undefined) => {
            const energyBillForStorage = energyBill === undefined ? null : energyBill;

            const result = mutation.mutateAsync(energyBillForStorage);

            return result;
        },
    };
}

export const useEnergyBillsQuery = (querySummary: QuerySummary | undefined, count: number) => {
    const query = useLocalStorageQuery<(number | undefined)[]>("energyBills", undefined, querySummary);

    const { data: energyBillsFromStorage } = query;

    if (energyBillsFromStorage) {
        return {
            ...query,
            data: energyBillsFromStorage.map(energyBill => energyBill === null ? undefined : energyBill),
        }
    }

    return query;
}

export const useEnergyBillsMutation = (querySummary?: QuerySummary) => {
    const queryClient = useQueryClient();

    const mutation = useLocalStorageMutation<(number | null)[]>("energyBills", querySummary, { invalidate: false });

    return {
        ...mutation,
        mutate: (energyBills: (number | undefined)[] | undefined) => {
            const energyBillsForStorage = energyBills?.map((energyBill) => energyBill === undefined ? null : energyBill);

            const result = mutation.mutate(energyBillsForStorage);

            queryClient.invalidateQueries("energyBills");

            return result;
        },
        mutateAsync: async (energyBills: (number | undefined)[] | undefined) => {
            const energyBillsForStorage = energyBills?.map((energyBill) => energyBill === undefined ? null : energyBill);

            const result = await mutation.mutateAsync(energyBillsForStorage);

            queryClient.invalidateQueries("energyBills");

            return result;
        },
    };
}

export const useEnergyBillInfoQuery = (querySummary: QuerySummary | undefined) => {
    return useLocalStorageQuery<EnergyBillInfo>("energyBillInfo", undefined, querySummary);
}

export const useEnergyBillInfoMutation = (querySummary?: QuerySummary, options?: InvalidOptions) => {
    return useLocalStorageMutation<EnergyBillInfo>("energyBillInfo", querySummary, options ?? { invalidate: false });
}

export interface SignupData {
    user: User;
    organisation?: Organisation;
}

export const useSignupDataQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const query = useQuery(
        "signupData",
        () => {
            const signupDataString = localStorage.getItem("signupData");

            const signupData = signupDataString ? JSON.parse(signupDataString) as SignupData : undefined;

            return signupData;
        }
    );

    querySummary?.updateQuery(id, "signupData", query);

    return query;
}

export const useSignupDataMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const mutation = useMutation(
        async (signupData: SignupData | undefined) => {
            if (signupData)
                localStorage.setItem("signupData", JSON.stringify(signupData));
            else
                localStorage.removeItem("signupData");

            return signupData;
        },
        {
            onSuccess: (signupData) => {
                invalidateQueryDependencies("signupData");
            }
        }
    );

    querySummary?.updateMutation(id, "signupData", mutation);

    return mutation;
}

