import { useEffect, useState } from "react";
import { JsonInvoice as Invoice, DefaultService as FinancialsService, OpenAPI as FinancialsApi } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import { error } from "../components/Messages";
import { Panel } from "../components/Panel"
import { bemName } from "../util/bemName";

import "./InvoicesPanel.scss";
import { doFetch } from "../util/fetch";

const componentName = "InvoicesPanel";

interface Props {
}

export const InvoicesPanel: React.FunctionComponent<Props> = () => {
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const init = async () => {
        try {
            const response = await FinancialsService.searchForInvoices();

            setInvoices(response.collection ?? []);
        } catch (err: any) {
            error(err.message, "Invoice error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(
        () => {
            init()
        },
        []
    );

    const items =
        loading ?
            <div className={bemName(componentName, "loading")}></div>
            :
            invoices.map(
                (invoice, index) => <InvoiceItem key={index} invoice={invoice} />
            );

    return (
        <Panel title="Invoices">
            <div className={bemName(componentName, "container")}>
                {/* <div className={bemName(componentName, "item", "header")}>
                    <div className={bemName(componentName, "date", "header")}>Date</div>
                    <div className={bemName(componentName, "invoiceNumber", "header")}>Invoice Number</div>
                    <div className={bemName(componentName, "tax", "header")}>Tax</div>
                    <div className={bemName(componentName, "total", "header")}>Total</div>
                    <div className={bemName(componentName, "status", "header")}>Status</div>
                    <div className={bemName(componentName, "actions", "header")}></div>
                </div>
                <div className={bemName(componentName,"separator")}></div> */}
                {items}
            </div>
        </Panel>
    )
}

type ItemProps = {
    invoice: Invoice;
};

const InvoiceItem: React.FunctionComponent<ItemProps> = ({invoice}) => {
    const downloadPdf = async () => {
        const token = 
            FinancialsApi.TOKEN === undefined ?
                undefined
                :
                typeof FinancialsApi.TOKEN === "string" ? 
                    FinancialsApi.TOKEN 
                    : 
                    await FinancialsApi.TOKEN({ method: "GET", url: `/invoices/${invoice.invoiceID}`});

        const pdfResponse = await doFetch(
            `${FinancialsApi.BASE}/invoices/${invoice.invoiceID}`,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        );

        const pdfContents = await pdfResponse.blob();

        var url = window.URL.createObjectURL(pdfContents);

        const anchor = document.createElement("a");
        anchor.download = (invoice.invoiceNumber || invoice.invoiceID) + ".pdf";
        anchor.target = "_blank";
        anchor.href = url;
        // logger.log("@@@@ anchor", anchor.href);
        try {
            document.body.append(anchor);

            anchor.click();
        } finally {
            document.body.removeChild(anchor);
        }
    }

    return (
        <div className={bemName(componentName, "item")}>
            <div className={bemName(componentName, "date")}>{Intl.DateTimeFormat(undefined, { dateStyle: "long" }).format(new Date(invoice.date!))}</div>
            {/* <div className={bemName(componentName, "invoiceNumber")}>{invoice.invoiceNumber}</div>
            <div className={bemName(componentName, "tax")}>{Intl.NumberFormat(undefined, {style: "currency", currency: "AUD"}).format(invoice.totalTax!)}</div> */}
            <div className={bemName(componentName, "total")}>{Intl.NumberFormat(undefined, {style: "currency", currency: "AUD"}).format(invoice.total!)}</div>
            {/* <div className={bemName(componentName, "status")}>{invoice.status}</div> */}
            <div className={bemName(componentName, "actions")}><Button text="Download" onClick={() => downloadPdf()}/></div>
        </div>
    );
}

export default InvoicesPanel;