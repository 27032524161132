const metricMultipliers: [ number, string, string ][] = [
    [  1000000000000000000000000, "Y", "Y" ],
    [     1000000000000000000000, "Z", "Z" ],
    [        1000000000000000000, "E", "E" ],
    [           1000000000000000, "P", "P" ],
    [              1000000000000, "T", "T" ],
    [                 1000000000, "G", "G" ],
    [                    1000000, "M", "M" ],
    [                       1000, "k", "k" ],
    [                          1,  "",  "" ],
    [                     0.0001, "m", "m" ],
    [                   0.000001, "μ", "μ" ],
    [                0.000000001, "n", "n" ],
    [             0.000000000001, "p", "p" ],
    [          0.000000000000001, "f", "f" ],
    [       0.000000000000000001, "a", "a" ],
    [    0.000000000000000000001, "z", "z" ],
    [ 0.000000000000000000000001, "y", "y" ],
];

const metricMassMultipliers: [ number, string, string ][] = [
    [  1000000000000000000000000, "Z",  "t" ],
    [     1000000000000000000000, "E",  "t" ],
    [        1000000000000000000, "P",  "t" ],
    [           1000000000000000, "T",  "t" ],
    [              1000000000000, "G",  "t" ],
    [                 1000000000, "M",  "t" ],
    [                    1000000, "k",  "t" ],
    [                       1000,  "",  "t" ],
    [                          1,  "", "kg" ],
    [                     0.0001,  "",  "g" ],
    [                   0.000001, "m",  "g" ],
    [                0.000000001, "μ",  "g" ],
    [             0.000000000001, "n",  "g" ],
    [          0.000000000000001, "p",  "g" ],
    [       0.000000000000000001, "f",  "g" ],
    [    0.000000000000000000001, "a",  "g" ],
    [ 0.000000000000000000000001, "z",  "g" ],
];

export type MetricOptions = {
    unit?: string;
    significantDigits?: number;
    multiplier?: number;
};

const metricScale = (value: number, unit?: string, multiplier?: number): [ number, string ] => {
    if (unit) {
        value = Math.abs(value);

        if (multiplier) {
            if (unit !== "kg") {
                for (const [ factor, prefix ] of metricMultipliers) {
                    if (factor === multiplier) {
                        return [ factor, prefix + unit ];
                    }
                }
            } else {
                for (const [ factor, prefix, newUnit ] of metricMassMultipliers) {
                    if (factor === multiplier) {
                        return [ factor, prefix + newUnit ];
                    }
                }
            }
        } else {
            if (unit !== "kg") {
                for (const [ factor, prefix ] of metricMultipliers) {
                    if (value >= factor) {
                        return [ factor, prefix + unit ];
                    }
                }
            } else {
                for (const [ factor, prefix, newUnit ] of metricMassMultipliers) {
                    if (value >= factor) {
                        return [ factor, prefix + newUnit ];
                    }
                }
            }
        }
    }

    return [ 1, unit ?? "" ];
}

export const metricFormat = (rawValue: number | undefined, options: MetricOptions) => {
    if (rawValue === undefined || rawValue === null) {
         return undefined;
    }

    const { unit, significantDigits, multiplier } = options;

    if (unit === undefined) {
        return Intl.NumberFormat(undefined, { maximumSignificantDigits: significantDigits }).format(rawValue);
    }

    const unitOverride = unit === "kWh" ? "Wh" : unit;
    const rawValueOverride = unit === "kWh" ? rawValue * 1000 : rawValue;

    const [ factor, suffix ] = metricScale(rawValueOverride, unitOverride, multiplier);

    const value = rawValueOverride / factor;

    return Intl.NumberFormat(undefined, { maximumSignificantDigits: significantDigits }).format(value) + " " + suffix;
}

export const metricFormatTextAndSuffix = (rawValue: number | undefined, options: MetricOptions) => {
    if (rawValue === undefined || rawValue === null) {
         return undefined;
    }

    const { unit, significantDigits, multiplier } = options;

    if (unit === undefined) {
        return { text: Intl.NumberFormat(undefined, { maximumSignificantDigits: significantDigits }).format(rawValue), suffix: "" };
    }

    const unitOverride = unit === "kWh" ? "Wh" : unit;
    const rawValueOverride = unit === "kWh" ? rawValue * 1000 : rawValue;

    const [ factor, suffix ] = metricScale(rawValueOverride, unitOverride, multiplier);

    const value = rawValueOverride / factor;

    return { text: Intl.NumberFormat(undefined, { maximumSignificantDigits: significantDigits }).format(value), suffix };
}

type CurrencyFormatOptions = {
    currency?: "AUD";
    fractionalDigits?: number;
    excludeCurrencyId?: boolean;
    excludeCurrencySymbol?: boolean;
}

export const currencyFormat = (rawValue: number | undefined, options?: CurrencyFormatOptions) => {
    if (rawValue === undefined || rawValue === null) {
        return undefined;
    }

    const currency = options?.currency ?? "AUD";
    const fractionalDigits = options?.fractionalDigits ?? 2;

    const parts: string[] = [];

    if (!options?.excludeCurrencyId) {
        parts.push("A ");
    }

    if (!options?.excludeCurrencySymbol) {
        parts.push("$");
    }

    parts.push(
        Intl.NumberFormat(
            undefined, 
            { 
                currency: currency, 
                minimumFractionDigits: fractionalDigits, 
                maximumFractionDigits: fractionalDigits 
            }
        ).format(rawValue)
    );

    return parts.join("");
}

export const dateTimeFormat = (value: Date | string | undefined) => {
    if (value === undefined) {
        return undefined;
    }

    if (typeof value === "string") {
        value = new Date(value);
    }

    return Intl.DateTimeFormat(undefined, { dateStyle: "short" }).format(value);
}

export const dateFormat = (value: Date | string | undefined) => {
    if (value === undefined) {
        return undefined;
    }

    if (typeof value === "string") {
        value = new Date(value);
    }

    return Intl.DateTimeFormat(undefined, { dateStyle: "short" }).format(value);
}

export const joulesFormat = (value: number | undefined) => {
    if (value === undefined) {
        return undefined;
    }

    const kiloWattHours = value * 2.77778e-7;

    return metricFormat(kiloWattHours, { significantDigits: 3, unit: "kWh" });
}

export const toInteger = (rawValue?: string | number | null): number | undefined => {
    if (typeof(rawValue) === "number") {
        return Math.floor(rawValue);
    }

    if (!rawValue) {
        return undefined;
    }

    const value = parseInt(rawValue);
    if (!(value > 0)) {
        return undefined;
    }

    return value;
}

export const percentageFormatter = (value: number | undefined) => {
    if (value === undefined) {
        return "—";
    }

    return Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }).format(value);
}
