import { Libraries, useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, { Suggestion, getDetails } from "use-places-autocomplete";
import { v4 as uuid } from "uuid";
import { AddressType, CountryInfo, OrganisationAddress, UserAddress } from "../api/SolarCloudApi";
import { useEnvironmentQuery } from "../hooks/Environment";
import { bemName } from "../util/bemName";
import { Dropdown, Option } from "./Dropdown";

import { useEffect } from "react";
import { CountryOptions, useCountries } from "../hooks/Countries";
import { useQuerySummary } from "../hooks/QuerySummary";
import "./AddressSearch.scss";
import { error } from "./Messages";

type AddressUnion = UserAddress | OrganisationAddress;

type CountrySpec = string | string[] | CountryInfo | CountryInfo[];

type Props = {
    placeholder?: string,
    address?: AddressUnion,
    setAddress: (address: AddressUnion) => void,
    options?: CountryOptions,
};

const extractCountryCodes = (countrySpec?: CountrySpec): string[] | undefined => {
    if (!countrySpec) {
        return undefined;
    }

    if (Array.isArray(countrySpec)) {
        return countrySpec.map(
            item => {
                if (typeof (item) === "string") {
                    return item;
                } else {
                    return (item as CountryInfo).code!;
                }
            }
        );
    } else {
        if (typeof (countrySpec) === "string") {
            return [countrySpec];
        } else {
            return [(countrySpec as CountryInfo).code!];
        }
    }
}

const extractAddressComponent = (details: google.maps.places.PlaceResult, name: string, short?: boolean) => {
    for (const component of details.address_components ?? []) {
        for (const type of component.types) {
            if (type === name) {
                return short ? component.short_name : component.long_name;
            }
        }
    }

    return undefined;
}

const libraries: Libraries = ["places"];

export const AddressSearch = ({ placeholder, address, setAddress, options }: Props) => {
    const querySummary = useQuerySummary();

    const { data: environment } = useEnvironmentQuery(querySummary);
    const { countries } = useCountries(options);

    const isLoading = querySummary.get().isLoading;

    const { isLoaded: isScriptLoaded, loadError: scriptLoadError } = useLoadScript(
        {
            googleMapsApiKey: environment?.google.apiKey || "",
            libraries: libraries,
        }
    );

    const { init, value, setValue, suggestions, clearSuggestions } = usePlacesAutocomplete(
        {
            requestOptions: {
                componentRestrictions: {
                    country: extractCountryCodes(countries) ?? null,
                },
            },
            initOnMount: false,
        }
    );

    useEffect(
        () => {
            if (isScriptLoaded && !isLoading) {
                init();
            }

            if (scriptLoadError) {
                error("Address lookup script failed to load. Please enter address manually.");
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isScriptLoaded, scriptLoadError, isLoading]
    )

    const updateValue = (value: string) => {
        setValue(value);
    }

    const updateSelection = async (suggestion: Suggestion | undefined) => {
        if (suggestion) {
            // setValue(suggestion?.description, false);
            setValue("", false);

            clearSuggestions();

            if (suggestion.place_id) {
                const details = await getDetails(
                    {
                        placeId: suggestion.place_id,
                        fields: ["address_components"],
                    }
                ) as google.maps.places.PlaceResult;

                // console.log("@@@@", "address suggestion", suggestion);
                // console.log("@@@@", "address details", details);

                const newAddress: UserAddress = {
                    uuid: address?.uuid ?? uuid(),
                    type: AddressType.POSTAL,
                    line1: suggestion.structured_formatting.main_text,
                    city: extractAddressComponent(details, "locality"),
                    state: extractAddressComponent(details, "administrative_area_level_1", true),
                    postcode: extractAddressComponent(details, "postal_code"),
                    country: extractAddressComponent(details, "country"),
                };

                setAddress?.(newAddress);
            }
        } else {
            clearSuggestions();
        }
    }

    const addressOptions: Option<Suggestion>[] =
        suggestions.data.map(
            (suggestion) => {
                return {
                    key: suggestion.place_id,
                    name: suggestion.description,
                    value: suggestion,
                };
            }
        );

    // console.log("@@@@ address search:", isScriptLoaded, countries, scriptLoadError);

    return (
        <div className={bemName("AddressSearch", "container")}>
            <Dropdown
                placeholder={placeholder ?? "Enter address"}
                autoShowList
                value={value}
                options={addressOptions}
                onEdit={updateValue}
                onSelect={updateSelection}
            />
        </div>
    );
}
