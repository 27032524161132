import { Page } from "../components/Page";
import { SolarCloudIcon } from "../data/SolarCloudIcon";
import { bemName } from "../util/bemName";

import "./LoadingPage.scss";

const componentName = "LoadingPage"

export const LoadingPage = () => {
    return (
        <Page>
            <div className={bemName(componentName, "spinnerContainer")}>
                <div className={bemName(componentName, "spinner")}>
                    {SolarCloudIcon.Spinner}
                </div>
            </div>
        </Page>
    );
}

export default LoadingPage;
