import { Panel } from "../components/Panel";
import { bemName } from "../util/bemName";

import { ChangePasswordForm } from "../forms/ChangePasswordForm";

import "./MySavingsPanel.scss";

const componentName = "ChangePasswordPanel";

interface Props {
}

export const ChangePasswordPanel: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <Panel title="Change password">
            <div className={bemName(componentName, "data")}>
                <ChangePasswordForm />
            </div>
        </Panel>
    );
}

export default ChangePasswordPanel;
