import { useMutation } from "react-query";
import { ApiError, Order } from "../api/SolarCloudApi";
import { CreditCardData, CreditCardInput } from "../components/CreditCardInput";
import { invalidateQueryDependencies } from "../hooks/Dependencies";
import { errorHandler } from "../hooks/Error";
import { useNewOrderMintPaymentMutation } from "../hooks/Order";
import { QuerySummary } from "../hooks/QuerySummary";
import { useUuid } from "../hooks/Utils";
import { bemName } from "../util/bemName";
import { FormProps } from "./Form";
import { OrderSummaryForm } from "./OrderSummaryForm";

import EnjoyFlexibility from "../components/EnjoyFlexibility";
import GotQuestion from "../components/GotQuestion";
import ReferAFriend from "../components/ReferAFriend";
import { FormControl, NavigationButtons } from "../panel/BuyPanelsPanel";
import { currencyFormat } from "../util/formats";
import "./PaymentForm.scss";
import { PaymentTypes } from "./PaymentTypes";
import { TestimonialForm } from "./TestimonialForm";

export enum PaymentType {
    Off,
    Apple,
    Google,
    Mint,
    PayPal,
    Contact,
};

export type OrderPayment = {
    order?: Order;
    payment?: {
        type: PaymentType;
        details?: CreditCardData;
    },
    valid: boolean;
};

type PaymentFormProps = FormProps<OrderPayment, "orderPayment", "updateOrderPayment"> & {
    formControl: FormControl;
}

type OrderPaymentResponse = {
    status: "Success";
    order: Order;
} | {
    status: "Error";
    error: ApiError;
}

export const useOrderPaymentMutator = (querySummary?: QuerySummary) => {
    const id = useUuid();
    const { mutateAsync: makePayment } = useNewOrderMintPaymentMutation(querySummary);

    const mutation = useMutation(
        async (orderPayment: OrderPayment): Promise<OrderPaymentResponse> => {
            try {
                const { order, payment } = orderPayment;
                const { details: cardData } = payment!;

                const updatedOrder = await makePayment({ order: order!, cardData: cardData! });

                return { status: "Success", order: updatedOrder };
            } catch (err) {
                return { status: "Error", error: err as ApiError };
            }
        },
        {
            onSuccess: () => {
                invalidateQueryDependencies("newOrder");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "newOrder", mutation);

    return mutation;
}

const componentName = "PaymentForm";

const usePaymentForm = (props: PaymentFormProps) => {
    const { orderPayment, updateOrderPayment, formControl } = props;
    const { order, payment } = orderPayment ?? {};
    const { details } = payment ?? {};

    const updateCreditCardData = (creditCardData?: CreditCardData) => {
        const orderPayment: OrderPayment =
            creditCardData ?
                {
                    order,
                    payment: {
                        type: PaymentType.Mint,
                        details: creditCardData,
                    },
                    valid: creditCardData?.valid,
                }
                :
                {
                    order,
                    payment: {
                        type: PaymentType.Mint,
                    },
                    valid: false,
                }

        updateOrderPayment?.(orderPayment);
    }

    return {
        ...props,
        order,
        creditCardData: details,
        updateCreditCardData,
        formControl,
    };
};

export const PaymentForm = (props: PaymentFormProps) => {
    const {
        id,
        key,
        order,
        creditCardData,
        updateCreditCardData,
        formControl,
    } = usePaymentForm(props);

    const isFailureCase = formControl?.title === "Payment error" ? true : false
    const depositFormatted = currencyFormat(order?.totalDeposit, {
        excludeCurrencyId: true,
      });
    return (
        <div id={id} key={key} className={bemName(componentName, "paymentForm")}>
            {isFailureCase && (<><div className={bemName(componentName, "failText")}>Likely the internet dropped out mid transaction (banks you got to love them)... Please try again. You can check your bank online to make sure it did not go through. Payment ready below.</div>
         <div  className={bemName(componentName, "depositOnlyWrapper")}><div className={bemName(componentName, "depositOnlyText")}>Deposit only</div>
          <div className={bemName(componentName, "depositAmount")}>{depositFormatted}</div>
          </div> 
            </>)}
            <CreditCardInput value={creditCardData} updateValue={updateCreditCardData} />
            {!isFailureCase && (<OrderSummaryForm order={order} />)}
            <div  className={isFailureCase ? bemName(componentName, "navWrapper"):""}> <NavigationButtons formControl={formControl} /></div>
            {isFailureCase &&(<div className={bemName(componentName, "separator")}></div>)}
            <EnjoyFlexibility />
            <GotQuestion />
            <PaymentTypes />
            <TestimonialForm />
            <ReferAFriend />
        </div>
    );
}