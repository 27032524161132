import { ReactNode } from "react";
import { withComponentName } from "../util/bemName";

import "./Button.scss";

const componentName = "Button";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    raw?: boolean;
    asLink?: boolean;
    selectable?: boolean;
    selected?: boolean;
    bordered?: boolean;
    text?: string;
    [key: string]: any;
    children?: ReactNode | ReactNode[];
}

export const Button = ({ id, key, className, raw, asLink, selectable, selected, bordered, disabled, text, children, ...props }: Props) => {
    const classNames: string[] = [ 
        asLink && `${componentName}Link`, 
        raw && `${componentName}Raw`, 
        className, 
        componentName 
    ].filter(
        name => typeof(name) === "string"
    ) as string[];

    const modifiers: string [] = [
        disabled && "disabled",
        selectable && "selectable",
        selectable && selected === true && "selected",
        selectable && selected === false && "deselected",
        bordered && "bordered",
    ].filter(
        name => typeof(name) === "string"
    ) as string[];

    const bemClass = withComponentName(classNames);

    if (!raw && !bordered && !selectable && !asLink) {
        return (
            <button {...props} disabled={disabled} className={bemClass(undefined, ...modifiers)} {...props}>
                <div className={bemClass("slider", ...modifiers)} />
                <div className={bemClass("text", ...modifiers)}>
                    {text}
                    {children}
                </div>
            </button>
        );
    } else {
        return (
            <button {...props} disabled={disabled} className={bemClass(undefined, ...modifiers)}>
                <div className={bemClass("text", ...modifiers)}>
                    {text}
                    {children}
                </div>
            </button>
        );
    }
}