import { useContext, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Button } from '../components/Button';
import { FederatedLogins } from '../components/FederatedLogins';
import { Page } from '../components/Page';
import { Separator } from '../components/Separator';
import ProfileContext from '../contexts/ProfileContext';
import { MutatorStatus } from '../forms/Form';
import { Credentials, LoginForm, useLoginFormMutator } from '../forms/LoginForm';
import { useEnvironmentQuery } from '../hooks/Environment';
import { useNewOrderQuery } from '../hooks/Order';
import { useQuerySummary } from '../hooks/QuerySummary';
import { useAuthenticationQuery } from '../util/authentication';
import { bemName } from '../util/bemName';
import "./LoginPage.scss";

export const LoginPage = () => {
    const profile = useContext(ProfileContext);

    const [credentials, setCredentials] = useState<Credentials>();
    const { mutateAsync: performLogin } = useLoginFormMutator();

    const querySummary = useQuerySummary();
    const { data: environment } = useEnvironmentQuery(querySummary);
    const { data: authorisation } = useAuthenticationQuery(querySummary);
    const { data: newOrder } = useNewOrderQuery(querySummary);
    const isLoading = querySummary.get().isLoading;

    const navigate = useNavigate();

    const user = authorisation?.user;

    const handleLogin = async () => {
        const loginOutcome = await performLogin(credentials!);

        switch (loginOutcome.status) {
            case MutatorStatus.Success:
                navigate("/");
                break;
            case MutatorStatus.VerificationRequired:
                navigate("/email/verify");
                break;
            case MutatorStatus.PasswordReset:
                navigate("/resetpassword");
                break;
        }
    }

    const oauthState =
        newOrder?.uuid ?
            `order:${newOrder.uuid};country:${profile.country}` :
            undefined;

    if (!isLoading && authorisation?.loggedIn) {
        return <Navigate to={"/"} />;
    }

    return (
        <Page title="Login" onEnter={handleLogin} isLoading={querySummary.get().isLoading}>
            {
                false &&
                !user &&
                <>
                    <div className={bemName("LoginPage", "socialContainer")}>
                        <FederatedLogins oauthState={oauthState} prefix="Login with " />
                    </div>
                    <Separator text={"or"} />
                </>
            }
            <div className={bemName("LoginPage", "formContainer")}>
                <LoginForm signupAction={() => { }} updateValue={setCredentials} formControl={{ title: "Login" }} />
                <div className={bemName("LoginPage", "spacer", "large")} />
                <div className={bemName("LoginPage", "submitLine")}>
                    <Button text={"Login"} disabled={!credentials?.valid} onClick={handleLogin} />
                    <div className={bemName("LoginPage", "links")}>
                        <Link to="/forgot-password" className={bemName("LoginPage", "link")}>Forgotten your password?</Link>
                        {
                            environment?.hasFeature("Signup") &&
                            <Link to="/buypanels" className={bemName("LoginPage", "link")}>Dont' have an account?</Link>
                        }
                    </div>
                </div>
            </div>
        </Page>
    );
}

export default LoginPage;
