export function bemName(blockOrArray: string | string[], element?: string, ...modifiers: (string | undefined)[]): string {
    const classNames: string[] = [];

    const blocks = Array.isArray(blockOrArray) ? blockOrArray : [ blockOrArray ];
  
    for (const block of blocks) {
      if (element) {
        classNames.push(`${block}__${element}`);
      } else {
        classNames.push(block);
      }
    
      modifiers.forEach((modifier) => {
        if (modifier) {
          classNames.push(`${block}${element ? `__${element}` : ""}_${modifier}`);
        } 
      });
    }
  
    return classNames.join(" "); 
}

export const withComponentName = (blockName: string | string[]) => {
  return (element?: string, ...modifiers: (string | undefined)[]) => bemName(blockName, element, ...modifiers);
}
  