import { Panel } from "../components/Panel";
import { ProfileForm } from "../forms/ProfileForm";
import { FormControl } from "./BuyPanelsPanel";

export const ProfilePanel = () => {
    const formControl: FormControl = {
        title: "Profile",
        nextTitle: "Save",
        goNext: () => {},
    };

    return (
        <Panel title="Profile">
            <ProfileForm proceedIfValid={false} formControl={formControl}/>
        </Panel>
    );
}

export default ProfilePanel;