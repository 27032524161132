import { Link } from "react-router-dom";
import { SolarCloudIconType } from "../data/SolarCloudIcon";
import { bemName } from "../util/bemName";

import "./SidebarItem.scss";
// import { useTranslation } from "react-i18next";
import { Profile } from "../contexts/ProfileContext";
import { User } from "../api/SolarCloudApi";

export type EnablementProps = {
    profile?: Profile;
    user?: User;
    federatedUser?: boolean;
};

export type EnabledFunction = (props?: EnablementProps) => boolean;

export type SidebarItemProps = {
    type: "item";
    icon: SolarCloudIconType;
    titleId: string;
    enabled?: boolean | EnabledFunction; 
    enablement?: EnablementProps
    to: string;
    selected?: boolean;
    select?: () => void;
};

export const SidebarItem = ({ icon, titleId, enabled, to, selected, select, enablement }: SidebarItemProps) => {
    // const { t } = useTranslation();
    const t = (value: string) => value;

    const isEnabled = enabled === undefined || enabled === true || (typeof enabled === "function" && enabled(enablement));

    if (!isEnabled) {
        return null;
    }

    const selectedModifier = selected ? "selected" : undefined;

    return (
        <div className={bemName("SidebarItem", undefined, selectedModifier)}>
            <Link to={to} onClick={() => select && select()}>
                <div className={bemName("SidebarItem", "container", selectedModifier)}>
                    <div className={bemName("SidebarItem", "icon", selectedModifier)}>{icon}</div>
                    <div className={bemName("SidebarItem", "pageName", selectedModifier)}>{t(titleId)}</div>
                </div>
            </Link>
        </div>
    );
}

type ValueLabel = {
    value: string;
    label?: string;
}

type ValueLabelFunction = () => ValueLabel[];

export type SidebarSelectProps = {
    type: "select";
    items?: ValueLabel[] | ValueLabelFunction;
    selectedItem?: ValueLabel;
}

export const SidebarSelect = ({ items, selectedItem }: SidebarSelectProps) => {
    // const { t } = useTranslation();
    
    return (
        <div className={bemName("SidebarSelect")}>
        </div>
    );
}