import { useNavigate } from "react-router-dom";
import { useTrackerEventQuery } from "../hooks/Tracker";
import { bemName } from "../util/bemName";
import { Button } from "./Button";
import { TrackerEventView, TrackerSeparatorType } from "./TrackerEventView";
import "./TrackerView.scss";

type Props = {
    maxEvents?: number;
}

export const TrackerView = ({ maxEvents }: Props) => {
    const navigate = useNavigate();
    const { data: allTrackerEventsOptional } = useTrackerEventQuery();

    const allTrackerEvents = allTrackerEventsOptional || [];

    const overflow = maxEvents !== undefined && allTrackerEvents.length > maxEvents;

    const trackerEvents = overflow ? allTrackerEvents.slice(0, maxEvents) : allTrackerEvents;

    const finalSeparatorType = overflow ? TrackerSeparatorType.More : TrackerSeparatorType.Last;

    const eventsJSX = trackerEvents.map((event, index) => (
        <TrackerEventView key={index} index={index} separator={index + 1 === trackerEvents.length ? finalSeparatorType : TrackerSeparatorType.Normal} event={event} />
    ))

    const style = { gridTemplateRows: `repeat(${trackerEvents.length}, calc(2.4cm + 2px) auto)` };

    return (
        <div className={bemName("TrackerView", "container")} style={style}>
            {eventsJSX}
            {
                overflow &&
                <Button text="more" onClick={() => navigate("/dashboard/tracker")}/>
            }
        </div>
    );
}