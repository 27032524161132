import { useEffect, useState } from "react";
import { Location, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CountryInfo, OrderStatus, OwnerType, UserStatus } from "../api/SolarCloudApi";
import { Breadcrumb } from "../components/Breadcrumbs";
import { Button } from "../components/Button";
import { error, errorWithTimeout, infoWithTimeout } from "../components/Messages";
import { Panel } from "../components/Panel";
import { Switch } from "../components/Switch";
import { BuyPanelsCompleteForm } from "../forms/BuyPanelsCompleteForm";
import { BuyPanelsForm } from "../forms/BuyPanelsForm";
import EnergyBillForm, { EnergyBillInfo, emptyEnergyBillInfo } from "../forms/EnergyBillForm";
import { MutatorStatus } from "../forms/Form";
import { Credentials, LoginForm, useLoginFormMutator } from "../forms/LoginForm";
import { OrganisationForm, ValidatedOrganisation } from "../forms/OrganisationForm";
import { PaymentAcknowledgeForm } from "../forms/PaymentAcknowledgeForm";
import { OrderPayment, PaymentForm, useOrderPaymentMutator } from "../forms/PaymentForm";
import { ProfileForm, ProfileUser } from "../forms/ProfileForm";
import { SignupForm, useSignupFormMutator } from "../forms/SignupForm";
import { SignupUnavailableForm, useSignupUnavailableFormMutator } from "../forms/SignupUnavailableForm";
import { LoginVerification, VerificationForm, useVerificationCodeMutation } from "../forms/VerificationForm";
import { errorHandler } from "../hooks/Error";
import { useBuyPanelsMutation, useEnergyBillInfoMutation, useEnergyBillInfoQuery } from "../hooks/LocalStorage";
import { useNewOrderCheckoutMutation, useNewOrderMutation, useNewOrderQuery } from "../hooks/Order";
import { useQuerySummary } from "../hooks/QuerySummary";
import { useCountryAvailable } from "../hooks/Reference";
import { useAuthenticationQuery } from "../util/authentication";
import { bemName } from "../util/bemName";
import { stripValidated } from "../util/objects";
import "./BuyPanelsPanel.scss";


export enum FormState {
    Loading = "loading",
    SignupUnavailable = "signup-unavailable",
    EnergyBill = "energy-bill",
    Panels = "panels",
    Signup = "signup",
    Verification = "verification",
    Login = "login",
    Organisation = "organisation",
    Payment = "payment",
    PaymentAcknowledge = "payment-acknowledge",
    Profile = "profile",
    Complete = "complete",
    Leaving = "leaving",
}

const formStateFromLocation = (location: Location): FormState | undefined => {
    const pathParts = location.pathname.split("/");

    while (pathParts[pathParts.length - 1] === "" && pathParts.length !== 0) {
        pathParts.pop();
    }

    if (pathParts.length === 0) {
        return undefined;
    }

    const targetState = pathParts[pathParts.length - 1];

    for (const formState of Object.values(FormState)) {
        if (formState === targetState) {
            return targetState as FormState;
        }
    }

    return undefined;
}

const cleanNumber = (value: string) => {
    return value.replaceAll(/[^0-9]/g, "");
}

export type FormControl = {
    title: string;
    goBack?: () => void;
    backTitle?: string;
    backValid?: boolean;
    backMessage?: string;
    goNext?: () => void;
    nextTitle?: string;
    nextValid?: boolean;
    forceState?: () => (FormState | undefined);
    email?: string;
}

export type NavigationButtonsProps = {
    formControl: FormControl;

    preBack?: () => void | Promise<void>;
    preNext?: () => void | Promise<void>;
}

export const NavigationButtons = ({ preBack, preNext, formControl }: NavigationButtonsProps) => {
    const { goBack, backTitle, backValid, backMessage, goNext, nextTitle, nextValid } = formControl;

    const handleBack = async () => {
        try {
            await preBack?.();
            goBack?.();
        } catch (err) {
            errorHandler(err);
        }
    }

    const handleNext = async () => {
        try {
            await preNext?.();
            goNext?.();
        } catch (err) {
            errorHandler(err);
        }
    }

    return (
        <div className={bemName(componentName, "actionContainer")}>
            {
                (
                    goBack &&
                    <Button disabled={backValid === false} text={backTitle} onClick={handleBack} className="backButton" />
                )
                ||
                <div className={bemName(componentName, "message")}>{backMessage}</div>
            }
            {
                (
                    goNext &&
                    <Button disabled={nextValid === false} text={nextTitle} onClick={
                        () => {
                            if (nextValid !== undefined) {
                                if (nextValid) {
                                    handleNext()
                                }
                                else {
                                    return
                                }

                            }
                            else {
                                handleNext()
                            }
                        }
                    } />
                )
                ||
                <div></div>
            }
        </div>
    );
}
type BreadcrumbControl = Partial<Breadcrumb> & {
    text: string;
    formState: FormState | FormState[];
}

const breadcrumbControls: BreadcrumbControl[] = [
    {
        text: "Panels",
        formState: [FormState.Loading, FormState.EnergyBill, FormState.Panels, FormState.SignupUnavailable],
    },
    {
        text: "Signup",
        formState: [FormState.Signup],
    },
    {
        text: "Verification",
        formState: [FormState.Verification],
    },
    {
        text: "Ownership",
        formState: [FormState.Organisation],
    },
    {
        text: "Payment",
        formState: [FormState.Payment, FormState.PaymentAcknowledge],
    },
    {
        text: "Profile",
        formState: [FormState.Profile],
    },
    {
        text: "Complete",
        formState: [FormState.Complete, FormState.Leaving],
    },
];

const getBreadcrumbs = (formState: FormState) => {
    const index = breadcrumbControls.findIndex(control => control.formState.indexOf(formState) !== -1);

    const breadcrumbs: Breadcrumb[] = breadcrumbControls.map(
        (control, i) => {
            return {
                type: i < index ? "past" : i === index ? "current" : "future",
                text: control.text,
            };
        }
    )

    return breadcrumbs;
}

type BuyPanelsPanelProps = {
    defaultPadding?: boolean;
    addToBagCallBck:(count:number| undefined)=>void;
}

const componentName = "BuyPanelsPanel";
export const BuyPanelsPanel = ({ defaultPadding , addToBagCallBck}: BuyPanelsPanelProps) => {
    const querySummary = useQuerySummary();

    const [searchParams] = useSearchParams();
    // const searchParams = new URLSearchParams(window.location.search);
    const energyBillParam = searchParams.get("energyBill");
    const panelsParam = searchParams.get("panels");

    const { stateParam } = useParams();
    const [formState, setFormState] = useState<FormState>(FormState.Loading);
    const [paymentFailure, setPaymentFailure] = useState<boolean>(false);

    const isCountryAvailable = useCountryAvailable(querySummary);
    // const { data: countryOverride } = useCountryOverrideQuery(querySummary);
    const { overrideCountry } = useSignupUnavailableFormMutator(querySummary);
    const { createContact } = useSignupUnavailableFormMutator(querySummary);

    const [energyBillInfo, setEnergyBillInfo] = useState<EnergyBillInfo>();
    const { data: savedEnergyBillInfo } = useEnergyBillInfoQuery(querySummary);
    const { mutate: saveEnergyBillInfo } = useEnergyBillInfoMutation(querySummary);

    const { mutateAsync: setBuyPanels } = useBuyPanelsMutation(querySummary);
    const { data: newOrder } = useNewOrderQuery(querySummary);
    const { mutateAsync: saveNewOrder } = useNewOrderMutation(querySummary);
    const { mutateAsync: checkoutNewOrder } = useNewOrderCheckoutMutation(querySummary);
    // const { mutateAsync: cancelCheckoutNewOrder } = useNewOrderCheckoutCancelMutation(querySummary);

    const [signupCredentials, setSignupCredentials] = useState<Credentials>();
    const { mutateAsync: createLogin } = useSignupFormMutator(querySummary);

    const [loginVerification, setLoginVerification] = useState<LoginVerification>();
    const { mutateAsync: verifyLogin } = useVerificationCodeMutation(querySummary);

    const [loginCredentials, setLoginCredentials] = useState<Credentials>();
    const { mutateAsync: login } = useLoginFormMutator(querySummary);

    const [organisation, setOrganisation] = useState<ValidatedOrganisation>();

    const [orderPayment, setOrderPayment] = useState<OrderPayment>();
    const { mutateAsync: makeOrderPayment } = useOrderPaymentMutator(querySummary);

    const [user, setUser] = useState<ProfileUser>();

    const { data: authentication } = useAuthenticationQuery(querySummary);

    const { isLoading, isMutating } = querySummary.get();

    const navigate = useNavigate();

    const location = useLocation();

    const basePath =
        location.pathname.indexOf("dashboard") !== -1 ?
            `/dashboard/buypanels`
            :
            `/buypanels`
        ;

    // console.log("@@@@ form state:", formState);
 
    useEffect(
        () => {
            if (isLoading) {
                return;
            }

            var newFormState = formStateFromLocation(location) ?? FormState.Loading;

            if (stateParam && stateParam !== formState) {
                newFormState = stateParam as FormState;
            }

            var updatedEnergyBillInfo: EnergyBillInfo | undefined = undefined;
            if (energyBillParam) {
                const newEnergyBillInfo = {
                    ...emptyEnergyBillInfo,
                    ...savedEnergyBillInfo,
                    energyBill: parseInt(energyBillParam),
                    averageBill: parseInt(energyBillParam),
                    valid: true
                };

                saveEnergyBillInfo(newEnergyBillInfo);
                setEnergyBillInfo(newEnergyBillInfo);
                // console.log("@@@@ Setting energyBillInfo:", newEnergyBillInfo);

                newFormState = FormState.Panels;
            } else {
                updatedEnergyBillInfo = savedEnergyBillInfo;
                setEnergyBillInfo(updatedEnergyBillInfo);
            }

            if (panelsParam) {
                setBuyPanels(parseInt(panelsParam));

                newFormState = FormState.Panels;
            }

            if (
                newFormState === FormState.Loading ||
                newFormState === FormState.SignupUnavailable
            ) {
                if (authentication?.user?.status === UserStatus.VERIFIED) {
                    newFormState = FormState.Profile;
                } else if (!isCountryAvailable) {
                    newFormState = FormState.SignupUnavailable;
                } else if (!updatedEnergyBillInfo?.valid) {
                    newFormState = FormState.EnergyBill;
                    // } else if (newFormState !== FormState.EnergyBill) {
                } else {
                    newFormState = FormState.Panels;
                }
            }

            const newOrderPayment: OrderPayment = {
                order: newOrder,
                payment: orderPayment?.payment,
                valid: !!orderPayment?.valid,
            }

            setOrderPayment(newOrderPayment);

            if (newOrder?.status === OrderStatus.PAYMENT_PENDING) {
                newFormState = FormState.Organisation;
            }

            changeFormState(newFormState);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading]
    );

    useEffect(
        () => {
            if (isLoading) {
                return;
            }

            const updateOrder = async () => {
                if (organisation?.uuid) {
                    if (newOrder?.owner?.type !== OwnerType.ORGANISATION || newOrder.owner.organisation?.uuid !== organisation.uuid) {
                        const updatedNewOrder = {
                            ...newOrder,
                            owner: {
                                type: OwnerType.ORGANISATION,
                                organisation: stripValidated(organisation),
                            }
                        };

                        await saveNewOrder(updatedNewOrder);
                    }
                } else {
                    if (newOrder?.owner) {
                        const { owner, ...updatedNewOrder } = newOrder;

                        await saveNewOrder(updatedNewOrder);
                    }
                }
            }

            updateOrder();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading, organisation?.uuid, organisation?.abn, organisation?.name]
    )

    const updateOverrideCountry = (country: CountryInfo | undefined) => {
        if (!country?.available) {
            return;
        }

        overrideCountry(country);

        if (energyBillInfo?.valid) {
            changeFormState(FormState.Panels);
        } else {
            changeFormState(FormState.EnergyBill);
        }
    }

    const editEnergyBill = () => {
        changeFormState(FormState.EnergyBill);
    }

    const clearEnergyBill = () => {
        setEnergyBillInfo(emptyEnergyBillInfo);
    }

    const goToDashboard = () => {
        navigate("/dashboard");
    }

    const goToLogin = () => {
        changeFormState(FormState.Login);
    }

    const goToSignup = () => {
        changeFormState(FormState.Signup);
    }

    const changeFormState = (newState: FormState) => {
        setFormState(newState);

        navigate(`${basePath}/${newState}`);
    }

    const useFormControl = (): FormControl => {
        switch (formState) {
            case FormState.SignupUnavailable:
                return {
                    title: "Signup is not available",
                };

            case FormState.EnergyBill:
                return {
                    title: "What's your energy bill like?",
                    goBack: () => { changeFormState(FormState.Panels); },
                    backTitle: "Cancel",
                    goNext: () => { changeFormState(FormState.Panels); },
                    nextTitle: "Save",
                    nextValid: !!energyBillInfo?.valid,
                };

            case FormState.Panels:
                return {
                    title: "Energy savings coming your way!",
                    goNext: async () => {
                        changeFormState(FormState.Signup);
                    },
                    nextTitle: "Proceed",
                };

            case FormState.Signup:
                return {
                    title: "Take control of your energy now! \nSign up",
                    goBack: () => { changeFormState(FormState.Panels); },
                    backTitle: "Back",
                    goNext: async () => {
                        const createResult = await createLogin(signupCredentials!);

                        switch (createResult.status) {
                            case "Success":
                                infoWithTimeout(3000, "You are now signed up but need to verify your email address");
                                changeFormState(FormState.Verification);
                                break;

                            case "UsernameExists":
                                error("Your email already exists in our system. Try signing using the link.");
                                break;

                            case "Error":
                                errorWithTimeout(3000, `Something unexpected happened: ${createResult.error}`);
                                break;
                        }

                    },
                    nextTitle: "Proceed",
                    nextValid: !!signupCredentials?.valid,
                    forceState: () => {
                        const status = authentication?.user?.status;
                        switch (status) {
                            case UserStatus.ACTIVE:
                            case UserStatus.PENDING:
                            case UserStatus.PROFILED:
                                return FormState.Organisation;

                            case UserStatus.VERIFIED:
                                return FormState.Organisation;

                            case UserStatus.UNVERIFIED:
                                return FormState.Verification;

                            case undefined:
                                return;

                            case UserStatus.CANCELLED:
                            case UserStatus.COMPROMISED:
                            case UserStatus.INACTIVE:
                            default:
                                error("You are logged in but there seems to be something wrong with your account. Please contact the system administrator.");
                                return undefined;
                        }
                    },
                };

            case FormState.Verification:
                return {
                    title: "Verification",
                    goBack: () => { changeFormState(FormState.Organisation); },
                    goNext: async () => {
                        await verifyLogin(loginVerification!);

                        changeFormState(FormState.Login);
                    },
                    backTitle: "Back",
                    nextTitle: "Proceed",
                    nextValid: loginVerification?.valid,
                };

            case FormState.Login:
                return {
                    title: "Login",
                    goNext: async () => {
                        const loginResult = await login(loginCredentials!);

                        switch (loginResult.status) {
                            case MutatorStatus.Success:
                                changeFormState(FormState.Organisation);
                                break;

                            case MutatorStatus.VerificationRequired:
                                changeFormState(FormState.Verification);
                                break;

                            case MutatorStatus.PasswordReset:
                                break;

                            case MutatorStatus.Error:
                                error(loginResult.err);
                                break;
                        }
                    },
                    nextTitle: "Login",
                    nextValid: loginCredentials?.valid,
                };

            case FormState.Organisation:
                // console.log("@@@@ organisation?.valid:", organisation?.valid);
                return {
                    title: "Who are the panels for?",
                    goBack: () => { changeFormState(FormState.Panels); },
                    backTitle: "Back",
                    goNext: async () => { changeFormState(FormState.Payment); },
                    nextTitle: "Proceed",
                    nextValid: !!organisation?.valid,
                };

            case FormState.Payment:
                return {
                    title: paymentFailure ?"Payment error" :"How do you want to pay?",
                    goBack: () => { changeFormState(FormState.Organisation); },
                    backTitle: "Back",
                    goNext: async () => {
                        if (newOrder?.status !== OrderStatus.PAYMENT_PENDING) {
                            await checkoutNewOrder(newOrder!);
                        }

                        const paymentDetails = orderPayment?.payment?.details!;

                        const cleanOrderPayment: OrderPayment = {
                            ...orderPayment!,
                            payment: {
                                type: orderPayment?.payment?.type!,
                                details: {
                                    ...paymentDetails,
                                    number: cleanNumber(paymentDetails.number!),
                                    name: paymentDetails.name,
                                    expiry: cleanNumber(paymentDetails.expiry!),
                                    cvc: cleanNumber(paymentDetails.cvc!)
                                },
                            }
                        };

                        const response = await makeOrderPayment(cleanOrderPayment);

                        if (response.status === "Success") {
                            setPaymentFailure(false)
                            changeFormState(FormState.PaymentAcknowledge);
                        } else {
                            setPaymentFailure(true)
                        }
                    },
                    nextTitle: "Proceed",
                    nextValid: orderPayment?.valid,
                };

            case FormState.PaymentAcknowledge:
                return {
                    title: "Payment successful",
                    goNext: () => {
                        // const userStatus = authentication?.user?.status;
                        // if (
                        //     userStatus === UserStatus.ACTIVE ||
                        //     userStatus === UserStatus.PENDING ||
                        //     userStatus === UserStatus.PROFILED
                        // ) {
                        //     changeFormState(FormState.Complete);
                        // } else {
                            changeFormState(FormState.Profile);
                        // }
                    },
                    nextTitle: "Proceed",
                    // backTitle: "Back",
                    // goBack: () => { changeFormState(FormState.Payment); },

                };

            case FormState.Profile:
                return {
                    title: "Your details",
                    goBack: () => { changeFormState(FormState.PaymentAcknowledge); },
                    backTitle: "Back",
                    goNext: async () => {
                        changeFormState(FormState.Complete);
                    },
                    nextTitle: "Proceed",
                    nextValid: user?.valid,
                };

            case FormState.Complete:
                return {
                    title: "Thankyou",
                    goNext: async () => { goToDashboard(); },
                    nextTitle: "Take me to the dashboard NOW...",
                
                };

            default:
                return { title: "Bad things happen" };
        }
    };

    const formControl = useFormControl();
    const { forceState } = formControl;

    const formStateOverride = forceState?.();
    if (formStateOverride) {
        changeFormState(formStateOverride);
    }

    const breadcrumbs = getBreadcrumbs(formState);

    // console.log(`@@@@ formState:${formState} goBack:${goBack}, backTitle:${backTitle}, backValid:${backValid} goNext:${goNext}, nextTitle:${nextTitle}, nextValid:${nextValid}`);
    // console.log(`@@@@ energyBillInfo:`, energyBillInfo);
    return (
        <Panel
            title={formControl.title}
            isLoading={isLoading || isMutating || formState === FormState.Loading}
            defaultPadding={defaultPadding}
            breadcrumbs={breadcrumbs}
        >
            <Switch case={formState}>
                <SignupUnavailableForm
                    key={FormState.SignupUnavailable}
                    saveContact={createContact}
                    overrideCountry={updateOverrideCountry}
                    formControl={formControl}
                />

                <EnergyBillForm
                    key={FormState.EnergyBill}
                    updateEnergyBillInfo={setEnergyBillInfo}
                    formControl={formControl}
                />

                <BuyPanelsForm
                    key={FormState.Panels}
                    updating={isLoading}
                    readOnly={false}
                    energyBill={energyBillInfo?.valid ? energyBillInfo?.averageBill : undefined}
                    editEnergyBill={editEnergyBill}
                    clearEnergyBill={clearEnergyBill}
                    formControl={formControl}
                    addToBagCallBck={addToBagCallBck}
                />

                <SignupForm
                    key={FormState.Signup}
                    loginAction={goToLogin}
                    updateValue={setSignupCredentials}
                    formControl={formControl}
                />

                <VerificationForm
                    key={FormState.Verification}
                    updateValue={setLoginVerification}
                    formControl={formControl}
                />

                <LoginForm
                    key={FormState.Login}
                    signupAction={goToSignup}
                    updateValue={setLoginCredentials}
                    formControl={formControl}
                />

                <OrganisationForm
                    key={FormState.Organisation}
                    updateOrganisation={setOrganisation}
                    formControl={formControl}
                />

                <PaymentForm
                    key={FormState.Payment}
                    orderPayment={orderPayment}
                    updateOrderPayment={setOrderPayment}
                    formControl={formControl}
                />

                <PaymentAcknowledgeForm
                    key={FormState.PaymentAcknowledge}
                    order={newOrder}
                    formControl={formControl}
                />

                <ProfileForm
                    key={FormState.Profile}
                    updateUser={setUser}
                    proceedIfValid={true}
                    formControl={formControl}
                />

                <BuyPanelsCompleteForm
                    key={FormState.Complete}
                    formControl={formControl}
                    email={user?.emails?.[0]?.address}
                />
            </Switch>            
        </Panel>
    );
}

export default BuyPanelsPanel;
