import { Suspense, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import './App.scss';
import { OrderStatus, Organisation, User, UserStatus } from './api/SolarCloudApi';
import { Message, Messages } from './components/Messages';
import { Profile, ProfileContext } from './contexts/ProfileContext';
import { Environment, Feature, hasFeature, useEnvironmentQuery } from './hooks/Environment';
import { useNewOrderQuery } from './hooks/Order';
import { useQuerySummary } from './hooks/QuerySummary';
import CalculatorPage from './pages/CalculatorPage';
import LoadingPage from './pages/LoadingPage';
import BuyPanelsPanel from './panel/BuyPanelsPanel';
import OrdersPanel from './panel/OrdersPanel';
import { useAuthenticationQuery } from './util/authentication';
import { bemName } from './util/bemName';
import { logger } from './util/logger';

import LoginPage from './pages/LoginPage';
// import VerificationPage from './pages/VerificationPage';
import { Auth } from 'aws-amplify';
import BuyPanelsPage from './pages/BuyPanelsPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ContactPage from './pages/ContactPage';
import DashboardPage from './pages/DashboardPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import AllocationsPanel from './panel/AllocationsPanel';
import ChangePasswordPanel from './panel/ChangePasswordPanel';
import ContactPanel from './panel/ContactPanel';
import DashboardPanel from './panel/DashboardPanel';
import InvoicesPanel from './panel/InvoicesPanel';
import LocationsPanel from './panel/LocationsPanel';
import ProfilePanel from './panel/ProfilePanel';
import TermsAndConditionsPanel from './panel/TermsAndConditionsPanel';
import TrackerPanel from './panel/TrackerPanel';

// const BuyPanelsPage = React.lazy(() => import("./pages/BuyPanelsPage"));
// const ChangePasswordPage = React.lazy(() => import("./pages/ChangePasswordPage"));
// const ContactPage = React.lazy(() => import("./pages/ContactPage"));
// const DashboardPage = React.lazy(() => import("./pages/DashboardPage"));
// const ForgotPasswordPage = React.lazy(() => import("./pages/ForgotPasswordPage"));
// const LoginPage = React.lazy(() => import("./pages/LoginPage"));
// const ResetPasswordPage = React.lazy(() => import("./pages/ResetPasswordPage"));
// const SignupPage = React.lazy(() => import("./pages/SignupPage"));
// const TermsAndConditionsPage = React.lazy(() => import("./pages/TrackerPage"));
// const AllocationsPanel = React.lazy(() => import("./panel/AllocationsPanel"));
// const ChangePasswordPanel = React.lazy(() => import("./panel/ChangePasswordPanel"));
// const ContactPanel = React.lazy(() => import("./panel/ContactPanel"));
// const DashboardPanel = React.lazy(() => import("./panel/DashboardPanel"));
// const InvoicesPanel = React.lazy(() => import("./panel/InvoicesPanel"));
// const LocationsPanel = React.lazy(() => import("./panel/LocationsPanel"));
// const TermsAndConditionsPanel = React.lazy(() => import("./panel/TermsAndConditionsPanel"));
// const TrackerPanel = React.lazy(() => import("./panel/TrackerPanel"));

const match = (user: User | undefined, features: Feature[] | undefined, name: string, needsUser?: boolean, status?: UserStatus | Array<UserStatus>, feature?: Feature | Array<Feature>): boolean => {
  const trace = false;

  if (status) {
    if (!user?.status) {
      trace && logger.log("@@@@ match - no user status: ", "user:", user, "features:", features, "name:", name, "needsUser:", needsUser, "status:", status, "feature:", feature);
      return false;
    } else {
      const statuses = Array.isArray(status) ? status : [status];

      if (!statuses.includes(user.status)) {
        trace && logger.log("@@@@ match - user status not matched: ", "user:", user, "features:", features, "name:", name, "needsUser:", needsUser, "status:", status, "feature:", feature);
        return false;
      }
    }
  }

  if (!hasFeature(features, feature)) {
    trace && logger.log("@@@@ match - no feature: ", "user:", user, "features:", features, "name:", name, "needsUser:", needsUser, "status:", status, "feature:", feature);
    return false;
  }

  if (needsUser === true && !user) {
    trace && logger.log("@@@@ match - user expected: ", "user:", user, "features:", features, "name:", name, "needsUser:", needsUser, "status:", status, "feature:", feature);
    return false;
  }

  if (needsUser === false && user) {
    trace && logger.log("@@@@ match - no user expected: ", "user:", user, "features:", features, "name:", name, "needsUser:", needsUser, "status:", status, "featue:", feature);
    return false;
  }

  trace && logger.log("@@@@ match - matched: ", "user:", user, "features:", features, "name:", name, "needsUser:", needsUser, "status:", status, "featue:", feature);
  return true;
}

type NavigateWithTraceProps = {
  forced?: boolean;
  to: string;
}

const NavigateWithTrace = ({ to }: NavigateWithTraceProps, forced?: boolean) => {
  // logger.log(`@@@@ Navigating to ${to}${forced ? ` forced from ${window.location.pathname}` : ""}`);

  return <Navigate to={`${to}`} />
}

// enum AppState {
//   LoggedOut = "LoggedOut",
//   Signup = "Signup",
//   Verify = "Verify",
//   Profile = "Profile",
//   Buying = "Buying",
//   Payment = "Payment",
//   LoggedIn = "LoggedIn",
// }

type AppWithEnvironmentProps = {
  environment?: Environment;
}

const AppWithEnvironment = ({ environment }: AppWithEnvironmentProps) => {
  const [organisation, setOrganisation] = useState<Organisation | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [country, setCountry] = useState<string | undefined>(undefined);
  const [messages, setMessages] = useState<Message[]>([]);

  const querySummary = useQuerySummary();
  const { data: newOrder } = useNewOrderQuery(querySummary);
  const { data: authentication } = useAuthenticationQuery(querySummary);

  const user = authentication?.user;

  const Logout = () => {
    const navigate = useNavigate();

    try {
      Auth
        .signOut()
        .then(
          () => {
            navigate("/")
          }
        )
    } catch (error) {
      console.log(`Failed to signOut`, error);
    }

    return <></>;
  }

  const profile: Profile = {
    authentication,
    organisation,
    setOrganisation,
    email,
    setEmail,
    country,
    setCountry: setCountry,
    messages,
    setMessages,
    get match() { return (name: string, needsUser?: boolean, status?: UserStatus | Array<UserStatus>, feature?: Feature | Array<Feature>) => match(user, environment?.features, name, needsUser, status, feature); }
  };

  // logger.log("@@@@ location:", window.location);
  // logger.log("`@@@@ profile`:", profile);

  if (!environment || querySummary.get().isLoading) {
    return <LoadingPage />;
  }

  const defaultPath: string = (
    () => {
      if (authentication?.loggedIn) {
        if (
          newOrder?.status === OrderStatus.PAYMENT_PENDING
        ) {
          return "/buypanels/payment";
        } else {
          switch (authentication?.user.status) {
            default:
            case UserStatus.ACTIVE:
            case UserStatus.PENDING:
              return "/dashboard";

            case UserStatus.UNVERIFIED:
            case UserStatus.VERIFIED:
              return "/buypanels";

            case UserStatus.PROFILED:
              return "/buypanels";

            case UserStatus.CANCELLED:
            case UserStatus.COMPROMISED:
            case UserStatus.INACTIVE:
              return "/signup/error";
          }
        }
      } else {
        if (
          newOrder?.status === OrderStatus.PAYMENT_PENDING
        ) {
          return "/buypanels/payment";
        } else {
          return "/login";
        }
      }
    }
  )();

  // logger.log("@@@@", "path:", window.location.pathname, "defaultPath:", defaultPath, "loggedIn:", authentication?.loggedIn, authentication);

  return (
    <div className={bemName("App")}>
      <div className={bemName("App", "container")}>
        <Suspense fallback={<LoadingPage />}>
          {/* <div className="UserInfo">User: {user?.emails ? user.emails[0].address + " - " + user.userid : ""}</div> */}
          <ProfileContext.Provider value={profile}>
            <Router basename="/">
              <Messages />
              <Routes>
                <Route key="11" path="/" element={<NavigateWithTrace to={defaultPath} forced />} />,
                <Route key="30" path="/login" element={<LoginPage />} />
                <Route key="33" path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route key="34" path="/resetpassword" element={<ResetPasswordPage />} />
                <Route key="35" path="/changepassword" element={<ChangePasswordPage />} />
                <Route key="41" path="/buypanels/:state?" element={<BuyPanelsPage />} />
                <Route key="51" path="/dashboard" element={<DashboardPage />}>
                  <Route path="/dashboard" element={<DashboardPanel />} />
                  <Route path="/dashboard/tracker" element={<TrackerPanel />} />
                  <Route path="/dashboard/locations" element={<LocationsPanel />} />
                  <Route path="/dashboard/buypanels/:state?" element={<BuyPanelsPanel addToBagCallBck={()=>null}/>} />
                  <Route path="/dashboard/allocations" element={<AllocationsPanel />} />
                  <Route path="/dashboard/invoices" element={<InvoicesPanel />} />
                  <Route path="/dashboard/orders" element={<OrdersPanel />} />
                  <Route path="/dashboard/termsandconditions" element={<TermsAndConditionsPanel />} />
                  <Route path="/dashboard/profile" element={<ProfilePanel />} />
                  <Route path="/dashboard/changepassword" element={<ChangePasswordPanel />} />
                  <Route path="/dashboard/contact" element={<ContactPanel />} />
                </Route>,
                <Route key="91" path="/terms" element={<TermsAndConditionsPage />} />
                <Route key="92" path="/contact" element={<ContactPage />} />
                <Route key="93" path="/logout" element={<Logout />} />
                <Route key="94" path="/logoff" element={<Logout />} />
                <Route key="95" path="/auth/redirect"  element={<NavigateWithTrace to={defaultPath} forced />} />
                <Route key="95" path="/auth/signout"  element={<NavigateWithTrace to={"/login"} forced />} />
                <Route key="96" path="*" element={<NavigateWithTrace to={`/`} />} />
                <Route key="loading" path="/loading" element={<LoadingPage />} />
                <Route key="calculator" path="/calculator" element={<CalculatorPage />} />
              </Routes>
            </Router>
          </ProfileContext.Provider>
        </Suspense>
      </div>
    </div>
  );
}

export const App = () => {
  const { data: environment } = useEnvironmentQuery();

  return (
    // <React.StrictMode>
    <>
      {
        environment ?
          <AppWithEnvironment environment={environment} />
          :
          <LoadingPage />
      }
    </>
    // </React.StrictMode>
  )
}

export default App;