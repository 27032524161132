import { useEffect, useState } from "react";
import { Contact, ContactType, EmailType, PhoneNumberType, DefaultService as UsersService } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import { Input } from "../components/Input";
import { error, infoWithTimeout } from "../components/Messages";

import { useCountries } from "../hooks/Countries";
import { useQuerySummary } from "../hooks/QuerySummary";
import { useAuthenticationQuery } from "../util/authentication";
import { bemName } from "../util/bemName";
import "./ContactForm.scss";

const componentName = "ContactForm";

interface Props {
    contact? : Contact;
    blockName?: string;
}

export const ContactForm = ({ blockName, ...props }: Props) => {
    const [contact, setContact] = useState<Contact>({});

    const querySummary = useQuerySummary();

    const { data: authentication } = useAuthenticationQuery(querySummary);
    const { defaultCountry } = useCountries({}, querySummary);

    const isLoading = querySummary.get().isLoading;

    const user = authentication?.user;

    useEffect(
        () => {
            if (isLoading) {
                return;
            }
            
            const phoneNumber =  user?.phoneNumbers?.filter(item => item.type === PhoneNumberType.DEFAULT).length !== 0
                ? user?.phoneNumbers?.filter(item => item.type === PhoneNumberType.DEFAULT)[0].number
                : undefined;

            const newContact: Contact = {
                type: ContactType.INQUIRY,
                phoneNumber: phoneNumber,
                email: user?.emails?.filter(item => item.type === EmailType.DEFAULT)[0].address,
                givenName: user?.givenName,
                familyName: user?.familyName,
                country: defaultCountry?.code ?? "AU",
            }

            setContact(newContact);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading]
    );

    const updateContact = (update: Contact) => {
        const newContact = {
            ...contact,
            ...update
        }

        setContact(newContact);
    }

    const submitForm = async (event: any): Promise<void> => {
        try {
            await UsersService.registerContact(contact);
    
            infoWithTimeout(3000, "Thank you for your enquiry. Someone will be in contact soon.");
        } catch (err: any) {
            error(err.body?.message || err.message, "Inquiry failed");
        }
    };

    const block = blockName || componentName;

    const complete = 
        contact.comment && contact.comment !== "" && 
        (
            (contact.email && contact.email !== "") ||
            (contact.phoneNumber && contact.phoneNumber !== "")
        );

    // logger.log("@@@@ contact", contact, complete);

    return (
        <div className={bemName(block, "container")}>
            <div className={bemName(block, "commentContainer")}>
                <label htmlFor="inputBrief" className={bemName(block, "commentLabel")}>What can help you with?</label>
                <textarea placeholder="Brief description about your request" className={bemName(block, "comment")} value={contact.comment} onChange={(e) => updateContact({ comment: e.target.value })} required autoFocus></textarea>
            </div>
            <div className={bemName(block, "phoneContainer")}>
                <Input placeholder="Phone number" value={contact.phoneNumber} onChange={(e) => updateContact({ phoneNumber: e.target.value })}/>
            </div>
            <div className={bemName(block, "emailContainer")}>
                <Input placeholder="Email" value={contact.email} onChange={(e) => updateContact({ email: e.target.value })} />
            </div>
            <div className={bemName(block, "buttonContainer")}>
                <Button text="Request information" disabled={!complete} onClick={submitForm} />
            </div>
        </div>
    );
}
