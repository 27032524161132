export const logger = {
    log: 
        (message: any, ...parameters: any[]) => {
            console.log(message, parameters);
        },

    warn:
        (message: any, ...parameters: any[]) => {
            console.warn(message, parameters);
        },

    error:
        (message: any, ...parameters: any[]) => {
            console.error(message, parameters);
        },
}