import { useState } from "react";
import { Footer } from "../components/Footer";
import { Page } from "../components/Page";
import BuyPanelsPanel from "../panel/BuyPanelsPanel";
import "./BuyPanelsPage.scss";


type BuyPanelsPageProps = {
    addToBagCount?:number | undefined
  };

export const BuyPanelsPage = (props: BuyPanelsPageProps) => {
    // const navigate = useNavigate();
    // const { data: authentication } = useAuthenticationQuery();
    // const profile = authentication?.user;
    const[addToBagValue,setAddToBag]=useState<number|undefined>(0)

    // useEffect(
    //     () => {
    //         // logger.log("@@@@ user status", profile?.status);

    //         if (profile?.status == UserStatus.ACTIVE || profile?.status === UserStatus.PENDING) {
    //             navigate("/dashboard/buypanels");
    //         }
    //     },
    //     [ profile?.status ]
    // )

    return (
        <>
            <Page isFromBuyPanelPage={true} requiredFeature={"BuyPanels"} addToBagCount={addToBagValue}>
                <BuyPanelsPanel defaultPadding={false} addToBagCallBck={setAddToBag} />
                <div className="footerWrapper">
                <Footer />
                </div>
            </Page>
        </>
    );
}

export default BuyPanelsPage;
