import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { DefaultService } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import { Input } from "../components/Input";
import { infoWithTimeout } from "../components/Messages";
import { invalidateQueryDependencies } from "../hooks/Dependencies";
import { errorHandler } from "../hooks/Error";
import { useLoginEmailMutation, useLoginEmailQuery } from "../hooks/LocalStorage";
import { QuerySummary, useQuerySummary } from "../hooks/QuerySummary";
import { useUuid } from "../hooks/Utils";
import { bemName } from "../util/bemName";
import { FormProps } from "./Form";
import "./VerificationForm.scss";
import { NavigationButtons, FormControl } from "../panel/BuyPanelsPanel";
import GotQuestion from "../components/GotQuestion";
import { PaymentTypes } from "./PaymentTypes";
import { TestimonialForm } from "./TestimonialForm";
import EnjoyFlexibility from "../components/EnjoyFlexibility";
import ReferAFriend from "../components/ReferAFriend";

const componentName = "VerificationForm";

export type LoginVerification = {
    email?: string;
    code?: string;
    valid: boolean;
};

type VerificationFormProps = Omit<FormProps<LoginVerification>, "value"> & {
    formControl: FormControl;
};

export const useVerificationCodeMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const mutation = useMutation(
        async ({ email, code }: LoginVerification) => {
            const response = await DefaultService.verificationCodeComplete({ email, code });

            if (response.code === "ALREADY_VERIFIED") {
                infoWithTimeout(3000, "User email was already verified");
            } else {
                infoWithTimeout(3000, "User verification complete");
            }
        },
        {
            onSuccess: (data, variables, context) => {
                invalidateQueryDependencies("verficiationCode");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "verficiationCode", mutation);

    return mutation;
}

const useVerificationFormController = (props: VerificationFormProps) => {
    const { updateValue, formControl } = props;

    const querySummary = useQuerySummary();
    const { data: loginEmail } = useLoginEmailQuery(querySummary);
    const { mutateAsync: setLoginEmail } = useLoginEmailMutation(querySummary);
    const [verificationCode, setVerificationCode] = useState("");

    useEffect(
        () => {
            const loginVerification: LoginVerification = {
                email: loginEmail,
                code: verificationCode,
                valid: !!loginEmail && loginEmail.length >= 3 && !!verificationCode && verificationCode.length === 6,
            }

            updateValue?.(loginVerification, loginVerification.valid);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loginEmail, verificationCode]
    );

    const resendValid = !!loginEmail && loginEmail.length > 5;

    const resend = async () => {
        const response = await DefaultService.verificationCode(loginEmail);

        if (response.code === "ALREADY_VERIFIED") {
            infoWithTimeout(3000, "User email was already verified");
        } else {
            infoWithTimeout(3000, "Verification code resent to your email");
        }
    }

    return {
        loginEmail,
        setLoginEmail,
        verificationCode,
        setVerificationCode,
        resend: resendValid ? resend : undefined,
        formControl,
    };
}

export const VerificationForm = (props: VerificationFormProps) => {
    const {
        loginEmail,
        setLoginEmail,
        verificationCode,
        setVerificationCode,
        resend,
        formControl,
    } = useVerificationFormController(props);
    
    return (
        <div className={bemName(componentName, "container")}>
            <div className={bemName(componentName, "text")}>
                <p>Yes we know this can be annoying, seems like you need a verification code to buy toilet paper these days...... but for your safety please check your inbox for the code we just sent you and please enter it into the box below and press Proceed</p>
            </div>
            <Input type="email" placeholder="Email" value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} />
            <Input type="text" placeholder="Verification Code" value={verificationCode} fluid icon="address card" iconPosition="left" autoComplete="one-time-code" onChange={(e) => setVerificationCode(e.target.value)} />
            <div className={bemName(componentName, "links")}>
                <Button disabled={!resend} asLink text="Resend verification code" onClick={resend} />
            </div>
            <NavigationButtons formControl={formControl} />
            <EnjoyFlexibility/>
            <GotQuestion />
            <PaymentTypes />
            <TestimonialForm />
            <ReferAFriend/>
        </div>
    );
}
