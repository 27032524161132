import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  AddressType,
  PhoneNumberType,
  User,
  UserAddress,
  UserPhoneNumber,
} from "../api/SolarCloudApi";
import { Address } from "../components/Address";
import { AddressSearch } from "../components/AddressSearch";
import { Input } from "../components/Input";
import { bemName } from "../util/bemName";
import { FormProps } from "./Form";

import GotQuestion from "../components/GotQuestion";
import ReferAFriend from "../components/ReferAFriend";
import { useQuerySummary } from "../hooks/QuerySummary";
import { useUserMutation } from "../hooks/User";
import { FormControl, NavigationButtons } from "../panel/BuyPanelsPanel";
import { useAuthenticationQuery } from "../util/authentication";
import "./ProfileForm.scss";

const componentName = "ProfileForm";

function findDefaultPhoneNumber(
  user: Partial<User> | undefined
): UserPhoneNumber | undefined {
  if (user === undefined) {
    return undefined;
  }

  var defaultPhoneNumber: UserPhoneNumber | undefined;

  if (user.phoneNumbers) {
    for (const phoneNumber of user.phoneNumbers) {
      if (!defaultPhoneNumber) {
        defaultPhoneNumber = phoneNumber;
      }

      switch (phoneNumber.type) {
        case PhoneNumberType.MOBILE:
          defaultPhoneNumber = phoneNumber;
          break;

        case PhoneNumberType.DEFAULT:
          if (
            defaultPhoneNumber.type !== PhoneNumberType.HOME &&
            defaultPhoneNumber.type !== PhoneNumberType.BUSINESS
          ) {
            defaultPhoneNumber = phoneNumber;
          }
          break;

        case PhoneNumberType.BUSINESS:
          if (defaultPhoneNumber.type !== PhoneNumberType.HOME) {
            defaultPhoneNumber = phoneNumber;
          }
          break;

        default:
          break;
      }
    }
  }

  return defaultPhoneNumber ? defaultPhoneNumber : {};
}

const validateUser = (user: User | undefined): boolean => {
  return !!user?.givenName && !!user?.familyName;
};

const validatePhoneNumber = (
  phoneNumber: UserPhoneNumber | undefined
): boolean => {
  return !!phoneNumber && !!phoneNumber.number;
};

const validateAddress = (address: UserAddress | undefined): boolean => {
  return (
    !!address &&
    !!address.line1 &&
    !!address.city &&
    !!address.state &&
    !!address.postcode &&
    !!address.country
  );
};

function updatePhoneNumbers(
  user: Partial<User> | undefined,
  phoneNumber: UserPhoneNumber | undefined
): UserPhoneNumber[] | undefined {
  if (!phoneNumber || !validatePhoneNumber(phoneNumber)) {
    return user?.phoneNumbers;
  }

  if (!user) {
    return [phoneNumber];
  }

  if (phoneNumber.uuid) {
    for (var index = 0; index < (user.phoneNumbers?.length ?? 0); ++index) {
      if (user.phoneNumbers?.[index].uuid === phoneNumber.uuid) {
        const phoneNumbers = [...user.phoneNumbers];
        phoneNumbers[index] = phoneNumber;
        return phoneNumbers;
      }
    }
  }

  return [...(user.phoneNumbers ?? []), phoneNumber];
}

function findDefaultAddress(
  user: Partial<User> | undefined
): UserAddress | undefined {
  if (user === undefined) {
    return undefined;
  }

  var defaultAddress: UserAddress | undefined;

  if (user.addresses) {
    for (const address of user.addresses) {
      if (!defaultAddress) {
        defaultAddress = address;
      }

      switch (address.type) {
        case AddressType.HOME:
          defaultAddress = address;
          break;

        case AddressType.BUSINESS:
          if (defaultAddress.type !== AddressType.HOME) {
            defaultAddress = address;
          }
          break;

        case AddressType.POSTAL:
          if (
            defaultAddress.type !== AddressType.HOME &&
            defaultAddress.type !== AddressType.BUSINESS
          ) {
            defaultAddress = address;
          }
          break;

        default:
          break;
      }
    }
  }

  return defaultAddress ? defaultAddress : {};
}

function updateAddresses(
  user: Partial<User> | undefined,
  address: UserAddress | undefined
): UserAddress[] | undefined {
  if (!address || !validateAddress(address)) {
    return user?.addresses;
  }

  if (!user) {
    return [address];
  }

  if (address.uuid) {
    for (var index = 0; index < (user.addresses?.length ?? 0); ++index) {
      if (user.addresses?.[index].uuid === address.uuid) {
        const addresses = [...user.addresses];
        addresses[index] = address;
        return addresses;
      }
    }
  } else {
    address.uuid = uuid();
  }

  return [...(user.addresses ?? []), address];
}

export type ProfileUser =
  | (User & { valid: true })
  | (Partial<User> & { valid?: false });

type ProfileFormProps = Omit<
  FormProps<ProfileUser, "user", "updateUser">,
  "user"
> & {
  proceedIfValid: boolean;
  formControl: FormControl;
};

const useProfileFormController = (props: ProfileFormProps) => {
  const { updateUser, proceedIfValid, formControl } = props;

  const [user, setUser] = useState<ProfileUser>();
  const [phoneNumber, setPhoneNumber] = useState<UserPhoneNumber>();
  const [address, setAddress] = useState<UserAddress>();
  const [modified, setModified] = useState<boolean>(false);

  const querySummary = useQuerySummary();

  const { data: authentication } = useAuthenticationQuery(querySummary);
  const { mutateAsync: saveUser } = useUserMutation(querySummary);

  const profileUser = authentication?.user;

  const isLoading = querySummary.get().isLoading;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (user === undefined) {
      if (profileUser !== undefined) {
        setUser(profileUser);
        setPhoneNumber(findDefaultPhoneNumber(profileUser));
        setAddress(findDefaultAddress(profileUser));
      } else {
        setUser({});
      }

      return;
    }

    const valid =
      validateUser(user) &&
      validatePhoneNumber(phoneNumber) &&
      validateAddress(address);

    const newUser = {
      ...user,
      phoneNumbers: updatePhoneNumbers(user, phoneNumber),
      addresses: updateAddresses(user, address),
      valid: valid,
    };

    // console.log("@@@@ user valid:", valid, user);

    setUser(newUser);

    updateUser?.(
      {
        ...newUser,
        valid: newUser!.valid,
      },
      newUser.valid
    );
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [isLoading, user?.givenName, user?.familyName, phoneNumber, address]);

  const updateUserProperty = (properties: Partial<User>) => {
    if (isLoading) {
      return;
    }

    const newProfileUser = { ...user, ...properties, valid: undefined };

    setUser(newProfileUser);

    setModified(true);
  };

  const updatePhoneNumber = (phoneNumber?: UserPhoneNumber) => {
    if (isLoading) {
      return;
    }
    
    setPhoneNumber(
      phoneNumber && phoneNumber.uuid
        ? phoneNumber
        : { uuid: uuid(), type: PhoneNumberType.DEFAULT, ...phoneNumber }
    );
    setModified(true);
  }

  const updateAddress = (address?: UserAddress) => {
    if (isLoading) {
      return;
    }
    
    // console.log("@@@@ updating address:", address);

    setAddress(
      address && address.uuid
        ? address
        : { uuid: uuid(), type: AddressType.POSTAL, ...address }
    );

    setModified(true);
  }

  const saveProfile = async () => {
    await saveUser(user!);

    setModified(false);
  };

  return {
    user,
    updateUserProperty,
    phoneNumber,
    setPhoneNumber: updatePhoneNumber,
    address,
    setAddress: updateAddress,
    modified,
    saveProfile,
    proceedIfValid,
    formControl,
  };
};

const AddressBox = ({ user, address }: {
  user?: ProfileUser,
  address?: UserAddress
}) => {
  return (
    <div className={bemName(componentName, "address_box")}>
      <div>
        {user?.givenName} {user?.familyName}
      </div>
      <div>{address?.line1}</div>
      <div>{address?.line2}</div>
      <div>{address?.city} {address?.state} {address?.postcode}</div>
      <div>{address?.country}</div>
    </div>
  );
};

export const ProfileForm = (props: ProfileFormProps) => {
  const {
    user,
    updateUserProperty,
    phoneNumber,
    setPhoneNumber,
    address,
    setAddress,
    modified,
    saveProfile,
    proceedIfValid,
    formControl,
  } = useProfileFormController(props);

  // console.log("@@@@ profileForm modified", modified);
 
  return (
    <div className={bemName(componentName, "container")}>
    <div className={bemName(componentName, "textSection")}>
      <p className={bemName(componentName, "text")}>
        Thank you for becoming a customer but for obvious reasons we need to
        know who owns these panels.
      </p>
      <p className={bemName(componentName, "text", "subText")}>
        The below information will appear on the receipt. As many of our
        customers buy these as a gift the owners details (who you are gifting
        them to will need to be entered below)
      </p>
    </div>
    <div className={bemName(componentName, "nameLabel")}>First name(s)*</div>
    <div className={bemName(componentName, "names")}>
      <div className={bemName(componentName, "givenName")}>
        <Input
          placeholder="Given name"
          value={user?.givenName}
          autoComplete="given-name"
          onChange={(event) =>
            updateUserProperty({ givenName: event.target.value as string })
          }
        />
      </div>
      <div className={bemName(componentName, "nameLabel")}>Surname*</div>

      <div className={bemName(componentName, "familyName")}>
        <Input
          placeholder="Family name"
          value={user?.familyName}
          autoComplete="family-name"
          onChange={(event) =>
            updateUserProperty({ familyName: event.target.value as string })
          }
        />
      </div>
    </div>
    <div className={bemName(componentName, "phoneLabel")}>
      Mobile number:*
    </div>
    <div className={bemName(componentName, "phone")}>
      <Input
        placeholder="Mobile"
        value={phoneNumber?.number}
        autoComplete="tel"
        onChange={(event) =>
          setPhoneNumber({
            ...phoneNumber,
            number: event.target.value as string,
          })
        }
      />
    </div>
    <div className={bemName(componentName, "note")}>
      Note: you can edit all the above details in the dashboard after
      purchase.{" "}
      <span>
        But please be aware this is the information that will appear in your
        receipt.
      </span>
    </div>
    <div className={bemName(componentName, "note")}>
      Auto address search
      <br /> Use google to search for your address and it will input into the
      address fields below
    </div>
    <div className={bemName(componentName, "address ")}>
      <div className={bemName(componentName, "addressSearch")}>
        <AddressSearch
          placeholder="Search for address"
          address={address}
          setAddress={setAddress}
          options={{ onlyAvailable: true, allowOverride: true }}
        />
      </div>
      <div className={bemName(componentName, "or")}>
        <div>OR</div>
        <div>Input your address manually below.</div>
      </div>
      <div className={bemName(componentName, "addressManual")}>
        <Address address={address!} updateAddress={setAddress} />
      </div>
    </div>
    <AddressBox user={user} address={address} />
    <div className={bemName(componentName, "navButton")}>
      <NavigationButtons
        formControl={{ ...formControl, nextValid: (proceedIfValid || modified) && user?.valid }}
        preNext={saveProfile}
      />
    </div>
    <GotQuestion />
    <ReferAFriend />
  </div>
  );
};
