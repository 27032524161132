import { ChangeEvent, DetailedHTMLProps, SelectHTMLAttributes } from "react";

import "./Select.scss";

function defaultKeyOf<T>(value: T): string {
    if (value === undefined || value === null) {
        return "";
    }

    switch (typeof (value)) {
        case "undefined":
            return "";

        case "boolean":
            return value ? "true" : "false";

        case "string":
            return value;

        case "bigint":
        case "function":
        case "number":
        case "symbol":
            return value.toString();

        default:
            return "(unknown)";
    }
}

function defaultTextOf<T>(value: T): string {
    if (value === undefined || value === null) {
        return "";
    }

    switch (typeof (value)) {
        case "undefined":
            return "";

        case "boolean":
            return value ? "true" : "false";

        case "string":
            return value;

        case "bigint":
        case "function":
        case "number":
        case "symbol":
            return value.toString();

        default:
            return "(unknown)";
    }
}

type KeyOfFunction<T> = (value: T) => string;
type TextOfFunction<T> = (value: T) => string;

const componentName = "Select";

interface Props<T = string> extends DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
    values?: T[];
    keyOf?: KeyOfFunction<T>;
    textOf?: TextOfFunction<T>;
    selection?: T;
    onSelection?: (value: T | undefined) => void;
}

export const Select = <T = string>({ values, keyOf, textOf, selection, onSelection, children, ...props }: Props<T>) => {
    const updateSelection = (event: ChangeEvent<HTMLSelectElement>) => {
        const key = event.target.value;

        if (values) {
            for (const value of values) {
                if (keyOfFunction(value) === key) {
                    onSelection && onSelection(value);
                    return;
                }
            }
            onSelection && onSelection(undefined);
        } else {
            onSelection && onSelection(key as T);
        }
    };

    const keyOfFunction = keyOf ?? defaultKeyOf;
    const textOfFunction = textOf ?? defaultTextOf;

    const selectedKey = selection && keyOfFunction(selection);

    const options = values?.map(
        (value, index) => {
            const key = keyOfFunction(value);
            const text = textOfFunction(value);
            return <option key={key} value={key}>{text}</option>;
        }
    );

    props.placeholder && options?.unshift(<option key={""}>{props.placeholder}</option>)

    return (
        <select {...props} className={props.className ? `${componentName} ${props.className}` : componentName} value={selectedKey} onChange={updateSelection}>
            {options}
        </select>
    );
}