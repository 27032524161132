import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import ReactDOM from "react-dom";
import { bemName } from "../util/bemName";

import "./Modal.scss"
import { Panel } from "./Panel";

const componentName = "Modal";

const modalRoot = document.querySelector("#root") as HTMLElement;

interface Props {
    blockName?: string;
    open: boolean;
    title?: string;
    onClose?: () => Promise<void> | void;
    children?: React.ReactNode | undefined;
}

export const Modal: React.FunctionComponent<Props> = ({ blockName, open, title, onClose, children }) => {
    const elementRef = useRef(document.createElement("div"));

    useEffect(
        () => {
            const current = elementRef.current;

            modalRoot!.appendChild(current);

            return () => { modalRoot!.removeChild(current) };
        },
        []
    )

    if (!open) {
        return null;
    }

    const block = blockName || componentName;

    return (
        ReactDOM.createPortal(
            <div className={bemName(block)}>
                <div className={bemName(block, "shade")}>
                    <Panel title={title}>
                        {children}
                    </Panel>
                </div>
            </div>,
            elementRef.current
        )
    );
}