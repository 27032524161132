import { Panel } from "../components/Panel";
import { SolarCloudIcon } from "../data/SolarCloudIcon";
import { bemName } from "../util/bemName";

import "./LoadingPanel.scss";

const componentName = "LoadingPanel"

export const LoadingPanel = () => {
    return (
        <Panel>
            <div className={bemName(componentName, "spinnerContainer")}>
                <div className={bemName(componentName, "spinner")}>
                    {SolarCloudIcon.Spinner}
                </div>
            </div>
        </Panel>
    );
}

export default LoadingPanel;
