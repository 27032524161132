import FooterIcon from "../res/icons/footer1.png";
import FooterIconBottom from "../res/icons/footer2.png";
import { bemName } from "../util/bemName";

import "./Footer.scss";

const componentName = "Footer";

export const Footer = () => {
    return (
        <div className={bemName(componentName)}>
            {/* <div className={bemName(componentName, "copyright")}>
                ** 104 panels is enough to zero the average Australian home energy bill based Canstarblue research December 2022
            </div>
            <div className={bemName(componentName, "flexWrapper")}>
                <div>
                    <img src={Logo} className={bemName(componentName, "solarCloud")} alt="" />
                    <div className={bemName(componentName, 'iconWrapper')}>
                        <img src={Fb} alt="" />
                        <img src={Twitter} alt="" />
                        <img src={Insta} alt="" />
                    </div>
                </div>
                <div>
                    <div>Your guarantee</div>
                    <div>Your money</div>

                </div>
                <div>
                    <div>Indicative return</div>
                    <div>Replacement Product <br /> Disclosure Statement</div>

                </div>
                <div>
                    <div>SolarCloud Constituion</div>
                    <div>Management agreement</div>
                </div>
            </div> */}
            <div className={bemName(componentName, "footernoteWrapper")}>
                <div className={bemName(componentName, "footnote")}>
                    <p>This is an indicative return only, and not a guaranteed or minimum return. The return to a panel investor in the SolarCloud scheme is from a pool of solar panels owned by multiple investors, which are likely to be located on multiple sites. The return available from each site is affected by weather, climate, orientation, power sale price achieved and the other risks described in the Product Disclosure Statement (PDS). The rate of return to an investor is not set to any one site – it is intended that the returns from each site will be pooled to minimise an investor’s exposure to any one site. The Internal Rate of Return (IRR) calculated here is based on a 25 year term, 29.76 kW system purchased at $1.82 per Watt, with system output of 134 kWh per day (based on Helioscope yield calculations for Jinko 320 watt panels with a Fronius inverter in Mudgee NSW, oriented north with 30 degrees tilt), 100% of the system output sold at a base price 11.2c/kWh with price escalation of 2% per annum, 95% availability of system, panel degradation of 0.6% per annum, and with management, operation and maintenance costs of 20% gross revenue. The actual characteristics, costs and returns of each site will differ from this, but SolarCloud believes this is a reasonable indicative forecast of the potential returns from the pool based on currently available information.</p>


                    <p>SolarCloud operates an Australian Securities and Investment Commission (ASIC) Registered Scheme (ARSN: 612098110) allowing you to invest in solar panels for a financial return. SolarCloud is a member of the Australian Solar Council and all our installers are Clean Energy Council Members. All investments must be made on the form of Application form included in the Replacement Product Disclosure Statement, a copy of which is available here. All prices shown are in AUD (Australian Dollars) unless stated otherwise.</p>

                </div>
                <div>
                    <img src={FooterIcon} className={bemName(componentName, "imgFooter")} alt="" />
                    <img src={FooterIconBottom} className={bemName(componentName, "img")} alt="" />
                </div>
            </div>
        </div>
    );
}