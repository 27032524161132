import { Panel } from "../components/Panel"
import { AllocationsForm } from "../forms/AllocationsForm";

import "./AllocationsPanel.scss";

interface Props {
}

export const AllocationsPanel: React.FunctionComponent<Props> = () => {
    return (
        <Panel title="Payouts / Allocations">
            <AllocationsForm />
        </Panel>
    )
}

export default AllocationsPanel;
