import { useEffect, useState } from "react";
import { AddressType, EmailType, Organisation, OrganisationAddress, OrganisationEmail, OrganisationType, ReferenceData } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import EnjoyFlexibility from "../components/EnjoyFlexibility";
import GotQuestion from "../components/GotQuestion";
import { Input } from "../components/Input";
import ReferAFriend from "../components/ReferAFriend";
import { Select } from "../components/Select";
import { errorHandler } from "../hooks/Error";
import { useQuerySummary } from "../hooks/QuerySummary";
import { useOrganisationTypesQuery } from "../hooks/Reference";
import { useCreateOrganisationMutation } from "../hooks/User";
import { FormControl, NavigationButtons } from "../panel/BuyPanelsPanel";
import { useAuthenticationQuery } from "../util/authentication";
import { bemName } from "../util/bemName";
import { Validated } from "../util/objects";
import { FormProps } from "./Form";
import "./OrganisationForm.scss";
import { PaymentTypes } from "./PaymentTypes";
import { TestimonialForm } from "./TestimonialForm";

const findEmail = (organisation?: Organisation) => {
    return organisation?.emails?.filter(email => email.type === EmailType.DEFAULT)?.[0];
}

const updateOrganisationEmail = (organisation: Organisation | undefined, email?: OrganisationEmail) => {
    const updatedEmails = organisation?.emails ? [...organisation.emails] : [];

    var index = updatedEmails.findIndex(existingEmail => existingEmail.uuid === email?.uuid || existingEmail.type === email?.type);

    if (index !== -1) {
        if (email) {
            updatedEmails[index] = email;
        } else {
            updatedEmails.splice(index, 1);
        }
    } else if (email) {
        updatedEmails.push(email);
    }

    return {
        ...organisation,
        emails: updatedEmails,
    }
}

const findAddress = (organisation?: Organisation) => {
    return organisation?.addresses?.filter(address => address.type === AddressType.BUSINESS)?.[0];
}

const updateOrganisationAddress = (organisation: Organisation | undefined, address: OrganisationAddress | undefined) => {
    const updatedAddresses = organisation?.addresses ? [...organisation.addresses] : [];

    var index = updatedAddresses.findIndex(existingAddress => existingAddress.uuid === address?.uuid || existingAddress.type === address?.type);

    if (index !== -1) {
        if (address) {
            updatedAddresses[index] = address;
        } else {
            updatedAddresses.splice(index, 1);
        }
    } else if (address) {
        updatedAddresses.push(address);
    }

    return {
        ...organisation,
        addresses: updatedAddresses,
    }
}

const componentName = "OrganisationForm";

export type ValidatedOrganisation = Validated<Partial<Organisation>>;

type OrganisationFormProps = Omit<FormProps<ValidatedOrganisation, "organisation", "updateOrganisation">, "organisation"> & {
    formControl: FormControl;
};

const useOrganisationForm = (props: OrganisationFormProps) => {
    const {
        updateOrganisation,
        formControl,
    } = props;

    const [forOrganisation, setForOrganisation] = useState<boolean>();
    const [organisation, setOrganisation] = useState<ValidatedOrganisation>();
    const [organisationTypeData, setOrganisationTypeData] = useState<ReferenceData>();

    const querySummary = useQuerySummary();

    const { data: allOrganisationTypeData } = useOrganisationTypesQuery(querySummary);
    const { data: authorisation } = useAuthenticationQuery(querySummary);
    const { mutateAsync: createOrganisation } = useCreateOrganisationMutation(querySummary);

    const isLoading = querySummary.get().isLoading;

    const user = authorisation?.user;

    const existingOrganisations = user?.organisations;

    useEffect(
        () => {
            if (isLoading) {
                return;
            }

            if (forOrganisation === false) {
                updateOrganisation?.({ valid: true }, true);
            } else if (forOrganisation === true) {
                const valid = validateOrganisation(organisation);
                updateOrganisation?.({ ...organisation, valid }, valid);
            } else {
                updateOrganisation?.({ valid: false }, false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading, forOrganisation, organisation]
    )

    const validateText = (value?: string, minLength?: number): boolean => {
        if (!value) {
            return false;
        }

        if (minLength) {
            if (value.length < minLength) {
                return false;
            }
        }

        return true;
    }

    const validateOrganisation = (organisation?: Organisation) => {
        if (!organisation) {
            return false;
        }

        var valid = !!organisationTypeData;

        const organisationNameRequired: boolean = !!organisationTypeData?.flags?.nameRequired;
        const organisationAbnRequired: boolean = !!organisationTypeData?.flags?.abnRequired;

        if (organisationNameRequired && !validateText(organisation?.name, 3)) {
            valid = false;
        }

        if (organisationAbnRequired && !validateText(organisation?.abn, 3)) {
            valid = false;
        }

        return valid;
    }

    const updateOrganisationType = (organisationTypeData: ReferenceData | undefined) => {
        setOrganisationTypeData(organisationTypeData);

        updateOrganisationProperties({ type: organisationTypeData?.code as OrganisationType });
    };

    const updateOrganisationProperties = (organisationUpdate: Partial<Organisation>) => {
        const newOrganisation = { ...organisation, ...organisationUpdate };

        setOrganisation(newOrganisation);
    };

    const selectOrganisation = (organisation: Organisation | undefined) => {
        setOrganisation({ ...organisation, valid: !!organisation });

        const organisationTypeData = allOrganisationTypeData?.find(typeData => typeData.code === organisation?.type);

        setOrganisationTypeData(organisationTypeData);
    }

    const saveOrganisation = async () => {
        try {
            if (forOrganisation) {
                if (organisation?.uuid) {
                    await updateOrganisation?.(organisation, true);
                } else {
                    const updatedOrganisation = await createOrganisation(organisation!);

                    await updateOrganisation?.(updatedOrganisation, true);
                }
            } else {
                await updateOrganisation?.({ valid: true }, true);
            }
        } catch (error) {
            errorHandler(error);
        }
    }

    const updateEmail = (email?: OrganisationEmail) => {
        const updatedOrganisation = updateOrganisationEmail(organisation, email);

        setOrganisation(updatedOrganisation);
    }

    const updateAddress = (address?: OrganisationAddress, valid?: boolean) => {
        const updatedOrganisation = updateOrganisationAddress(organisation, { ...address, type: AddressType.BUSINESS });

        setOrganisation(updatedOrganisation);
    }

    const selectableOrganisationTypeData = allOrganisationTypeData?.filter(type => type.code !== OrganisationType.INDIVIDUAL);

    return {
        forOrganisation,
        setForOrganisation,
        existingOrganisations,
        selectOrganisation,
        selectableOrganisationTypeData,
        organisationTypeData,
        updateOrganisationType,
        organisation,
        updateOrganisationProperties,
        email: findEmail(organisation),
        setEmail: updateEmail,
        address: findAddress(organisation),
        setAddress: updateAddress,
        saveOrganisation,
        formControl
    }
}

export const OrganisationForm = (props: OrganisationFormProps) => {
    const {
        forOrganisation,
        setForOrganisation,
        existingOrganisations,
        selectOrganisation,
        selectableOrganisationTypeData,
        organisationTypeData,
        updateOrganisationType,
        organisation,
        updateOrganisationProperties,
        saveOrganisation,
        formControl
    } = useOrganisationForm(props);

    return (
        <div className={bemName(componentName, "container")}>
            <div className={bemName(componentName, "who")}>
                <Button text="Private use" selectable selected={forOrganisation === false} onClick={() => setForOrganisation(false)} />
                <Button text="Company or organisation" selectable selected={forOrganisation === true} onClick={() => setForOrganisation(true)} />
            </div>
            {
                forOrganisation === true &&
                existingOrganisations?.length !== 0 &&
                <div className={bemName(componentName, "existing")}>
                    <p>Select an existing company or organisation:</p>
                    <Select<Organisation>
                        // placeholder="(New)"
                        values={existingOrganisations}
                        keyOf={organisation => organisation.uuid!}
                        textOf={organisation => organisation.name!}
                        onSelection={selectOrganisation}
                    />
                </div>
            }
            {
                forOrganisation &&
                organisation?.uuid === undefined &&
                <div className={bemName(componentName, "details")}>
                    <p>Add a company or organisation</p>
                    <div className={bemName(componentName, "type")}>
                        <Select<ReferenceData>
                            // placeholder="Select type..."
                            values={selectableOrganisationTypeData}
                            selection={organisationTypeData}
                            keyOf={reference => reference?.code ?? ""}
                            textOf={reference => reference?.name ?? ""}
                            onSelection={selection => updateOrganisationType(selection)}
                        />
                    </div>
                    {
                        (organisationTypeData?.flags?.nameRequired) &&
                        <div className={bemName(componentName, "abn")}>
                            <Input
                                placeholder="ABN"
                                value={organisation?.abn}
                                autoComplete="off"
                                onChange={event => updateOrganisationProperties({ abn: event.target.value as string })}
                            />
                        </div>
                    }
                    {
                        (organisationTypeData?.flags?.abnRequired) &&
                        <div className={bemName(componentName, "name")}>
                            <Input
                                placeholder="Name"
                                value={organisation?.name}
                                autoComplete="off"
                                onChange={event => updateOrganisationProperties({ name: event.target.value as string })}
                            />
                        </div>
                    }
                    {/* {
                        organisationTypeData &&
                        <>
                            <div className={bemName(componentName, "emailLabel")}>Company/organisaiton email</div>
                            <div className={bemName(componentName, "email")}>
                                <Input
                                    placeholder="Email"
                                    type="email"
                                    value={email?.address}
                                    autoComplete="email"
                                    onChange={event => setEmail({ type: EmailType.DEFAULT, address: event.target.value as string })}
                                />
                            </div>
                            <div className={bemName(componentName, "addressLabel")}>Address</div>
                            <div className={bemName(componentName, "address")}>
                                <div className={bemName(componentName, "addressSearch")}>
                                    <AddressSearch placeholder="Search for address" address={address} setAddress={setAddress} options={{ onlyAvailable: true, allowOverride: true }} />
                                </div>
                                <div className={bemName(componentName, "addressManual")}>
                                    <Address address={address!} updateAddress={setAddress} />
                                </div>
                            </div>
                        </>
                    } */}
                </div>
            }
            <NavigationButtons formControl={formControl} preNext={saveOrganisation} />
            <EnjoyFlexibility/>
            <GotQuestion />
            <PaymentTypes />
            <TestimonialForm />
            <ReferAFriend/>

        </div>
    );
}