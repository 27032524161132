import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { UserStatus } from '../api/SolarCloudApi';
import { Footer } from '../components/Footer';
import { Sidebar, SidebarEntry, sidebarEntry, sidebarSpacer } from '../components/Sidebar';
import { EnablementProps } from '../components/SidebarItem';
import ProfileContext from '../contexts/ProfileContext';
import { SolarCloudIcon } from '../data/SolarCloudIcon';
import { useEnvironmentQuery } from '../hooks/Environment';
import { useAuthenticationQuery } from '../util/authentication';
import { bemName } from '../util/bemName';
import "./Dashboard.scss";
// import { Avatar } from '../components/Avatar';

const sidebarEntries: SidebarEntry[] = [
    sidebarEntry(SolarCloudIcon.Dashboard, "Dashboard", "/dashboard"),
    sidebarEntry(SolarCloudIcon.Tracker, "Tracker", "/dashboard/tracker"),
    // sidebarEntry(SolarCloudIcon.Calculator, "Calculator", "/"),
    sidebarEntry(SolarCloudIcon.Location, "Locations", "/dashboard/locations"),
    sidebarSpacer(),
    // sidebarEntry(SolarCloudIcon.Statement, "Statement", "/"),
    // sidebarEntry(SolarCloudIcon.Energy, "Energy Company", "/"),
    // sidebarEntry(SolarCloudIcon.Orders, "Buy more", "/dashboard/buypanels"),
    sidebarEntry(SolarCloudIcon.Allocations, "Payout allocations", "/dashboard/allocations", (props) => props?.user?.status === UserStatus.ACTIVE),
    // sidebarEntry(SolarCloudIcon.Payouts, "Disbursement", "/dashboard/payouts"),
    // sidebarEntry(SolarCloudIcon.Invoices, "Invoices (coming soon)", "/dashboard/invoices"),
    sidebarEntry(SolarCloudIcon.Orders, "Orders", "/dashboard/orders"),
    sidebarEntry(SolarCloudIcon.TermsAndConditions, "Terms and conditions", "/dashboard/termsandconditions"),
    // sidebarEntry(SolarCloudIcon.Help, "Help", "/"),
    sidebarSpacer(),
    sidebarEntry(SolarCloudIcon.Contact, "Profile", "/dashboard/profile"),
    sidebarEntry(SolarCloudIcon.Password, "Change password", "/dashboard/changepassword", (props) => props?.federatedUser !== true),
    sidebarEntry(SolarCloudIcon.Logout, "Logout", "/logout"),
    sidebarEntry(SolarCloudIcon.Contact, "Contact us", "/dashboard/contact"),
    // sidebarEntry(SolarCloudIcon.BuyPanels, "(Buy Panels)", "/buypanels"),
    // sidebarEntry(SolarCloudIcon.Settings, "Settings", "/"),
];

const sidebarItemEnabled = (props: EnablementProps, sidebarItem: SidebarEntry) => {
    return true;
}

export const DashboardPage = () => {
    const profile = useContext(ProfileContext);
    const { data: environment } = useEnvironmentQuery();
    const { data: authentication } = useAuthenticationQuery();

    const user = authentication?.user;

    const sidebarItems = sidebarEntries.filter(
        sidebarItem => sidebarItemEnabled({ profile, user, federatedUser: authentication?.loggedIn && authentication?.federated }, sidebarItem)
    );

    const showNameFeature = environment?.hasFeature("ShowName");

    return (
        <div className={bemName("Dashboard")}>
            <Sidebar profile={profile} entries={sidebarItems} pageName={"Tracker"} />
            <div className={bemName("Dashboard", "content")}>
                {/* <Avatar /> */}
                <Outlet />
                {
                    showNameFeature &&
                    <div className={bemName("Dashboard", "name")}>{authentication?.loggedIn ? authentication?.email : ""}</div>
                }
                <Footer />
            </div>
        </div>
    );
}

export default DashboardPage;
