export const apiServer = (
    () => {
        const elements = document.getElementsByTagName("meta");
        for (var i = 0; i < elements.length; ++i) {
            const element = elements.item(i) as HTMLMetaElement;
            if (element.name === "apiServer") {
                return element.content !== "" ? element.content : undefined;
            }
        }
        return undefined;
    }
)();