import { CognitoUser } from "@aws-amplify/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Auth, Hub } from "aws-amplify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { OpenAPI, User, DefaultService as UsersService } from '../api/SolarCloudApi';
import { invalidateQueryDependencies } from "../hooks/Dependencies";
import { Environment, useEnvironmentQuery } from "../hooks/Environment";
import { QuerySummary } from "../hooks/QuerySummary";
import { useUuid } from "../hooks/Utils";
import { logger } from "./logger";

type AuthenticationState = {
    initialised: boolean,
    environment?: Environment,
};

const authState: AuthenticationState = {
    initialised: false,
}

const authInit = (environment?: Environment) => {
    if (!environment || authState.initialised) {
        return;
    }

    authState.initialised = true;
    authState.environment = environment;

    // Hub.listen(
    //     'auth', 
    //     ({ payload: { event, data } }) => {
    //         logger.log("@@@@@ Auth event:", event, data);
    //         switch (event) {
    //         case 'signIn':
    //         case 'cognitoHostedUI':
    //             break;
    //         case 'customOAuthState':
    //             break;
    //         case 'signOut':
    //             break;
    //         case 'tokenRefresh':
    //             break;
    //         case 'signIn_failure':
    //         case 'cognitoHostedUI_failure':
    //             logger.log('Sign in failure', data);
    //             break;
    //         }
    //     }
    // );

    // logger.log("@@@@ Auth.configure", environment.awsExports);
    Auth.configure(environment.awsExports);
}

type AuthenticationLoggedIn = {
    loggedIn: true;
    user: User;
    email: string;
    idToken: string;
    accessToken: string;
    federated: boolean;
};

type AuthenticationLoggedOut = {
    loggedIn: false;
    user: undefined;
    googleSignIn: () => Promise<void>;
    appleSignIn: () => Promise<void>;
    facebookSignIn: () => Promise<void>;
};

export type Authentication = AuthenticationLoggedIn | AuthenticationLoggedOut;

export const useAuthenticationQuery = (querySummary?: QuerySummary, oauthState?: string) => {
    const id = useUuid();
    const queryClient = useQueryClient();
    const { data: environment } = useEnvironmentQuery();

    authInit(environment);

    const authenticationLoggedOut = (): AuthenticationLoggedOut => {
        return {
            loggedIn: false,
            user: undefined,
            googleSignIn: async () => {
                await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: oauthState });
            },
            appleSignIn: async () => {
                await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple, customState: oauthState });
            },
            facebookSignIn: async () => {
                await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook, customState: oauthState });
            },
        };
    }

    const query = useQuery(
        "authentication",
        async (): Promise<Authentication> => {
            if (!environment) {
                return authenticationLoggedOut();
            }

            Hub.listen(
                'auth', 
                ({ payload: { event, data } }) => {
                    // logger.log("@@@@@ Auth event:", id, event, data);

                    switch (event) {
                    case 'signIn':
                        // logger.log("@@@@@ signin authentication:", id, event, data);
                        queryClient.invalidateQueries("authentication");
                        break;
                    case 'signOut':
                        // logger.log("@@@@@ invalidating authentication:", id, event, data);
                        queryClient.invalidateQueries("authentication");
                        break;
                    case 'tokenRefresh':
                        // logger.log("@@@@@ invalidating authentication:", id, event, data);
                        queryClient.invalidateQueries("authentication");
                        break;
                    case 'signIn_failure':
                    case 'cognitoHostedUI_failure':
                        logger.error('Sign in failure', data);
                        break;
                    default:
                        break;
                    }
                }
            );

            try {
                const user: CognitoUser | undefined = await Auth.currentAuthenticatedUser();

                if (!user) {
                    return authenticationLoggedOut();
                }
    
                const session = user.getSignInUserSession()!;
    
                const idToken = session.getIdToken();
                const identities = idToken.decodePayload().identities;
                const accessToken = session.getAccessToken();

                OpenAPI.TOKEN = idToken.getJwtToken();
                const profileUser = await UsersService.getProfile();

                OpenAPI.TOKEN = getAccessToken;

                return {
                    loggedIn: true,
                    user: profileUser,
                    email: session.getIdToken().decodePayload().email,
                    idToken: idToken.getJwtToken(),
                    accessToken: accessToken.getJwtToken(),
                    federated: identities !== undefined,
                }
            } catch (error: unknown) {
                // logger.error("@@@@ Authentication error:", error);

                return authenticationLoggedOut();
            }
        },
        {
            staleTime: Infinity,
        }
    );

    querySummary?.updateQuery(id, "authentication", query);

    return query;
}

export const useAuthenticationMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();
    const { data: authentication } = useAuthenticationQuery(querySummary);

    const mutationFunction = async (): Promise<void> => {
        if (!authentication || !authentication.loggedIn) {
          return;
        }

        await Auth.signOut();
    };

    const mutation = useMutation(
        mutationFunction,
        {
            onSuccess: (data, variables, context) => {
                invalidateQueryDependencies("authentication");
            },
            onError: (error) => {
                console.log(`Failed to signOut`, error);
            }
        }
    );

    querySummary?.updateMutation(id, "authentication", mutation);

    return mutation;
}

// export const authenticationConfigure = (environment: Environment) => {
//     Hub.listen(
//         'auth', 
//         ({ payload: { event, data } }) => {
//             logger.log("@@@@@ Auth event:", event, data);
//             switch (event) {
//             case 'signIn':
//             case 'cognitoHostedUI':
//                 break;
//             case 'customOAuthState':
//                 break;
//             case 'signOut':
//                 break;
//             case 'tokenRefresh':
//                 break;
//             case 'signIn_failure':
//             case 'cognitoHostedUI_failure':
//                 logger.log('Sign in failure', data);
//                 break;
//             }
//         }
//     );

//     logger.log("@@@@ Auth.configure", environment.awsExports);
//     Auth.configure(environment.awsExports);
// }

// const isUser = async () => {
//     try {
//         const session = await Auth.currentSession();

//         return (session.getAccessToken() || session.getIdToken()) && true;
//     } catch (error: unknown) {
//         return false;
//     }
// }

// export const useIsUserQuery = (querySummary?: QuerySummary) => {
//     const id = useUuid();

//     const query = useQuery(
//         "isUser",
//         isUser
//     );

//     querySummary?.updateQuery(id, "useIsUserQuery", query);

//     return query;
// }

export const getIdToken = async (): Promise<any> => {
    if (!authState.initialised) {
        return undefined;
    }

    const userData = await Auth.currentAuthenticatedUser() as CognitoUser | undefined;

    return userData?.getSignInUserSession()?.getIdToken().getJwtToken();
}

// export const useIdToken = () => {
//     const query = useQuery(
//         "idToken",
//         getIdToken
//     );

//     return query;
// }

export const getAccessToken = async (): Promise<any> => {
    if (!authState.initialised) {
        return;
    }
    
    try {
        const userData = await Auth.currentAuthenticatedUser() as CognitoUser | undefined;

        return userData?.getSignInUserSession()?.getAccessToken().getJwtToken();
    } catch (error: unknown) {
        return;
    }
}

// export const useAccessToken = () => {
//     const query = useQuery(
//         "accessToken",
//         getAccessToken
//     );

//     return query;
// }

// const getEmail = async () => {
//     try {
//         const userData = await Auth.currentAuthenticatedUser() as CognitoUser;

//         return userData.getSignInUserSession()!.getIdToken().decodePayload().email;
//     } catch (err: any) {
//         logger.error("Autentication failed:", err);
//         return undefined;
//     }
// }

// export const useEmailQuery = (querySummary?: QuerySummary) => {
//     const id = useUuid();

//     const query = useQuery(
//         "email",
//         getEmail,
//         {
//             staleTime: Infinity,
//         }
//     );

//     querySummary?.updateQuery(id, "useEmailQuery", query);

//     return query;
// }

// const isFederatedUser = async () => {
//     try {
//         const userData = await Auth.currentAuthenticatedUser() as CognitoUser;

//         const identities = userData.getSignInUserSession()!.getIdToken().decodePayload().identities;

//         return identities !== undefined;
//     } catch (err: any) {
//         logger.error("Autentication failed:", err);
//         return undefined;
//     }
// }

// export const useFederatedUserQuery = (querySummary?: QuerySummary) => {
//     const id = useUuid();

//     const query = useQuery(
//         "federatedUser",
//         isFederatedUser,
//         {
//             staleTime: Infinity,
//         }
//     );

//     querySummary?.updateQuery(id, "useFederatedUserQuery", query);

//     return query;
// }
