import { bemName } from "../util/bemName";

import { ReactComponent as WarningIcon } from "../res/icons/message/circle-exclamation-regular.svg";
import { ReactComponent as InformationIcon } from "../res/icons/message/circle-info-regular.svg";
import { ReactComponent as FatalIcon } from "../res/icons/message/explosion-regular.svg";
import { ReactComponent as ErrorIcon } from "../res/icons/message/square-x-regular.svg";
import { ReactComponent as CloseIcon } from "../res/icons/message/xmark-regular.svg";

import "./Message.scss";
import { MessageType } from "./Messages";

const iconFromType = (type: MessageType | undefined) => {
    switch (type) {
        case MessageType.Informational:
            return <InformationIcon />;

        case MessageType.Warning:
            return<WarningIcon />;

        case MessageType.Error:
            return <ErrorIcon />;

        case MessageType.Critical:
        default:
            return <FatalIcon />;
    }
}

interface MessageProps {
    type?: MessageType;
    // icon?: string | undefined;
    header?: string;
    content?: string;
    // info?: boolean;
    // warning?: boolean;
    // error?: boolean;
    onDismiss: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const Message = (props: MessageProps) => {
    const { type, header, content, onDismiss} = props;

    return (
        <div className={bemName("Message", "container", type)}>
            <div className={bemName("Message", "icon")}>
                {iconFromType(type)}
            </div>
            <div className={bemName("Message", "body")}>
                <div className={bemName("Message", "header")}>{header}</div>
                <div className={bemName("Message", "content")}>{content}</div>
            </div>
            <div className={bemName("Message", "close")}>
                <button className={bemName("Message", "closeIcon")} onClick={onDismiss}><CloseIcon /></button>
            </div>
        </div>
    );
}

export { Message };
