import { bemName } from "../util/bemName";
import "./DataBox.scss";

const componentName = "DataBox"

interface Props {
    blockName?: string;
    title: string;
    value?: string;
    icon: React.ReactNode;
    onClick?: () => Promise<void>|void;
}

export const DataBox: React.FunctionComponent<Props> = ({ blockName, title, value, icon, onClick }) => {
    const block = blockName || componentName;

    return (
        <div className={bemName(block)} onClick={() => onClick && onClick()}>
            <div className={bemName(block, "title")}>{title}</div>
            <div className={bemName(block, "value")}>{value ?? "—"}</div>
            <div className={bemName(block, "icon")}>{icon}</div>
        </div>
    );
}
