export enum CalcMode {
    Bill,
    Capacity,
};

export const COST_PER_PANEL = 149.00; // in Dollars 

const BILL_SYSTEMSIZE_RATIO = 0.0313;
const NUM_QUARTERS_PER_YEAR = 4;
// const RETURN_MULTIPLIER = 2.137392;
const MYBILL = 500;
const MYCAPACITY = 15.7;

// function uuidv4() {
//     return (
//         eval("[1e7]+-1e3+-4e3+-8e3+-1e11")
//             .replace(
//                 /[018]/g, 
//                 (c: number) =>
//                     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
//             )
//     );
// }

function round (number: number, decimalPlaces: number) : number { 
    const factorOfTen = Math.pow(10, decimalPlaces)
    return (Math.round(number * factorOfTen) / factorOfTen)
}

//
// calc.Bill:
//       billPerQuarter = userEnteredValue
//       capacity       = billPerQuarter * BILL_SYSTEMSIZE_RATIO # ROUND TO 1 DECIMAL PLACE
//
// calc.Capacity:
//      capacity = userEnteredValue
//      billPerQuarter = capacity / BILL_SYSTEMSIZE_RATIO # TRUNCATE THIS VALUE
//
//
//  totalBill = ROUND(billPerQuarter * NUM_QUARTERS_PER_YEAR * purchase_term, 2)
//
//  numberOfPanels = ( ROUND(billPerQuarter * BILL_SYSTEMSIZE_RATIO, 1) * 1000 ) / 100
//
//  panelCost = ROUND(numberOfPanels * COST_PER_PANEL), 2)
//
//  perCentOffBill = percentageSavings / 100  
//
//  estimatedSavings = ROUND(((totalBill * perCentOffBill) - panelCost), 2)
//

export function calculateEstimatedSavings(calcMode: CalcMode, value: number, purchaseTerm: number, percentageSavings: number): number {
    var kW = 0.0;
    var billAmountPerQuarter = 0;

    switch (calcMode) {
        case CalcMode.Bill:
            billAmountPerQuarter = value;

            if ( Number.isNaN(billAmountPerQuarter)) {
                // logger.log("myBill: NaN")
                billAmountPerQuarter = MYBILL;
            }

            kW = round(billAmountPerQuarter * BILL_SYSTEMSIZE_RATIO, 1);
            break;

        case CalcMode.Capacity:
            kW = value;

            if (Number.isNaN(kW)) { 
                // logger.log("myCapacity: NaN")
                kW = MYCAPACITY;
            }
            billAmountPerQuarter = Math.trunc(kW / BILL_SYSTEMSIZE_RATIO)
            break;
    }

    const totalBill = round( ((billAmountPerQuarter * NUM_QUARTERS_PER_YEAR * purchaseTerm)), 2);

    const numberOfPanels = (round(billAmountPerQuarter * BILL_SYSTEMSIZE_RATIO, 1) * 1000) / 100

    const panelCost = round( (numberOfPanels * COST_PER_PANEL), 2);

    const perCentOffBill = percentageSavings / 100;

    const estimatedSavings = round( ((totalBill * perCentOffBill) - panelCost), 2)

    return estimatedSavings;
}

export function calculateCapacityFromBill(value: number): number {
    return round(value * BILL_SYSTEMSIZE_RATIO, 1);
}