import { bemName } from "../util/bemName";
import "./SavingsGraph.scss";

const componentName = "SavingsGraph";

type Point = {
    x: number;
    y: number;
};

type Props = {
    energyBill?: number;
    savings?: number;
    extra?: number;
};

const calculatePoint = (ratio: number, radius: number, centre: Point) => {
    const angle = Math.PI/2 + 2 * Math.PI * ratio;

    const x = -Math.cos(angle) * radius + centre.x;
    const y = -Math.sin(angle) * radius + centre.y;

    return { x, y };
}

const calculateArc = (start: Point, startRatio: number, endRatio: number, radius: number, centre: Point): { path: string, end: Point} => {
    const { x, y } = calculatePoint(endRatio, radius, centre);

    const largeArcFlag = (endRatio - startRatio) > 0.5 ? "1" : "0";

    // logger.log("@@@@", endRatio, largeArcFlag, start, { x, y });

    return { path: `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x} ${y}`, end: { x, y } };
}

export const SavingsGraph = ({ energyBill, savings, extra }: Props) => {
    const ratio =
        savings !== undefined && energyBill !== undefined ?
            Math.min(savings / energyBill, 2)
            :
            undefined;

    const elements: React.ReactElement[] = [];

    const radius = 40;
    const centre = { x: 50, y: 50 };

    const start = { x: 50, y: 10 };
    var current = { ...start };

    var pathIndex = 0;

    if (ratio) {
        const { path: primaryPath, end: primaryEnd } = calculateArc(current, 0, ratio, radius, centre);
    
        const arcElement =
            ratio < 1 ?
                <path key={`path-${pathIndex++}`} className={bemName(componentName, "arc")} d={`${primaryPath}`} />
                :
                <circle key={`path-${pathIndex++}`} className={bemName(componentName, "arc")} cx={centre.x} cy={centre.y} r={radius} />

        elements.push(arcElement);

        current = primaryEnd;

        if (ratio > 1) {
            const secondaryRatio = ratio % 1;

            current = { ...start };

            const { path: secondaryPath, end: secondaryEnd } = calculateArc(current, 0, secondaryRatio, radius, centre);
    
            const secondaryArcElement =
                <path key={`path-${pathIndex++}`} className={bemName(componentName, "arcSecondary")} d={`${secondaryPath}`} />

            elements.push(secondaryArcElement);

            current = secondaryEnd;
        }
    }

    if (extra && energyBill) {
        const extraRatio = ((ratio ?? 0) % 1) + (extra / energyBill);

        const { path: extraPath, end: extraEnd } = calculateArc(current, ratio ?? 0, extraRatio, radius, centre);

        const extraArcElement =
            extraRatio < 1 ?
                <path key={`path-${pathIndex++}`} className={bemName(componentName, "arcExtra")} d={`${extraPath}`} />
                :
                <circle key={`path-${pathIndex++}`} className={bemName(componentName, "arcExtra")} cx={centre.x} cy={centre.y} r={radius} />

        elements.push(extraArcElement);

        current = extraEnd;
    }
    
    return (
        <div className={bemName(componentName, "container")}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 100 100"} >
                <circle className={bemName(componentName, "background")} cx="50" cy="50" r="40" />
                {elements}
            </svg>
        </div>
    );
}