/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TrackerEventPriority {
    CRITICAL = 'Critical',
    HIGH = 'High',
    LOW = 'Low',
    NORMAL = 'Normal',
}
