/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrderPaymentState {
    PAID = 'Paid',
    PENDING = 'Pending',
    REVERSED = 'Reversed',
    UNCONFIRMED = 'Unconfirmed',
    UNSUCCESSFUL = 'Unsuccessful',
}
