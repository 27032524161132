/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Allocation } from '../models/Allocation';
import type { Contact } from '../models/Contact';
import type { CountryInfo } from '../models/CountryInfo';
import type { Currency } from '../models/Currency';
import type { DashboardData } from '../models/DashboardData';
import type { EnergyCompany } from '../models/EnergyCompany';
import type { JPasswordChange } from '../models/JPasswordChange';
import type { JPasswordReset } from '../models/JPasswordReset';
import type { JVerification } from '../models/JVerification';
import type { Message } from '../models/Message';
import type { MintCard } from '../models/MintCard';
import type { Order } from '../models/Order';
import type { Organisation3 } from '../models/Organisation3';
import type { OwnerDisbursement } from '../models/OwnerDisbursement';
import type { PageableCollectionAllocation } from '../models/PageableCollectionAllocation';
import type { PageableCollectionCurrency } from '../models/PageableCollectionCurrency';
import type { PageableCollectionEnergyCompany } from '../models/PageableCollectionEnergyCompany';
import type { PageableCollectionJsonInvoice } from '../models/PageableCollectionJsonInvoice';
import type { PageableCollectionOrder } from '../models/PageableCollectionOrder';
import type { PageableCollectionOrganisation } from '../models/PageableCollectionOrganisation';
import type { PageableCollectionOwnerDisbursement } from '../models/PageableCollectionOwnerDisbursement';
import type { PageableCollectionPowerGenerationPricing } from '../models/PageableCollectionPowerGenerationPricing';
import type { PageableCollectionPowerGenerationProduct } from '../models/PageableCollectionPowerGenerationProduct';
import type { PageableCollectionPowerGenerationSite } from '../models/PageableCollectionPowerGenerationSite';
import type { PageableCollectionPowerGenerationSlice } from '../models/PageableCollectionPowerGenerationSlice';
import type { PageableCollectionPowerMonitor } from '../models/PageableCollectionPowerMonitor';
import type { PageableCollectionPowerPurchaseAgreement } from '../models/PageableCollectionPowerPurchaseAgreement';
import type { PageableCollectionPowerPurchasePricing } from '../models/PageableCollectionPowerPurchasePricing';
import type { PageableCollectionTermOrCondition } from '../models/PageableCollectionTermOrCondition';
import type { PageableCollectionTotal } from '../models/PageableCollectionTotal';
import type { PageableCollectionTrackerEvent } from '../models/PageableCollectionTrackerEvent';
import type { PageableCollectionUser } from '../models/PageableCollectionUser';
import type { PowerGenerationInfo } from '../models/PowerGenerationInfo';
import type { PowerGenerationPricing } from '../models/PowerGenerationPricing';
import type { PowerGenerationProduct } from '../models/PowerGenerationProduct';
import type { PowerGenerationSite1 } from '../models/PowerGenerationSite1';
import type { PowerGenerationSlice1 } from '../models/PowerGenerationSlice1';
import type { PowerMonitor1 } from '../models/PowerMonitor1';
import type { PowerPurchaseAgreement } from '../models/PowerPurchaseAgreement';
import type { PowerPurchasePricing } from '../models/PowerPurchasePricing';
import type { ReferenceData } from '../models/ReferenceData';
import type { TermOrCondition } from '../models/TermOrCondition';
import type { User3 } from '../models/User3';
import type { UUID } from '../models/UUID';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiTrackerHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracker/health',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getApiTrackerMetrics(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracker/metrics',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiTrackerRefresh(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tracker/refresh',
        });
    }

    /**
     * @param organisation
     * @param personal
     * @returns PageableCollectionTrackerEvent OK
     * @throws ApiError
     */
    public static trackerEventsGet(
        organisation?: Array<string>,
        personal?: boolean,
    ): CancelablePromise<PageableCollectionTrackerEvent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tracker/events',
            query: {
                'organisation': organisation,
                'personal': personal,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static calculateDisbursements(
        requestBody?: Array<OwnerDisbursement>,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/financials/owner-disbursements/all',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiFinancialsHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/financials/health',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static calculateIncomes(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/financials/actions/incomes',
        });
    }

    /**
     * @returns PageableCollectionEnergyCompany OK
     * @throws ApiError
     */
    public static searchForEnergyCompanys(): CancelablePromise<PageableCollectionEnergyCompany> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/financials/energycompanies',
        });
    }

    /**
     * @param requestBody
     * @returns PageableCollectionEnergyCompany OK
     * @throws ApiError
     */
    public static createEnergyCompanies(
        requestBody?: Array<EnergyCompany>,
    ): CancelablePromise<PageableCollectionEnergyCompany> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/financials/energycompanies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns EnergyCompany OK
     * @throws ApiError
     */
    public static createEnergyCompany(
        requestBody?: EnergyCompany,
    ): CancelablePromise<EnergyCompany> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/financials/energycompanies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param organisation
     * @param personal
     * @returns PageableCollectionOwnerDisbursement OK
     * @throws ApiError
     */
    public static ownerDisbursementsGet(
        organisation?: Array<string>,
        personal?: boolean,
    ): CancelablePromise<PageableCollectionOwnerDisbursement> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/financials/owner-disbursements',
            query: {
                'organisation': organisation,
                'personal': personal,
            },
        });
    }

    /**
     * @param uuid
     * @returns EnergyCompany OK
     * @throws ApiError
     */
    public static getEnergyCompany(
        uuid: UUID,
    ): CancelablePromise<EnergyCompany> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/financials/energycompanies/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns EnergyCompany OK
     * @throws ApiError
     */
    public static updateEnergyCompany(
        uuid: UUID,
    ): CancelablePromise<EnergyCompany> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/financials/energycompanies/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static deleteEnergyCompany(
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/financials/energycompanies/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static disbursementCalculator(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/financials/actions/disbursements',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static xeroInvoicing(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/financials/actions/xero',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getApiFinancialsMetrics(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/financials/metrics',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiFinancialsRefresh(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/financials/refresh',
        });
    }

    /**
     * @param organisation
     * @param personal
     * @returns PageableCollectionJsonInvoice OK
     * @throws ApiError
     */
    public static searchForInvoices(
        organisation?: Array<string>,
        personal?: boolean,
    ): CancelablePromise<PageableCollectionJsonInvoice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/financials/invoices',
            query: {
                'organisation': organisation,
                'personal': personal,
            },
        });
    }

    /**
     * @param id
     * @returns binary OK
     * @throws ApiError
     */
    public static getInvoicePdf(
        id: UUID,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/financials/invoices/orders/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiOrdersHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/health',
        });
    }

    /**
     * @returns PageableCollectionPowerGenerationProduct OK
     * @throws ApiError
     */
    public static productCatalogue(): CancelablePromise<PageableCollectionPowerGenerationProduct> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/products/catalogue',
        });
    }

    /**
     * @param uuid
     * @returns PowerGenerationProduct OK
     * @throws ApiError
     */
    public static productGet(
        uuid: UUID,
    ): CancelablePromise<PowerGenerationProduct> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/products/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns PowerGenerationProduct OK
     * @throws ApiError
     */
    public static productUpdate(
        uuid: UUID,
        requestBody?: PowerGenerationProduct,
    ): CancelablePromise<PowerGenerationProduct> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/orders/products/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns void
     * @throws ApiError
     */
    public static productDelete(
        uuid: UUID,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/orders/products/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns PageableCollectionPowerGenerationProduct OK
     * @throws ApiError
     */
    public static productSearch(): CancelablePromise<PageableCollectionPowerGenerationProduct> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/products',
        });
    }

    /**
     * @param requestBody
     * @returns PowerGenerationProduct OK
     * @throws ApiError
     */
    public static productCreate(
        requestBody?: PowerGenerationProduct,
    ): CancelablePromise<PowerGenerationProduct> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/orders/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @param panels
     * @returns Order OK
     * @throws ApiError
     */
    public static newOrderGet(
        uuid: UUID,
        panels?: number,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/new/{uuid}',
            path: {
                'uuid': uuid,
            },
            query: {
                'panels': panels,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns Order OK
     * @throws ApiError
     */
    public static newOrderUpsert(
        uuid: UUID,
        requestBody?: Order,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/orders/new/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PageableCollectionOrder OK
     * @throws ApiError
     */
    public static existingOrderSearch(): CancelablePromise<PageableCollectionOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/existing',
        });
    }

    /**
     * @returns PageableCollectionOrder OK
     * @throws ApiError
     */
    public static orderSearch(): CancelablePromise<PageableCollectionOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/orders',
        });
    }

    /**
     * @param requestBody
     * @returns Order OK
     * @throws ApiError
     */
    public static orderCreate(
        requestBody?: Order,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/orders/orders',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns Order OK
     * @throws ApiError
     */
    public static orderGet(
        uuid: UUID,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/orders/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static orderDelete(
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/orders/orders/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns Order OK
     * @throws ApiError
     */
    public static newOrderMintPayment(
        uuid: UUID,
        requestBody?: MintCard,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/orders/new/{uuid}/action/payment/mint',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param productUuid
     * @param uuid
     * @returns PowerGenerationPricing OK
     * @throws ApiError
     */
    public static productPricingGet(
        productUuid: UUID,
        uuid: UUID,
    ): CancelablePromise<PowerGenerationPricing> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/products/{productUuid}/pricing/{uuid}',
            path: {
                'productUuid': productUuid,
                'uuid': uuid,
            },
        });
    }

    /**
     * @param productUuid
     * @param uuid
     * @returns PowerGenerationPricing OK
     * @throws ApiError
     */
    public static productPricingUpdate(
        productUuid: UUID,
        uuid: UUID,
    ): CancelablePromise<PowerGenerationPricing> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/orders/products/{productUuid}/pricing/{uuid}',
            path: {
                'productUuid': productUuid,
                'uuid': uuid,
            },
        });
    }

    /**
     * @param productUuid
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static productPricingDelete(
        productUuid: UUID,
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/orders/products/{productUuid}/pricing/{uuid}',
            path: {
                'productUuid': productUuid,
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiOrdersRefresh(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/orders/refresh',
        });
    }

    /**
     * @param uuid
     * @returns Order OK
     * @throws ApiError
     */
    public static orderFulfil(
        uuid: UUID,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/orders/{uuid}/fulfil',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns Order OK
     * @throws ApiError
     */
    public static newOrderCheckout(
        uuid: UUID,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/orders/new/{uuid}/checkout',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param productUuid
     * @returns PageableCollectionPowerGenerationPricing OK
     * @throws ApiError
     */
    public static productPricingSearch(
        productUuid: UUID,
    ): CancelablePromise<PageableCollectionPowerGenerationPricing> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/products/{productUuid}/pricing',
            path: {
                'productUuid': productUuid,
            },
        });
    }

    /**
     * @param productUuid
     * @param requestBody
     * @returns PowerGenerationPricing OK
     * @throws ApiError
     */
    public static productPricingCreate(
        productUuid: UUID,
        requestBody?: PowerGenerationPricing,
    ): CancelablePromise<PowerGenerationPricing> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/orders/products/{productUuid}/pricing',
            path: {
                'productUuid': productUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PageableCollectionOrder OK
     * @throws ApiError
     */
    public static myOrderSearch(): CancelablePromise<PageableCollectionOrder> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/myorders',
        });
    }

    /**
     * @param uuid
     * @returns Order OK
     * @throws ApiError
     */
    public static newOrderCheckoutCancel(
        uuid: UUID,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/orders/new/{uuid}/checkout/cancel',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns Order OK
     * @throws ApiError
     */
    public static newOrderSubmit(
        uuid: UUID,
    ): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/orders/new/{uuid}/action/submit',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getApiOrdersMetrics(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/orders/metrics',
        });
    }

    /**
     * @returns PageableCollectionCurrency OK
     * @throws ApiError
     */
    public static currenciesGet(): CancelablePromise<PageableCollectionCurrency> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reference/currencies',
        });
    }

    /**
     * @param requestBody
     * @returns PageableCollectionCurrency OK
     * @throws ApiError
     */
    public static currenciesPut(
        requestBody?: Array<Currency>,
    ): CancelablePromise<PageableCollectionCurrency> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/reference/currencies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReferenceData OK
     * @throws ApiError
     */
    public static organisationType(): CancelablePromise<Array<ReferenceData>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reference/organisationtype',
        });
    }

    /**
     * @returns CountryInfo OK
     * @throws ApiError
     */
    public static countryInfoSearch(): CancelablePromise<Array<CountryInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reference/countryinfo/all',
        });
    }

    /**
     * @returns CountryInfo OK
     * @throws ApiError
     */
    public static countryInfoSearchActive(): CancelablePromise<Array<CountryInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reference/countryinfo/available',
        });
    }

    /**
     * @param path
     * @returns any OK
     * @throws ApiError
     */
    public static fileGet(
        path: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reference/files/{path}',
            path: {
                'path': path,
            },
        });
    }

    /**
     * @param path
     * @param _public
     * @returns void
     * @throws ApiError
     */
    public static filePut(
        path: string,
        _public?: boolean,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/reference/files/{path}',
            path: {
                'path': path,
            },
            query: {
                'public': _public,
            },
        });
    }

    /**
     * @param path
     * @returns void
     * @throws ApiError
     */
    public static fileDelete(
        path: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/reference/files/{path}',
            path: {
                'path': path,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getApiReferenceMetrics(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reference/metrics',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReferenceHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/reference/health',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiReferenceRefresh(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/reference/refresh',
        });
    }

    /**
     * @param requestBody
     * @returns Message OK
     * @throws ApiError
     */
    public static countryInfoPut(
        requestBody?: Array<CountryInfo>,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/reference/countryinfo',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns PowerGenerationSite1 OK
     * @throws ApiError
     */
    public static siteGet(
        uuid: UUID,
    ): CancelablePromise<PowerGenerationSite1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/generation/sites/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns PowerGenerationSite1 OK
     * @throws ApiError
     */
    public static siteUpdate(
        uuid: UUID,
        requestBody?: PowerGenerationSite1,
    ): CancelablePromise<PowerGenerationSite1> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/generation/sites/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static siteDelete(
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/generation/sites/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns PageableCollectionPowerGenerationSite OK
     * @throws ApiError
     */
    public static siteSearch(): CancelablePromise<PageableCollectionPowerGenerationSite> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/generation/sites',
        });
    }

    /**
     * @param requestBody
     * @returns PowerGenerationSite1 OK
     * @throws ApiError
     */
    public static siteCreate(
        requestBody?: PowerGenerationSite1,
    ): CancelablePromise<PowerGenerationSite1> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/generation/sites',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns PowerGenerationSlice1 OK
     * @throws ApiError
     */
    public static sliceGet(
        uuid: UUID,
    ): CancelablePromise<PowerGenerationSlice1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/generation/slices/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns PowerGenerationSlice1 OK
     * @throws ApiError
     */
    public static sliceUpdate(
        uuid: UUID,
        requestBody?: PowerGenerationSlice1,
    ): CancelablePromise<PowerGenerationSlice1> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/generation/slices/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static sliceDelete(
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/generation/slices/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getApiGenerationMetrics(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/generation/metrics',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiGenerationRefresh(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/generation/refresh',
        });
    }

    /**
     * @param organisation
     * @param site
     * @param user
     * @returns PageableCollectionPowerGenerationSlice OK
     * @throws ApiError
     */
    public static sliceSearch(
        organisation?: UUID,
        site?: UUID,
        user?: UUID,
    ): CancelablePromise<PageableCollectionPowerGenerationSlice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/generation/slices',
            query: {
                'organisation': organisation,
                'site': site,
                'user': user,
            },
        });
    }

    /**
     * @param requestBody
     * @returns PowerGenerationSlice1 OK
     * @throws ApiError
     */
    public static sliceCreate(
        requestBody?: PowerGenerationSlice1,
    ): CancelablePromise<PowerGenerationSlice1> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/generation/slices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiGenerationHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/generation/health',
        });
    }

    /**
     * @returns PowerGenerationInfo OK
     * @throws ApiError
     */
    public static powerGenerationInfo(): CancelablePromise<PowerGenerationInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/generation/slices/summary',
        });
    }

    /**
     * @param siteUuid
     * @returns PageableCollectionPowerMonitor OK
     * @throws ApiError
     */
    public static monitorSearch(
        siteUuid: UUID,
    ): CancelablePromise<PageableCollectionPowerMonitor> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/generation/sites/{siteUuid}/monitors',
            path: {
                'siteUuid': siteUuid,
            },
        });
    }

    /**
     * @param siteUuid
     * @param requestBody
     * @returns PowerMonitor1 OK
     * @throws ApiError
     */
    public static monitorCreate(
        siteUuid: UUID,
        requestBody?: PowerMonitor1,
    ): CancelablePromise<PowerMonitor1> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/generation/sites/{siteUuid}/monitors',
            path: {
                'siteUuid': siteUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param siteUuid
     * @param uuid
     * @returns PowerMonitor1 OK
     * @throws ApiError
     */
    public static monitorGet(
        siteUuid: UUID,
        uuid: UUID,
    ): CancelablePromise<PowerMonitor1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/generation/sites/{siteUuid}/monitors/{uuid}',
            path: {
                'siteUuid': siteUuid,
                'uuid': uuid,
            },
        });
    }

    /**
     * @param siteUuid
     * @param uuid
     * @param requestBody
     * @returns PowerMonitor1 OK
     * @throws ApiError
     */
    public static monitorUpdate(
        siteUuid: UUID,
        uuid: UUID,
        requestBody?: PowerMonitor1,
    ): CancelablePromise<PowerMonitor1> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/generation/sites/{siteUuid}/monitors/{uuid}',
            path: {
                'siteUuid': siteUuid,
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param siteUuid
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static monitorDelete(
        siteUuid: UUID,
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/generation/sites/{siteUuid}/monitors/{uuid}',
            path: {
                'siteUuid': siteUuid,
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiAgreementsHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/agreements/health',
        });
    }

    /**
     * @param agreementUuid
     * @param uuid
     * @returns PowerPurchasePricing OK
     * @throws ApiError
     */
    public static agreementPricingGet(
        agreementUuid: UUID,
        uuid: UUID,
    ): CancelablePromise<PowerPurchasePricing> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/agreements/{agreementUuid}/pricing/{uuid}',
            path: {
                'agreementUuid': agreementUuid,
                'uuid': uuid,
            },
        });
    }

    /**
     * @param agreementUuid
     * @param uuid
     * @param requestBody
     * @returns PowerPurchasePricing OK
     * @throws ApiError
     */
    public static agreementPricingUpdate(
        agreementUuid: UUID,
        uuid: UUID,
        requestBody?: PowerPurchasePricing,
    ): CancelablePromise<PowerPurchasePricing> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/agreements/{agreementUuid}/pricing/{uuid}',
            path: {
                'agreementUuid': agreementUuid,
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param agreementUuid
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static agreementPricingDelete(
        agreementUuid: UUID,
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/agreements/{agreementUuid}/pricing/{uuid}',
            path: {
                'agreementUuid': agreementUuid,
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns PageableCollectionPowerPurchaseAgreement OK
     * @throws ApiError
     */
    public static agreementSearch(): CancelablePromise<PageableCollectionPowerPurchaseAgreement> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/agreements/',
        });
    }

    /**
     * @param requestBody
     * @returns PowerPurchaseAgreement OK
     * @throws ApiError
     */
    public static agreementCreate(
        requestBody?: PowerPurchaseAgreement,
    ): CancelablePromise<PowerPurchaseAgreement> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/agreements/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getApiAgreementsMetrics(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/agreements/metrics',
        });
    }

    /**
     * @param uuid
     * @returns PowerPurchaseAgreement OK
     * @throws ApiError
     */
    public static agreementGet(
        uuid: UUID,
    ): CancelablePromise<PowerPurchaseAgreement> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/agreements/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns PowerPurchaseAgreement OK
     * @throws ApiError
     */
    public static agreementUpdate(
        uuid: UUID,
        requestBody?: PowerPurchaseAgreement,
    ): CancelablePromise<PowerPurchaseAgreement> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/agreements/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static agreementDelete(
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/agreements/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param agreementUuid
     * @returns PageableCollectionPowerPurchasePricing OK
     * @throws ApiError
     */
    public static agreementPricingSearch(
        agreementUuid: UUID,
    ): CancelablePromise<PageableCollectionPowerPurchasePricing> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/agreements/{agreementUuid}/pricing',
            path: {
                'agreementUuid': agreementUuid,
            },
        });
    }

    /**
     * @param agreementUuid
     * @param requestBody
     * @returns PowerPurchasePricing OK
     * @throws ApiError
     */
    public static agreementPricingCreate(
        agreementUuid: UUID,
        requestBody?: PowerPurchasePricing,
    ): CancelablePromise<PowerPurchasePricing> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/agreements/{agreementUuid}/pricing',
            path: {
                'agreementUuid': agreementUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiAgreementsRefresh(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/agreements/refresh',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getToken(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/token',
        });
    }

    /**
     * @returns PageableCollectionTermOrCondition OK
     * @throws ApiError
     */
    public static searchForTermOrConditions(): CancelablePromise<PageableCollectionTermOrCondition> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/terms-and-conditions',
        });
    }

    /**
     * @param requestBody
     * @returns PageableCollectionTermOrCondition OK
     * @throws ApiError
     */
    public static createTermAndConditions(
        requestBody?: Array<TermOrCondition>,
    ): CancelablePromise<PageableCollectionTermOrCondition> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/terms-and-conditions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns TermOrCondition OK
     * @throws ApiError
     */
    public static createTermOrCondition(
        requestBody?: TermOrCondition,
    ): CancelablePromise<TermOrCondition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/terms-and-conditions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getApiUsersMetrics(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/metrics',
        });
    }

    /**
     * @param email
     * @returns Message OK
     * @throws ApiError
     */
    public static verificationCode(
        email?: string,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/profile/action/verification-code',
            query: {
                'email': email,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static registerContact(
        requestBody?: Contact,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/contact',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PageableCollectionUser OK
     * @throws ApiError
     */
    public static searchForUsers(): CancelablePromise<PageableCollectionUser> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/users',
        });
    }

    /**
     * @param requestBody
     * @returns User3 OK
     * @throws ApiError
     */
    public static createUser(
        requestBody?: User3,
    ): CancelablePromise<User3> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiUsersRefresh(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/refresh',
        });
    }

    /**
     * @param uuid
     * @returns Organisation3 OK
     * @throws ApiError
     */
    public static getOrganisation(
        uuid: UUID,
    ): CancelablePromise<Organisation3> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/organisations/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns Organisation3 OK
     * @throws ApiError
     */
    public static updateOrganisation(
        uuid: UUID,
        requestBody?: Organisation3,
    ): CancelablePromise<Organisation3> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/organisations/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static deleteOrganisation(
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users/organisations/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns User3 OK
     * @throws ApiError
     */
    public static getUser(
        uuid: UUID,
    ): CancelablePromise<User3> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/users/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @returns User3 OK
     * @throws ApiError
     */
    public static updateUser(
        uuid: UUID,
        requestBody?: User3,
    ): CancelablePromise<User3> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/users/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static deleteUser(
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users/users/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns TermOrCondition OK
     * @throws ApiError
     */
    public static termsAndConditionsAccept(
        uuid: UUID,
    ): CancelablePromise<TermOrCondition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/terms-and-conditions/{uuid}/accept',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param email
     * @returns Message OK
     * @throws ApiError
     */
    public static resetPassword(
        email?: string,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/profile/action/reset-password',
            query: {
                'email': email,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Message OK
     * @throws ApiError
     */
    public static resetPasswordComplete(
        requestBody?: JPasswordReset,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/profile/action/reset-password-complete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns Message OK
     * @throws ApiError
     */
    public static changePassword(
        requestBody?: JPasswordChange,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/profile/action/change-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PageableCollectionOrganisation OK
     * @throws ApiError
     */
    public static searchForOrganisations(): CancelablePromise<PageableCollectionOrganisation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/organisations',
        });
    }

    /**
     * @param requestBody
     * @returns Organisation3 OK
     * @throws ApiError
     */
    public static createOrganisation(
        requestBody?: Organisation3,
    ): CancelablePromise<Organisation3> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/organisations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User3 OK
     * @throws ApiError
     */
    public static getProfile(): CancelablePromise<User3> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/profile',
        });
    }

    /**
     * @param requestBody
     * @returns User3 OK
     * @throws ApiError
     */
    public static updateProfile(
        requestBody?: User3,
    ): CancelablePromise<User3> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/profile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PageableCollectionEnergyCompany OK
     * @throws ApiError
     */
    public static getActiveEnergyCompanies(): CancelablePromise<PageableCollectionEnergyCompany> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/allocations/energycompanies',
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static notifictions(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/notifications',
        });
    }

    /**
     * @returns PageableCollectionAllocation OK
     * @throws ApiError
     */
    public static getPersonalAllocations(): CancelablePromise<PageableCollectionAllocation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/allocations/personal',
        });
    }

    /**
     * @param requestBody
     * @returns PageableCollectionAllocation OK
     * @throws ApiError
     */
    public static updatePersonalAllocations(
        requestBody?: Array<Allocation>,
    ): CancelablePromise<PageableCollectionAllocation> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/allocations/personal',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns Message OK
     * @throws ApiError
     */
    public static verificationCodeComplete(
        requestBody?: JVerification,
    ): CancelablePromise<Message> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/profile/action/verification-code-complete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PageableCollectionTermOrCondition OK
     * @throws ApiError
     */
    public static termsAndConditionsGetAcive(): CancelablePromise<PageableCollectionTermOrCondition> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/terms-and-conditions/active',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiUsersHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/health',
        });
    }

    /**
     * @param uuid
     * @returns TermOrCondition OK
     * @throws ApiError
     */
    public static termsAndConditionsReject(
        uuid: UUID,
    ): CancelablePromise<TermOrCondition> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/terms-and-conditions/{uuid}/reject',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns TermOrCondition OK
     * @throws ApiError
     */
    public static getTermOrCondition(
        uuid: UUID,
    ): CancelablePromise<TermOrCondition> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/terms-and-conditions/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns TermOrCondition OK
     * @throws ApiError
     */
    public static updateTermOrCondition(
        uuid: UUID,
    ): CancelablePromise<TermOrCondition> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/terms-and-conditions/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static deleteTermOrCondition(
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/users/terms-and-conditions/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiConfigRefresh(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/config/refresh',
        });
    }

    /**
     * @param path
     * @returns any OK
     * @throws ApiError
     */
    public static configurationDelete(
        path: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/config/{path}',
            path: {
                'path': path,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getApiConfigMetrics(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/metrics',
        });
    }

    /**
     * @param referer
     * @returns any OK
     * @throws ApiError
     */
    public static environmentGet(
        referer?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/env',
            headers: {
                'referer': referer,
            },
        });
    }

    /**
     * @param password
     * @returns string OK
     * @throws ApiError
     */
    public static configurationGet(
        password?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/',
            query: {
                'password': password,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static configurationUpdate(
        requestBody?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/config/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiConfigHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/config/health',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiDashboardHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dashboard/health',
        });
    }

    /**
     * @param month
     * @param year
     * @param organisation
     * @returns DashboardData OK
     * @throws ApiError
     */
    public static datsetsGet(
        month: number,
        year: number,
        organisation?: UUID,
    ): CancelablePromise<DashboardData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dashboard/datasets/{year}/{month}',
            path: {
                'month': month,
                'year': year,
            },
            query: {
                'organisation': organisation,
            },
        });
    }

    /**
     * @param organisation
     * @returns PageableCollectionTotal OK
     * @throws ApiError
     */
    public static totalsGet(
        organisation?: UUID,
    ): CancelablePromise<PageableCollectionTotal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dashboard/totals',
            query: {
                'organisation': organisation,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiDashboardRefresh(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/dashboard/refresh',
        });
    }

    /**
     * @param month
     * @param year
     * @param organisation
     * @returns PageableCollectionTotal OK
     * @throws ApiError
     */
    public static monthTotalsGet(
        month: number,
        year: number,
        organisation?: UUID,
    ): CancelablePromise<PageableCollectionTotal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dashboard/totals/{year}/{month}',
            path: {
                'month': month,
                'year': year,
            },
            query: {
                'organisation': organisation,
            },
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getApiDashboardMetrics(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dashboard/metrics',
        });
    }

    /**
     * @returns string OK
     * @throws ApiError
     */
    public static getApiMonitorMetrics(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/monitor/metrics',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static getApiMonitorHealth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/monitor/health',
        });
    }

    /**
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static postApiMonitor(
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/monitor/{uuid}',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @param uuid
     * @returns any OK
     * @throws ApiError
     */
    public static postApiMonitorDuplicate(
        uuid: UUID,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/monitor/{uuid}/duplicate',
            path: {
                'uuid': uuid,
            },
        });
    }

    /**
     * @returns any Created
     * @throws ApiError
     */
    public static postApiMonitorRefresh(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/monitor/refresh',
        });
    }

}
