/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AddressType {
    BUSINESS = 'Business',
    HOME = 'Home',
    LOCATION = 'Location',
    POSTAL = 'Postal',
}
