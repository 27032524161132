import { Order } from "../api/SolarCloudApi";
import Visa from "../res/icons/visa.png";
import { bemName } from "../util/bemName";
import { currencyFormat } from "../util/formats";

import "./PaymentSummaryForm.scss";

const componentName = "PaymentSummaryForm";

type PaymentSummaryFormProps = {
    order: Order | undefined;
};

export function PaymentSummaryForm({ order: newOrder }: PaymentSummaryFormProps) {
    const paymentFormatted = currencyFormat(newOrder?.totalPayment, { excludeCurrencyId: true });

    return (
        <div className={bemName(componentName, "container")}>
            <div className={bemName(componentName, "payment")} key="payment-container">
                <div className={bemName(componentName, "paymentLabel")} key="payment-label">Total  <span>{paymentFormatted}</span></div>
                <img src={Visa} alt="Visa"/>
            </div>
        </div>
    );
}
