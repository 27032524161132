import { DateTime } from "luxon";
import React, { MouseEvent, useState } from "react";
import { DashboardData } from "../api/SolarCloudApi";
import { bemName } from "../util/bemName";
import { metricFormat } from "../util/formats";

import "./DatasetChart.scss"

const barWidth = 10;
const barGap = 2;
const barStride = barWidth + barGap;
const barRadius = barWidth / 4;

const xAxisHeight = 15;
const yAxisWidth = 30;

const chartHeight = 96;
const chartWidth = yAxisWidth + 31 * (barWidth + barGap);
const maxBarHeight = chartHeight - xAxisHeight;

const fontHeight = 10;

const numberOfTicks = 4;

const popupWidth = 50;
const popupHeight = 25;
const popupGap = 5;
const popupTextHeight = 10;

const maxTimezoneOffset = 13;

const popupTimeout = 100;

const dayArray = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

function nice(value: number, round: boolean) {
    const exponent = Math.floor(Math.log10(value));
    const power = Math.pow(10, exponent);
    const fraction = value / power;

    if (round) {
        if (fraction < 1.5) {
            return power;
        } else if (fraction < 3.0) {
            return 2.0 * power;
        } else if (fraction < 7.0) {
            return 5.0 * power;
        } else {
            return 10.0 * power;
        }
    } else {
        if (fraction <= 1.0) {
            return power;
        } else if (fraction <= 2.0) {
            return 2.0 * power;
        } else if (fraction <= 5.0) {
            return 5.0 * power;
        } else {
            return 10.0 * power;
        }
    }
}

function boundCentre(start: number, span: number, min: number, max: number) {
    const halfSpan = span / 2;

    if (start - halfSpan < min) {
        return min + halfSpan;
    }

    if (start + halfSpan > max) {
        return max - halfSpan;
    }

    return start;
}

function bound(value: number, min: number, max: number) {
    if (value < min) {
        return min;
    }

    if (value > max) {
        return max;
    }

    return value;
}

interface Props {
    datasets: DashboardData;
    dataset: string;
    prefix: boolean;
    scale: number;
    xAxisName: string;
    yAxisName: string;
}

interface PopupData {
    epochTime?: number;
    value?: number;
    units?: string;
    text?: string[];
    backgroundX?: number;
    backgroundY?: number;
    textX?: number;
    textY?: number;
    visible?: boolean;
}

export const Chart: React.FunctionComponent<Props> = ({ datasets, dataset, prefix, scale, xAxisName, yAxisName }) => {
    const [popupData, setPopupData] = useState<PopupData>({});
    const [timeoutId, setTimeoutId] = useState<any>();

    function getDataset(name: string) {
        return datasets?.datasets?.filter(dataset => dataset.name === name)[0];
    }

    const days = getDataset("Day")?.data;

    const dailyValues = getDataset(dataset)?.data;

    const maxValue = dailyValues?.reduce((previous, current) => current > previous ? current : previous, 0);

    const maxRange = nice(maxValue, false);

    const tickSpacing = maxRange / numberOfTicks;

    // logger.log("@@@@ maxValue", maxValue, maxRange, tickSpacing);

    var ticks = [];
    for (var i = 0; i <= numberOfTicks; i++) {
        const yValue = i * tickSpacing / maxRange * maxBarHeight;

        const y = maxBarHeight - yValue;

        ticks.push(
            <text key={i} className="DatasetChart__value" x={yAxisWidth - 5} y={y}>{i * tickSpacing}</text>
        );
    }

    const popupStart = (event: MouseEvent, epochTime: number, value: number, units: string | undefined, x: number, y: number) => {
        const boundedX = boundCentre(x, popupWidth, yAxisWidth, chartWidth);
        const boundedY = bound(y - popupHeight - popupGap, 0, chartHeight);

        const data: PopupData = {
            epochTime,
            value,
            units,
            backgroundX: boundedX - popupWidth / 2,
            backgroundY: boundedY,
            textX: boundedX,
            textY: boundedY + 2 * popupGap,
        };

        const timeoutId = setTimeout(
            () => popupShow(data), 
            popupTimeout
        )

        setTimeoutId(timeoutId);
    }

    const popupShow = (popupData: PopupData) => {
        const { epochTime, value, units } = popupData;

        const dateText = DateTime.fromMillis(epochTime! * 1000).setZone("Australia/Sydney").toLocaleString();

        const valueText = metricFormat(value, { unit: units, significantDigits: 3 })!;

        const text = [ dateText, valueText ];

        setPopupData({ ...popupData, text, visible: true});
    }

    const popupCancel = (event: MouseEvent) => {
        clearTimeout(timeoutId);

        setPopupData({});
    }

    const startDay = days?.length && DateTime.fromMillis(days[0] * 1000).setZone("Australia/Sydney").plus({ hours: maxTimezoneOffset }).day;

    const lastDay = days?.length && DateTime.fromMillis(days[days.length - 1] * 1000).setZone("Australia/Sydney").plus({ hours: maxTimezoneOffset }).day;

    const lastDayOfMonth = days?.length && DateTime.fromMillis(days[days.length - 1] * 1000).setZone("Australia/Sydney").set({ day: 1}).plus({ month: 1}).minus({ day: 1}).plus({ hours: maxTimezoneOffset }).day;

    const barsBefore = !startDay ? [] : dayArray.slice(0, startDay - 1).map(
        (day, index) => {
            return (
                <text key={index} className="DatasetChart__day" x={yAxisWidth + (day - 1) * barStride + barWidth / 2} y={maxBarHeight + fontHeight}>{day}</text>
            );
        }
    );

    const bars = days?.map(
        (epochTime, index) => {
            const day = DateTime.fromMillis(epochTime * 1000).setZone("Australia/Sydney").plus({ hours: maxTimezoneOffset }).day;

            const barHeight = dailyValues![index] / maxRange * maxBarHeight;

            const y = maxBarHeight - barHeight;

            const popupX = yAxisWidth + index * barStride + barWidth / 2;

            // logger.log(day, dailyValues[index], barHeight);

            return (
                <g key={index}>
                    <rect className="DatasetChart__bar" x={yAxisWidth + (day - 1) * barStride} y={y} rx={barRadius} ry={barRadius} width={barWidth} height={barHeight} onMouseEnter={(e) => popupStart(e, epochTime, dailyValues![index], getDataset(dataset)?.type, popupX, y)} onMouseLeave={(e) => popupCancel(e)}/>
                    <text className="DatasetChart__day" x={yAxisWidth + (day - 1) * barStride + barWidth / 2} y={maxBarHeight + fontHeight}>{day}</text>
                </g>
            )
        }
    );

    const barsAfter = lastDay && lastDayOfMonth && lastDay < lastDayOfMonth ? dayArray.slice(lastDay, lastDayOfMonth!).map(
        (day, index) => {
            return (
                <text key={index} className="DatasetChart__day" x={yAxisWidth + (day - 1) * barStride + barWidth / 2} y={maxBarHeight + fontHeight}>{day}</text>
            );
        }
    ) : [];

    // logger.log("@@@@ popupData", popupData)

    if (!dailyValues) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 " + chartWidth + " " + chartHeight} width="100%" height="50%"></svg>
        );
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 " + chartWidth + " " + chartHeight}>
            <rect className={bemName("DatasetChart", "background")} x="0" y="0" width={chartWidth} height={chartHeight}/>
            {ticks}
            <text className="DatasetChart__yAxisLabel" x={yAxisWidth - barWidth / 2 - barGap} y={fontHeight}>{yAxisName}</text>
            {barsBefore}
            {bars}
            {barsAfter}
            <text className="DatasetChart__xAxisLabel" x={yAxisWidth - barWidth / 2 - barGap} y={maxBarHeight + fontHeight}>{xAxisName}</text>
            <g className={bemName("DatasetChart", "popup", popupData.visible ? "visible" : "hidden")}>
                <rect className={bemName("DatasetChart", "popupBackground")} x={popupData.backgroundX} y={popupData.backgroundY} rx={barRadius} ry={barRadius} width={popupWidth} height={popupHeight}/>
                {
                    popupData.text?.map(
                        (text, index) => (
                            <text key={index} className={bemName("DatasetChart", "popupText")} x={popupData.textX} y={popupData.textY! + index * popupTextHeight}>{text}</text>
                        )
                    )
                }
            </g>
        </svg>
    );
}
