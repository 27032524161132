import { withComponentName } from "../util/bemName";
import "./Breadcrumbs.scss";

import { ReactComponent as ProgressMiddle } from "../res/boarder/progress-middle.svg";

export type Breadcrumb = {
    type: "past" | "current" | "future";
    text: string;
    to?: string;
}

type BreadcrumbsProps = {
    breadcrumbs: Breadcrumb[];
}

const componentName = "Breadcrumbs";
const bemClass = withComponentName(componentName);

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
    const crumbElements =
        breadcrumbs
            .map(
                (crumb, index) => {
                    const nextCrumb = breadcrumbs[index + 1];

                    return (
                        <div id={`${index}`} key={index} className={bemClass("crumb", crumb.type)}>
                            <div key={`text-${index}`} className={bemClass("crumbText", crumb.type, index === 0 ? "leftMost" : index === breadcrumbs.length - 1 ? "rightMost" : undefined)}>
                                {crumb.text}
                            </div>
                            {
                                index < breadcrumbs.length - 1 &&
                                <div key={`middle-${index}`} className={bemClass("separator", `${crumb.type}Left`, nextCrumb ? `${nextCrumb.type}Right` : undefined)}>
                                    <ProgressMiddle />
                                </div>
                            }
                        </div>
                    )
                }
            );

    return (
        <div className={bemClass()}>
            {crumbElements}
        </div>
    );
}