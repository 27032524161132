import { useQuery } from "react-query";
import { DefaultService as GenerationService } from '../api/SolarCloudApi';
import { errorHandler } from "./Error";

// GenerationApi.BASE = "";
// GenerationApi.TOKEN = getAccessToken;

export const usePowerGenerationInfo = () => {
    const query = useQuery(
        "powerGenerationInfo",
        async () => {
            const powerGenerationInfo = await GenerationService.powerGenerationInfo();

            return powerGenerationInfo;
        },
        {
            onError: errorHandler,
        }
    );

    return query;
}
