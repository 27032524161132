import React, { ChangeEvent, useRef } from "react";
import { withComponentName } from "../util/bemName";

import "./Input.scss";

const componentName = "Input";

export type InputFormatMap = {
    [char: string]: RegExp;
};

const defaultInputFormatMap = {
    "#": /[0-9]/,
}

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    error?: boolean;
    format?: string;
    formatMap?: InputFormatMap;
    [key: string]: any;
}

const parseIntParameter = (value: any) => {
    if (value === undefined || value === null) {
        return undefined;
    }

    if (typeof value === "number" || typeof value === "bigint") {
        return value;
    }

    if (typeof value === "string") {
        return parseInt(value);
    }

    return undefined;
}

interface Selection {
    direction: "forward" | "backward" | "none";
    start: number;
    end: number;
}

const selectionFromInput = (input: HTMLInputElement): Selection | undefined => {
    if (!input || !input.selectionDirection || !input.selectionStart || !input.selectionEnd) {
        return undefined;
    }

    return {
        direction: input.selectionDirection,
        start: input.selectionStart,
        end: input.selectionEnd,
    };
}

interface NewValueAndSelection {
    newValue?: string;
    newSelection?: Selection;
}

const formatValue = (
    value: string, 
    format?: string, 
    formatMap: InputFormatMap = defaultInputFormatMap,
    selection?: Selection,
): NewValueAndSelection => {
    if (!format) {
        return {
            newValue: undefined,
            newSelection: undefined,
        };
    }

    var cursorStart = selection?.start;
    var cursor = cursorStart;

    var newValue = "";
    for (var formatIndex = 0, valueIndex = 0; formatIndex < format.length && valueIndex < value.length; ++formatIndex) {
        const formatChar = format[formatIndex];
        var valueChar = value[valueIndex];

        const regex = formatMap[formatChar];

        if (regex) {
            while (valueIndex < value.length && !regex.test(valueChar)) {
                ++valueIndex;
                valueChar = value[valueIndex];

                if (cursor && cursor > valueIndex) {
                    --cursor;
                }
            }
            if (valueIndex < value.length) {
                newValue += valueChar;
                ++valueIndex;             
            }
        } else {
            newValue += formatChar;

            if (cursor && cursor > valueIndex) {
                ++cursor;
            }
        }
    }

    var newSelection: Selection | undefined = 
        cursor ? 
            {
                direction: "none",
                start: cursor,
                end: cursor,
            }
            :
            undefined;

    return {
        newValue: newValue,
        newSelection: newSelection,
    }
}

export const Input = ({ error, min, max, format, regex, value, onChange, ...props }: InputProps) => {
    const minValue = parseIntParameter(min);
    const maxValue = parseIntParameter(max); 

    const inputRef = useRef<HTMLInputElement>(null);

    const blockNames: string[] = [
        props.className,
        componentName,
    ].filter(
        name => typeof (name) === "string"
    ) as string[];

    const modifiers: string[] = [
        error && `error`,
    ].filter(
        name => typeof (name) === "string"
    ) as string[];

    const bemClass = withComponentName(blockNames);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const selection = selectionFromInput(event.target);

        const { newValue, newSelection } = formatValue(
            value, 
            format, 
            regex, 
            selection
        );

        if (newValue) {
            event.target.value = newValue;
        }

        if (newSelection) {
            inputRef.current?.setSelectionRange(newSelection.start, newSelection.end);
        }
    
        onChange?.(event);
    }

    if (props.type === "number") {
        const update = (change: number) => {
            if (inputRef.current) {
                const value = parseInt(inputRef.current.value);

                var newValue = value ? value + change : (minValue || 1);

                if (minValue && newValue < minValue) {
                    newValue = minValue;
                }

                if (maxValue && newValue > maxValue) {
                    newValue = maxValue;
                }

                if (newValue === value) {
                    return;
                }

                const event = {
                    target: {
                        value: newValue.toString(),
                    },
                };

                onChange?.(event as ChangeEvent<HTMLInputElement>);
            }
        }

        return (
            <div className={bemClass("container", ...modifiers)}>
                <button className={bemClass("decrement")} onClick={event => update(-1)}>-</button>
                <input 
                    {...props} 
                    ref={inputRef} 
                    className={bemClass(undefined, ...modifiers) + " " + bemClass("number")}
                    value={value}
                    onChange={handleChange}
                />
                <button className={bemClass("increment")} onClick={event => update(+1)}>+</button>
            </div>
        );
    }

    return (
        <input 
            {...props} 
            ref={inputRef} 
            className={bemClass(undefined, ...modifiers) + (props.className ? ` ${props.className}` : "")} 
            value={value}
            onChange={handleChange}
        />
    );
}