import { useQuery } from "react-query";
import { CountryInfo } from "../api/SolarCloudApi";
import { useEnvironmentQuery } from "./Environment";
import { QuerySummary } from "./QuerySummary";
import { useUuid } from "./Utils";

export const useGeoCountryQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();
    
    const { data: environment } = useEnvironmentQuery();
    
    const query = useQuery(
        "countryCode",
        async () => {
            if (!environment?.ipgeolocation?.url) {
                throw new Error("ipgeolocation.url is missing from the environment configuration");
            }
    
            const response = await fetch(environment?.ipgeolocation?.url);
            const json = await response.json();
    
            const country: CountryInfo = {
                code: json.country_code2,
                name: json.country_name,
            };

            return country;
        },
        {
            staleTime: Infinity,
        }
    );

    querySummary?.updateQuery(id, "geoCountryCode", query);

    return query;
}
