/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PhoneNumberType {
    BUSINESS = 'Business',
    DEFAULT = 'Default',
    HOME = 'Home',
    MOBILE = 'Mobile',
}
