import { useEffect, useState } from "react";
import { bemName } from "../util/bemName";
import { v4 as uuid } from "uuid";
import { Subject } from "rxjs";
import { Message as MessageElement } from "./Message";

import "./Messages.scss";

export enum MessageType {
    Informational = "informational",
    Warning = "warning",
    Error = "error",
    Critical = "critical",
}

export interface Message {
    id: string;
    type?: MessageType;
    header?: string;
    content?: string;
    timeout?: number;
}

interface MessageWithTimeoutId extends Message {
    timeoutId?: any;
}

let messages: MessageWithTimeoutId[] = [];

const messagesSubject = new Subject<Message[]>();

export const addMessage = (message : Message) => {
    messages = messages.filter(
        element => 
            element.type !== message.type ||
            element.content !== message.content ||
            element.header !== message.header
    );

    messages.push(message);

    messagesSubject.next(messages);

    if (message.timeout) {
        (message as MessageWithTimeoutId).timeoutId = setTimeout(() => removeMessage(message.id), message.timeout);
    }
};

export const info = (content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id: uuid(),
            type: MessageType.Informational,
            header,
            content,
        }
    );
}

export const infoWithId = (id: string, content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id,
            type: MessageType.Informational,
            header,
            content,
        }
    );
}

export const infoWithTimeout = (timeout: number, content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id: uuid(),
            type: MessageType.Informational,
            header,
            content,
            timeout,
        }
    );
}

export const warn = (content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id: uuid(),
            type: MessageType.Warning,
            header,
            content,
        }
    );
}

export const warnWithId = (id: string, content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id,
            type: MessageType.Warning,
            header,
            content,
        }
    );
}

export const warnWithTimeout = (timeout: number, content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id: uuid(),
            type: MessageType.Warning,
            header,
            content,
            timeout,
        }
    );
}

export const error = (content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id: uuid(),
            type: MessageType.Error,
            header,
            content,
        }
    );
}

export const errorWithId = (id: string, content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id,
            type: MessageType.Error,
            header,
            content,
        }
    );
}

export const errorWithTimeout = (timeout: number, content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id: uuid(),
            type: MessageType.Error,
            header,
            content,
            timeout,
        }
    );
}

export const critical = (content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id: uuid(),
            type: MessageType.Critical,
            header,
            content,
        }
    );
}

export const criticalWithId = (id: string, content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id,
            type: MessageType.Critical,
            header,
            content,
        }
    );
}

export const criticalWithTimeout = (timeout: number, content: string, header?: string, icon?: string) => {
    addMessage(
        {
            id: uuid(),
            type: MessageType.Critical,
            header,
            content,
            timeout,
        }
    );
}

export const removeMessage = (id: string) => {
    const oldMessage = messages.find(element => element.id === id);

    if (oldMessage) {
        messages = messages.filter(element => element.id !== id);

        if (oldMessage.timeoutId) {
            clearTimeout(oldMessage.timeoutId);
        }

        messagesSubject.next(messages);
    }
};

export const Messages = () => {
    const [ , setMessages ] = useState<Message[]>([]);

    useEffect(
        () => {
            messagesSubject
            .asObservable()
            .subscribe(
                () => {
                    setMessages(messages);
                }
            );
        },
        []
    );

    const messageElements = messages?.map(
        (message) => {
            return (
                <div key={message.id} className={bemName("Messages", "message")}>
                    <MessageElement
                        type={message.type}
                        header={message.header}
                        content={message.content}
                        onDismiss={() => removeMessage(message.id)}
                    />
                </div>
            );
        }
    );

    return (
        <div className={bemName("Messages")}>
            {messageElements}
        </div>
    );
}
