/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TotalType {
    CO2 = 'CO2',
    CARS = 'Cars',
    ENERGY = 'Energy',
    INCOME = 'Income',
    KMS = 'Kms',
    SAVINGS = 'Savings',
    WATT_CAPACITY = 'WattCapacity',
}
