import React from "react";
import { Total } from "../api/SolarCloudApi";

import { ReactComponent as CarIcon } from "../res/icons/Car.svg";
import { ReactComponent as CloudIcon } from "../res/icons/Cloud.svg";
import { ReactComponent as GraphIcon } from "../res/icons/Graph.svg";
import { currencyFormat, metricFormat } from "./formats";

export type Criteria = {
    type: string,
    period: string,
    title: string,
    currency?: boolean,
    unit?: string,
    multiplier?: number,
    icon?: React.ReactElement,
};

export const savingsCriteria: Criteria[] = [
    { type: "Income", period: "Forever",     title: "Total income", currency: true, icon: <GraphIcon /> },
    { type: "Income", period: "LastQuarter", title: "Last Quarter",  currency: true, icon: <GraphIcon /> },
    { type: "Income", period: "ThisQuarter", title: "This Quarter",  currency: true, icon: <GraphIcon /> },
    // { type: "Income", period: "LastMonth",   title: "Last Month",    currency: true, icon: <GraphIcon /> },
    { type: "Income", period: "ThisMonth",   title: "This Month",    currency: true, icon: <GraphIcon /> },
];

export const energyCriteria: Criteria[] = [
    { type: "WattCapacity", period: "Month", title: "Active",            unit: "W",      icon: <GraphIcon /> },
    { type: "Income",       period: "Month", title: "This month",      currency: true, icon: <GraphIcon /> },
//  { type: "Energy",       period: "Month", title: "Energy generated",  unit: "Wh",     multiplier: 1000, icon: <GraphIcon /> },
    { type: "Cars",         period: "Month", title: "Cars off the road",                 icon: <CarIcon /> },
    { type: "CO2",          period: "Month", title: "CO2 Saved",         unit: "kg",     icon: <CloudIcon /> },
];

export const selectTotal = (type: string, period: string, totals: Total[] | undefined) => {
    const total = 
        totals
        ?.filter(
            total => total.type === type && total.period === period
        );

    return total && total.length !== 0 ? total[0] : undefined;
}

const selectCriteria = (type: string, period: string, criterias: Criteria[]) => {
    const criteria = 
        criterias
        .filter(
            criteria => criteria.type === type && criteria.period === period
        );

    return criteria.length !== 0 ? criteria[0] : undefined;
}

export const getFormattedTotal = (type: string, period: string, totals: Total[] | undefined, criterias: Criteria[]) => {
    const total = selectTotal(type, period, totals);
    const criteria = selectCriteria(type, period, criterias);

    if (total === undefined || total.value === undefined || criteria === undefined) {
        return "—";
    }

    if (criteria.currency) {
        return currencyFormat(total.value, { fractionalDigits: 0, excludeCurrencyId: true });
    } else if (!criteria.unit) {
        return Intl.NumberFormat().format(total.value);
    }

    const rawValue = criteria.multiplier ? total.value * criteria.multiplier : total.value;

    return metricFormat(rawValue, { significantDigits: 3, unit: criteria.unit! });
}