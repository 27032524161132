import { useQuery } from "react-query";
import { apiServer } from "../util/apiServer";
import { doFetch } from "../util/fetch";
import { errorHandler } from "./Error";
import { QuerySummary } from "./QuerySummary";
import { useUuid } from "./Utils";

export type Feature = "ShowName" | "Signup" | "BuyPanels";

export const hasFeature = (features?: Array<Feature>, feature?: Feature | Array<Feature>) => {
    if (!features) {
        return false;
    }

    if (!feature) {
        return true;
    }

    if (Array.isArray(feature)) {
        const intersection = features.filter(f => feature.includes(f));

        return intersection.length !== 0;
    } else {
        return features.includes(feature);
    }
}

export type Environment = {
    awsExports: any;
    defaults: {
        panels: string;
    };
    paypal: {
        clientId: string;
    };
    stripe: {
        id: string;
    };
    google: {
        apiKey: string;
    };
    ipgeolocation: {
        url: string;
    };
    features?: Array<Feature>;
    hasFeature: (feature: Feature) => boolean;
};

export const useEnvironmentQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();
    
    const query = useQuery(
        "environment",
        async () => {
            const response = await doFetch(`${apiServer ?? ""}/api/config/env`);
        
            const environment = await response.json() as Environment;

            if (environment && environment.features === undefined) {
                environment.features = [];
            }

            return { 
                ...environment, 
                hasFeature: 
                    (feature: Feature | Array<Feature> | undefined) => {
                        return hasFeature(environment.features, feature);
                    },
            };
        },
        {
            staleTime: Infinity,
            onError: errorHandler,
        }
    );

    querySummary?.updateQuery(id, "environment", query);

    return query;
}
