import { Page } from "../components/Page";
import { ChangePasswordForm } from "../forms/ChangePasswordForm";

import "./ChangePasswordPage.scss";

export const ChangePasswordPage = () => {
    return (
        <Page title="Change password">
            <ChangePasswordForm />
        </Page>
    );
}

export default ChangePasswordPage;
