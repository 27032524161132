import { useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import GoogleMap from "google-map-react";

import { Panel } from "../components/Panel";

import "./LocationsPanel.scss";
import { bemName } from "../util/bemName";
import { ReactComponent as MarkerIcon } from "../res/logos/solarcloud/logo-icon.svg";
import { useEnvironmentQuery } from "../hooks/Environment";
import { ErrorBoundary } from "react-error-boundary";

const Marker = ({ blockName }: any) => {
    return <div className={bemName(blockName, "marker")}><MarkerIcon/></div>
}

const componentName = "LocationsPanel";

interface Props {
    blockName?: string;
}

export const LocationsPanel: React.FunctionComponent<Props> = ({ blockName }) => {
    const [center, ] = useState({ lat: -32.614457, lng: 149.573329 });
    const [zoom, ] = useState(11);
    const { data: environment } = useEnvironmentQuery();

    const block = blockName || componentName;
    
    return (
        <Panel title="Locations">
            <div className={bemName(block)}>
            {
                environment && 
                <ErrorBoundary fallback={<div className={bemName(block, "fallback")}>Sorry, there was a problems loading the location map. Please try again at a later time.</div>}>
                    <Wrapper apiKey={environment.google.apiKey}>
                        <GoogleMap
                            bootstrapURLKeys={{ key: environment.google.apiKey }}
                            defaultCenter={center}
                            defaultZoom={zoom}
                        >
                            <Marker blockName={block} lat={-32.614457} lng={149.573329} label={"Mudgee"} style={{ color: "black" }}/>
                        </GoogleMap>
                    </Wrapper>
                </ErrorBoundary>
            }
            </div>
        </Panel>
    )
}

export default LocationsPanel;
