import CalculatorPanel from "../panel/CalculatorPanel";
import { bemName } from "../util/bemName";

export function CalculatorPage() {
    return (
        <div className={bemName("CalculatorPage", "page")}>
            <CalculatorPanel />
        </div>
    );
}

export default CalculatorPage;
