/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrderStatus {
    CANCEL_PENDING = 'CancelPending',
    CANCELED = 'Canceled',
    COMPLETE = 'Complete',
    DEPOSIT_TAKEN = 'DepositTaken',
    DRAFT = 'Draft',
    HOLD_TAKEN = 'HoldTaken',
    INSTALLATION_PENDING = 'InstallationPending',
    PAID_IN_FULL = 'PaidInFull',
    PARTIAL_FULFILLMENT = 'PartialFulfillment',
    PARTIAL_PAYMENT = 'PartialPayment',
    PAYMENT_PENDING = 'PaymentPending',
    SUBMITTED = 'Submitted',
}
