import React, { KeyboardEventHandler } from "react";
import LoadingPage from "../pages/LoadingPage";
import bag from "../res/images/bag.png";
import { ReactComponent as Logo } from "../res/logos/solarcloud/logo.svg";
import { bemName } from "../util/bemName";
import "./Page.scss";
import { Feature, useEnvironmentQuery } from "../hooks/Environment";

const componentName = "Page";

interface Props {
  id?: string;
  key?: string;
  title?: string;
  isLoading?: boolean;
  blockName?: string;
  isFromBuyPanelPage?: boolean;
  requiredFeature?: Feature | Array<Feature>;
  onEnter?: () => Promise<void> | void;
  children?: React.ReactNode | undefined;
  addToBagCount?:number | undefined
}

export const Page: React.FunctionComponent<Props> = ({
  id,
  key,
  title,
  isLoading,
  requiredFeature,
  onEnter,
  children,
  isFromBuyPanelPage,
  addToBagCount,
  ...props
}) => {
  const { data: environment } = useEnvironmentQuery();

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (onEnter) {
      // logger.log("@@@@ handleKeyDown", event);

      if (event.key === "Enter") {
        onEnter();
      }
    }
  };

  const blockName = props.blockName || componentName;

  if (isLoading) {
    return <LoadingPage />;
  }
  
  // @@@@ this is probably wrong
  let showAddToBagCount=addToBagCount
  if(window.location.pathname==='/buypanels/payment-acknowledge' || window.location.pathname==='/buypanels/profile' || window.location.pathname==='/buypanels/complete'){
    console.log("windpw",window.location.pathname)
    showAddToBagCount=0
  }

  const hasFeatures = environment?.hasFeature(requiredFeature) !== false;

  return (
    <div className={bemName(blockName, "page")} onKeyDown={handleKeyDown}>
      {
          isFromBuyPanelPage && (
          <div className={bemName(blockName, "bagcontainer")}>
            <img src={bag} height={16} width={16} alt="bag" />
            <div className={bemName(blockName, "bagcontainertext")}>My bag</div>
            <div className={bemName(blockName, "bagcontainertext2")}>{showAddToBagCount}</div>
          </div>)
      }
      <div className={bemName(blockName, "container")}>
        <div className={bemName(blockName, "box")}>
          <Logo className={bemName(blockName, "logo")} />
          {title && <div className={bemName(blockName, "title")}>{title}</div>}
          <div className={bemName(blockName, "content")}>
            {
              !hasFeatures &&
              <div className={bemName(blockName, "unavailable")}>
                <p>This feature is currently unavailable</p>
              </div>
            }
            {
              hasFeatures &&
              children
            }
          </div>
        </div>
      </div>
    </div>
  );
};
