import { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { OrderItem } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import { Input } from "../components/Input";
import { useBuyPanelsQuery } from "../hooks/LocalStorage";
import {
  useCatalogQuery,
  useIsNewOrderUpdatingQuery,
  useNewOrderMutation,
  useNewOrderQuery,
} from "../hooks/Order";
import { useQuerySummary } from "../hooks/QuerySummary";
import { withComponentName } from "../util/bemName";
import {
  BILL_SYSTEMSIZE_RATIO,
  CostingCalculations,
  CostingOptions,
  WATTS_PER_PANEL,
} from "../util/calculator";
import {
  currencyFormat
} from "../util/formats";
import { OrderSummaryForm } from "./OrderSummaryForm";

import EnjoyFlexibility from "../components/EnjoyFlexibility";
import GotQuestion from "../components/GotQuestion";
import ReferAFriend from "../components/ReferAFriend";
import { FormControl, NavigationButtons } from "../panel/BuyPanelsPanel";
import "./BuyPanelsForm.scss";
import { PaymentTypes } from "./PaymentTypes";
import { TestimonialForm } from "./TestimonialForm";

// const isDisplayable = (value: number | undefined) => {
//   return value !== undefined;
// };

// enum PaymentType {
//   Off = "off",
//   Apple = "apple",
//   Google = "google",
//   Mint = "mint",
//   PayPal = "paypal",
//   Contact = "contact",
// }

type BuyPanelsFormProps = {
  currentPanels?: number;
  orderPanels?: number;

  updating: boolean;
  readOnly: boolean;

  energyBill?: number;
  editEnergyBill?: () => void;
  clearEnergyBill?: () => void;
  addToBagCallBck:(count:number)=>void;

  formControl: FormControl;

  onChange?: (panels: number | undefined) => void;
};

const componentName = "BuyPanelsForm";
const bemClass = withComponentName(componentName);

const useBuyPanelsFormController = (props: BuyPanelsFormProps) => {
  const {
    currentPanels,
    energyBill,
    formControl,
  } = props;

  const querySummary = useQuerySummary();
  const { data: buyPanels } = useBuyPanelsQuery(querySummary);
  const { data: newOrder } = useNewOrderQuery(querySummary);
  const { mutate: updateNewOrder } = useNewOrderMutation(querySummary);
  const { data: isNewOrderUpdating } = useIsNewOrderUpdatingQuery(
    newOrder,
    querySummary
  );
  const { data: catalog } = useCatalogQuery(querySummary);

  const isLoading = querySummary.get().isLoading;
  const updating = isLoading || isNewOrderUpdating;

  useEffect(
    () => {
      if (isLoading) {
        return;
      }

      buyPanels && updatePanels(buyPanels);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, buyPanels]
  );

  const updatePanels = (panels: number) => {
    const orderItem = newOrder?.items && newOrder.items[0];
    if (!Number.isNaN(panels) && orderItem) {
      updateOrderItem(panels, orderItem);
    }
  };

  const updateOrderItem = (newQuantity: number, orderItem: OrderItem): void => {
    const existingItems: OrderItem[] = newOrder?.items || [];
    const isExistingItem: boolean =
      existingItems.filter(
        (item) => item.product?.uuid === orderItem.product?.uuid
      ).length === 1;
    const additionalItems: OrderItem[] = isExistingItem
      ? []
      : [{ ...orderItem }];
    const updatedItems: OrderItem[] = existingItems
      .concat(additionalItems)
      .map((item) => {
        if (item.uuid === orderItem.uuid) {
          return {
            ...item,
            units:
              newQuantity !== -1
                ? newQuantity * item.product!.wattPanelSize!
                : -1,
          };
        } else {
          return item;
        }
      })
      .filter((item) => item.units !== -1);

    const updatedNewOrder = {
      ...newOrder,
      correlationId: uuid(),
      items: updatedItems,
    };

    updateNewOrder(updatedNewOrder);
  };

  const orderItem = newOrder?.items && newOrder.items[0];

  // console.log(`@@@@ energyBill:${energyBill}`);

  const costingOptions: CostingOptions | undefined =
    orderItem?.unitPrice && orderItem?.unitDeposit
      ? new CostingOptions(
        BILL_SYSTEMSIZE_RATIO,
        WATTS_PER_PANEL,
        orderItem.unitPrice * WATTS_PER_PANEL,
        orderItem.unitDeposit * WATTS_PER_PANEL
      )
      : undefined;

  const calculations = costingOptions
    ? new CostingCalculations(
      costingOptions,
      energyBill,
      orderItem!.units! / orderItem!.product!.wattPanelSize!,
      buyPanels
    )
    : undefined;

  return {
    ...props,
    currentPanels,
    updatePanels,
    calculations,
    newOrder,
    catalog,
    querySummary,
    updating,
    formControl,
  };
};

export const BuyPanelsForm = (props: BuyPanelsFormProps) => {
  const {
    updatePanels,
    calculations,
    newOrder,
    querySummary,
    updating,
    formControl,
    addToBagCallBck
  } = useBuyPanelsFormController(props);

  const { bemModifiers } = querySummary.get();

  const orderItem = newOrder?.items && newOrder.items[0];
  const orderPanels =
    orderItem && orderItem!.units! / orderItem!.product!.wattPanelSize!;

  // const savingsPercentageElement =
  //     savingsPercentage !== undefined ?
  //         <>{percentageFormatter(savingsPercentage)} %<br />saving</>
  //         :
  //         "—";

  // const showPanelPricing = newOrder?.totalAvailable ? true : false;
  // const showDepositPricing = newOrder?.totalDeposit ? true : false;

  // logger.log("@@@@", "updating", updating);
  // logger.log("@@@@ catalog", catalog);

    useEffect(
        () => addToBagCallBck(orderPanels as number),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

const addToBagFun=(count:any | undefined)=>{
    addToBagCallBck(count)
}

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

  const Order = ({addToBag}:any) => {
    return (
      <div className={bemClass("order")}>
        <div className={bemClass("orderItemContainer")}>
          <div className={bemClass("orderItemHeaders")}>
            <div className={bemClass("orderItemHeader", "quantity")}>
              Quantity
            </div>
            <div className={bemClass("orderItemHeader", "capacity")}>
              Kilowatts
              {/* <div className={bemClass("orderItemHeader", "capacity", "units")}>Watts</div> */}
            </div>
            <div className={bemClass("orderItemHeader", "price")}>
              Investment
            </div>
          </div>
          <div className={bemClass("orderItems")}>
            <div className={bemClass("orderItem", "quantity")}>
              <Input
                type="number"
                value={orderPanels ?? ""}
                onChange={(event) => {
                  updatePanels(parseInt(event.target.value))
                  addToBag(parseInt(event.target.value))

                }}
                className={bemClass("orderItem", "inputQuantity")}
              />
            </div>
            <div className={bemClass("orderItem", "capacity")}>
              {orderItem && orderItem?.units!/1000}
            </div>
            <div className={bemClass("orderItem", "price")}>
              {orderItem &&
                currencyFormat(orderItem!.units! * orderItem!.unitPrice!, {
                  excludeCurrencyId: true,
                })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Preselections = ({addToBag}:any)=> {
    return (
      <div className={bemClass("preselectionsContainer")}>
        <div className={bemClass("preselections")}>
          <Button
            className={bemClass("preselection")}
            bordered
            onClick={() =>{
              updatePanels(4)
              addToBag(4)
              scrollToTop();


            }}
          >
            <div className={bemClass("preselection", "title")}>Starter</div>
            <div className={bemClass("preselection", "panels")}>4 panels</div>
            <div className={bemClass("preselection", "price")}>
              Only $56.90 deposit
            </div>
          </Button>
          <Button
            className={bemClass("preselection")}
            bordered
            onClick={() => {updatePanels(40)
              addToBag(40)
              scrollToTop();


            }}
          >
            <div className={bemClass("preselection", "title")}>
              Most popular
            </div>
            <div className={bemClass("preselection", "panels")}>40 panels</div>
            <div className={bemClass("preselection", "price")}>
              Only $569.00 deposit
            </div>
          </Button>
          {calculations?.energyBillCoveragePanels && (
            <Button
              className={bemClass("preselection")}
              bordered
              onClick={() =>{
                updatePanels(102)
              addToBag(102)
                // updatePanels(calculations.energyBillCoveragePanels!)
                // addToBag(calculations.energyBillCoveragePanels!)
                scrollToTop();


              }
              }
            >
              <div className={bemClass("preselection", "title")}>
                Zero your energy bill for life**
              </div>
              {/* <div className={bemClass("preselection", "panels")}>
                {calculations.energyBillCoveragePanels!} panels
              </div> */}
                <div className={bemClass("preselection", "panels")}>
                102 panels
              </div>
              <div className={bemClass("preselection", "price")}>
                {/* {currencyFormat(calculations.energyBillCoverageDeposit!, {
                  excludeCurrencyId: true,
                })}{" "} */}
                              Only $1,519.80 deposit

              </div>
            </Button>
          )}
        </div>
        <div className={bemClass("averagePanels")}>
            ** 102 panels is enough to zero the average Australian home energy bill based Canstarblue research December 2022
        </div>
      </div>
    );
  };

  return (
    <div className={bemClass("form", ...bemModifiers)}>

      <Order addToBag={addToBagFun}/>
      <OrderSummaryForm order={newOrder} updating={updating} isFromPanelScreen={true}/>
      <div className={bemClass("navButton")}>
      <NavigationButtons formControl={formControl} />
      </div>
      <EnjoyFlexibility />
      <GotQuestion />
      <PaymentTypes />
      <Preselections addToBag={addToBagFun} />
      <TestimonialForm />
      <ReferAFriend/>

    </div>
  );
}
export default BuyPanelsForm;
