import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from 'react-query';

import App from './App';
import { OpenAPI } from './api/SolarCloudApi';

import { queryClient } from './hooks/Dependencies';
import "./i18n";
import { apiServer } from './util/apiServer';

// logger.log("@@@@ apiServer", apiServer);

OpenAPI.BASE = apiServer ?? "";

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(
    <QueryClientProvider client={queryClient}>
        <App />
    </QueryClientProvider>
);