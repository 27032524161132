import { Page } from "../components/Page";
import { ResetPasswordForm } from "../forms/ResetPasswordForm";

import "./ResetPasswordPage.scss";

export const ResetPasswordPage = () => {
    return (
        <Page title="Reset password">
            <ResetPasswordForm/>
        </Page>
    );
}

export default ResetPasswordPage;
