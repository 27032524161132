import { Auth } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../components/Button";
import { FederatedLogins } from "../components/FederatedLogins";
import { Input } from "../components/Input";
import { error, infoWithTimeout } from "../components/Messages";
import { Separator } from "../components/Separator";
import ProfileContext from "../contexts/ProfileContext";
import { bemName } from "../util/bemName";
import "./ChangePasswordForm.scss";

const componentName = "ChangePasswordForm";

type Props = {
    includeFederated?: boolean;
}

export const ChangePasswordForm = ({ includeFederated }: Props) => {
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState<string>();
    const profile = useContext(ProfileContext);
    const navigate = useNavigate();

    // useEffect(
    //     () => {
    //         logger.log("@@@@ profile", profile);
    //     },
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     []
    // )

    async function handleChangePassword() {
        try {
            const cognitoUser = await Auth.signIn(profile.email!, password);

            // logger.log("@@@@ cognitoUser", cognitoUser);

            if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                const response = await Auth.completeNewPassword(cognitoUser, newPassword);

                // logger.log("@@@@ completeNewPassword response", response);
            } else {
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
                const response = await Auth.changePassword(cognitoUser, password, newPassword);

                // logger.log("@@@@ completeNewPassword response", response);
            }

            infoWithTimeout(2500, "Your password has been changed", "Password");

            navigate("/");
        } catch (err: any) {
            // logger.log("@@@@ error", err);
            error(err.body?.message || err.message);
        }
    }

    const checkError = () => {
        if (
            password !== "" && 
            (
                newPassword !== "" ||
                verifyPassword !== ""
            )
        ) {
            if (newPassword !== verifyPassword) {
                return "New passwords do not match";
            }
        }

        return undefined;
    }

    useEffect(
        () => {
            setErrorMessage(checkError());
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            password,
            newPassword,
            verifyPassword
        ]
    )

    const enableSubmit = 
        password !== "" && 
        newPassword !== "" && 
        verifyPassword === newPassword;

    return (
        <div className={bemName(componentName, "container")}>
            <div>
                {
                    includeFederated &&
                    <>
                        <FederatedLogins prefix="Change password with" />
                        <Separator text="or" />
                    </>
                }

                <div className={bemName(componentName, "requestForm")}>
                    <div className={bemName(componentName, "input")}>
                        <Input type="password" placeholder="Password" value={password} autoComplete="current-password" onChange={(event) => setPassword(event.target.value)} />
                    </div>
                    <div className={bemName(componentName, "input")}>
                        <Input type="password" placeholder="New password" value={newPassword} autoComplete="new-password" onChange={(event) => setNewPassword(event.target.value)} />
                    </div>
                    <div className={bemName(componentName, "input")}>
                        <Input type="password" placeholder="New password" value={verifyPassword} autoComplete="new-password" onChange={(event) => setVerifyPassword(event.target.value)} />
                    </div>
                    <div className={bemName(componentName, "actions")}>
                        <div className={bemName(componentName, "button")}>
                            <Button type="submit" text="Change Password" disabled={!enableSubmit} onClick={() => handleChangePassword()} />
                        </div>
                        <div className={bemName(componentName, "links")}>
                            <Link to="/login">Cancel</Link>
                        </div>
                    </div>
                    {
                        errorMessage &&
                        <div className={bemName(componentName, "message")}>{errorMessage}</div>
                    }
                </div>
            </div>
        </div>
    );
}
