import { Order } from "../api/SolarCloudApi";
import { bemName } from "../util/bemName";
import { currencyFormat } from "../util/formats";

import "./OrderSummaryForm.scss";

const componentName = "OrderSummaryForm";

type OrderSummaryFormProps = {
  order: Order | undefined;

  updating?: boolean;

  showTotalPrice?: boolean;
  isFromPanelScreen?: boolean
};

export function OrderSummaryForm({
  order: newOrder,
  updating,
  showTotalPrice,
  isFromPanelScreen
}: OrderSummaryFormProps) {
  const priceFormatted = currencyFormat(newOrder?.totalPrice, {
    excludeCurrencyId: true,
  });
  const availableFormatted = currencyFormat(newOrder?.totalAvailable, {
    excludeCurrencyId: true,
  });
  const availableTaxFormatted = currencyFormat(newOrder?.totalAvailableTax, {
    excludeCurrencyId: true,
  });
  const depositFormatted = currencyFormat(newOrder?.totalDeposit, {
    excludeCurrencyId: true,
  });
  const holdFormatted = currencyFormat(newOrder?.totalHold, {
    excludeCurrencyId: true,
  });
  const paymentFormatted = currencyFormat(newOrder?.totalPayment, {
    excludeCurrencyId: true,
  });

  const showPanelPricing = newOrder?.totalAvailable ? true : false;
  const showDepositPricing = newOrder?.totalDeposit ? true : false;

  // console.log(
  //   "@@@@ updating:",
  //   updating,
  //   new Date().getTime(),
  //   !updating ? paymentFormatted : ""
  // );

  return (
    <div className={bemName(componentName)}>
      {showPanelPricing && !showDepositPricing && (
        <div className={bemName(componentName, "title")}>
          Buy your panels today
        </div>
      )}
      {showPanelPricing && showDepositPricing && (
        <div className={bemName(componentName, "title")}>
          Buy some panels today and pay only a deposit today when the rest are
          available
        </div>
      )}
      <div className={bemName(componentName, "totalsContainer")}>
        {showTotalPrice && (
          <div
            className={bemName(componentName, "totalContainer")}
            key="total-container-01"
          >
            <div
              className={bemName(componentName, "total", "label", "price")}
            >
              Total Price
            </div>
            <div
              className={bemName(componentName, "total", "value", "price")}
            >
              {!updating ? priceFormatted : ""}
            </div>
          </div>
        )}
        {showPanelPricing && (
          <>
            <div
              className={bemName(componentName, "totalContainer")}
              key="total-container-02"
            >
              <div
                className={bemName(
                  componentName,
                  "total",
                  "label",
                  "panelPrice"
                )}
              >
                Panel Cost
              </div>
              <div
                className={bemName(
                  componentName,
                  "total",
                  "value",
                  "panelPrice"
                )}
              >
                {!updating ? availableFormatted : ""}
              </div>
            </div>
            <div
              className={bemName(componentName, "totalContainer")}
              key="total-container-03"
            >
              <div
                className={bemName(componentName, "total", "label", "tax")}
              >
                {newOrder?.taxLabel} (Panels)
              </div>
              <div
                className={bemName(componentName, "total", "value", "tax")}
              >
                {!updating ? availableTaxFormatted : ""}
              </div>
            </div>
          </>
        )}
        {showDepositPricing && !isFromPanelScreen && (
          <>
            {!showPanelPricing && showDepositPricing && (
              <div key="total-container-04" className={bemName(componentName, "totalContainer")}>
                <div className={bemName(componentName, "payOnlyText")}>Pay only a deposit today</div>
                <div
                  className={bemName(
                    componentName,
                    "total",
                    "value",
                    "payment"
                  )}
                >
                </div>
              </div>
            )}
            <div
              className={bemName(componentName, "totalContainer")}
              key="total-container-05"
            >
              <div
                className={bemName(componentName, "total", "label", "deposit")}
              >
                Deposit
              </div>
              <div
                className={bemName(componentName, "total", "value", "deposit")}
              >
                {!updating ? depositFormatted : ""}
              </div>
            </div>

            <div
              className={bemName(componentName, "totalContainer")}
              key="total-container-06"
            >
              <div
                className={bemName(componentName, "total", "label", "hold")}
              >
                Balance
              </div>
              <div
                className={bemName(componentName, "total", "value", "hold")}
              >
                {!updating ? holdFormatted : ""}
              </div>
            </div>
          </>
        )}


        {!isFromPanelScreen && (
          <div
            className={bemName(componentName, "totalContainer")}
            key="total-container-07"
          >
            <div
              className={bemName(componentName, "total", "label", "payment")}
            >
              Total pay today
            </div>
            <div
              className={bemName(componentName, "total", "value", "payment")}
            >
              {!updating ? paymentFormatted : ""}
            </div>
          </div>
        )}
      </div>
      {showDepositPricing && isFromPanelScreen && (
        <>

          {isFromPanelScreen && !showPanelPricing && showDepositPricing && (
            <>
              <div className={bemName(componentName, "totalContainerPanelsPage")}>
                <div className={bemName(componentName, "payOnlyTextLeft")}>Pay only a deposit today</div>
                <div
                  className={bemName(componentName, "depositAmount")}
                >
                  {!updating ? depositFormatted : ""}
                </div>

              </div>
              <div className={bemName(componentName, "container")}>
                <div className={bemName(componentName, "totalBalanceWrapper")}>
                  <div className={bemName(componentName, "totalPaymentdeposit")}>
                    <div className={bemName(componentName, "labelText", "depositText")}> Deposit</div>
                    <div className={bemName(componentName, "valueText", "depositValue ")}>{!updating ? paymentFormatted : ""}</div>
                  </div>
                </div>
                <div className={bemName(componentName, "totalBalanceWrapper")}>
                  <div className={bemName(componentName, "totalPaymentdeposit")}>
                    <div className={bemName(componentName, "labelText", "balanceText")}> Balance</div>
                    <div className={bemName(componentName, "valueText")}>{!updating ? holdFormatted : ""}</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {isFromPanelScreen && (
        <div
          className={bemName(componentName, "totalContainerWrapper")}
          key="total-container-09"
        >
          <div className={bemName(componentName, "totalPaymentGstWrapper")}>
            <div className={bemName(componentName, "totalText")}> Total pay today</div>
            <div className={bemName(componentName, "gstText")}>inclusive of GST</div>
          </div>
          <div className={bemName(componentName, "total", "value", "payment")}>
            {!updating ? paymentFormatted : ""}
          </div>
        </div>
      )}

    </div>
  );
}
