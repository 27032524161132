import { useState } from "react";
import { Allocation, BPayPayee, BankPayee, EnergyCompany, EnergyCompanyPayee, PayPalPayee, PayeeType } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import { Input } from "../components/Input";
import { error } from "../components/Messages";
import { Select } from "../components/Select";
import { bemName } from "../util/bemName";

import { WATTS_PER_PANEL } from "../util/calculator";
import "./AllocationForm.scss";

const componentName = "AllocationForm";

interface AllocationFormProps {
    allocation?: Allocation;
    maxAllocation?: number;
    energyCompanies?: EnergyCompany[];
    onSave: (allocation: Allocation) => Promise<void> | void;
    onCancel: () => void;
    blockName?: string;
}

const getPayeeType = (allocation?: Allocation) => {
    if (!allocation) {
      return undefined;
    }
  
    return allocation.bankPayee?.type || allocation.bpayPayee?.type || allocation.energyCompanyPayee?.type || allocation.payPalPayee?.type;
  }
  
const useAllocationForm = (props: AllocationFormProps) => {
    const [allocation, setAllocation] = useState<Partial<Allocation>>(props.allocation ? { ...props.allocation } : {});
    const [wattageError, setWattageError] = useState<string | undefined>(undefined);

    const payeeType = getPayeeType(allocation);

    console.log("@@@@ payeeType/allocation:", payeeType, allocation);

    const updatePayeeType = (payeeType: PayeeType | undefined) => {
        const currentPayeeType = getPayeeType(allocation);
        if (payeeType === currentPayeeType) {
            return;
        }

        switch (payeeType) {
        case PayeeType.BANK:
            setAllocation({ ...allocation, bankPayee: {...allocation.bankPayee, type: PayeeType.BANK}, bpayPayee: undefined, energyCompanyPayee: undefined, payPalPayee: undefined });
            break;

        case PayeeType.BPAY:
            setAllocation({ ...allocation, bankPayee: undefined, bpayPayee: {...allocation.bpayPayee, type: PayeeType.BPAY}, energyCompanyPayee: undefined, payPalPayee: undefined });
            break;
            
        case PayeeType.ENERGY_COMPANY:
            setAllocation({ ...allocation, bankPayee: undefined, bpayPayee: undefined, energyCompanyPayee: {...allocation.energyCompanyPayee, type: PayeeType.ENERGY_COMPANY}, payPalPayee: undefined });
            break;
            
        case PayeeType.PAY_PAL:
            setAllocation({ ...allocation, bankPayee: undefined, bpayPayee: undefined, energyCompanyPayee: undefined, payPalPayee: {...allocation.payPalPayee, type: PayeeType.PAY_PAL} });
            break;
        }
    };

    const updateAllocation = (update: Allocation) => {
        setAllocation({
            ...allocation,
            ...update
        });
    };

    const updatePayment = (update: BankPayee | BPayPayee | EnergyCompanyPayee | PayPalPayee) => {
        switch (payeeType) {
            case PayeeType.BANK:
                setAllocation({ ...allocation, bankPayee: { ...allocation.bankPayee, ...update, type: payeeType }, bpayPayee: undefined, energyCompanyPayee: undefined, payPalPayee: undefined });
                break;
            case PayeeType.BPAY:
                setAllocation({ ...allocation, bankPayee: undefined, bpayPayee: { ...allocation.bpayPayee, ...update, type: payeeType }, energyCompanyPayee: undefined, payPalPayee: undefined });
                break;
            case PayeeType.ENERGY_COMPANY:
                setAllocation({ ...allocation, bankPayee: undefined, bpayPayee: undefined, energyCompanyPayee: { ...allocation.energyCompanyPayee, ...update, type: payeeType }, payPalPayee: undefined });
                break;
            case PayeeType.PAY_PAL:
                setAllocation({ ...allocation, bpayPayee: undefined, bankPayee: undefined, energyCompanyPayee: undefined, payPalPayee: { ...allocation.payPalPayee, ...update, type: payeeType } });
                break;
            default:
                error(`Unknown payee type ${payeeType}`, "Internal Error");
        }
    };

    const isValid = () => {
        if (!allocation.nickname || !allocation.wattCapacity || !payeeType)
            return false;

        switch (payeeType) {
            case PayeeType.BANK:
                if (!allocation.bankPayee?.accountName || !allocation.bankPayee?.bsb || !allocation.bankPayee?.accountNumber)
                    return false;
                break;
            case PayeeType.BPAY:
                if (!allocation.bpayPayee?.billerCode || !allocation.bpayPayee?.customerReferenceNumber)
                    return false;
                break;
            case PayeeType.ENERGY_COMPANY:
                if (!allocation.energyCompanyPayee?.energyCompany || !allocation.energyCompanyPayee?.billerCode || !allocation.energyCompanyPayee?.customerReferenceNumber)
                    return false;
                break;
            case PayeeType.PAY_PAL:
                if (!allocation.payPalPayee?.email)
                    return false;
                break;
        }

        return true;
    };

    const validateWattage = (watts: number) => {
        const panels = watts / WATTS_PER_PANEL;
        const maxPanels = props.maxAllocation && props.maxAllocation / WATTS_PER_PANEL;

        if (panels <= 0) {
            setWattageError("Panels must be a positive number");
        } else if (maxPanels && panels > maxPanels) {
            setWattageError(`The maximum number of panels that can be allocated is ${maxPanels}`);
        } else {
            setWattageError(undefined);
        }
    };

    return {
        ...props,
        allocation,
        payeeType,
        updatePayeeType,
        updateAllocation,
        updatePayment,
        validateWattage,
        wattageError,
        isValid,
    };
}

export const AllocationForm = (props: AllocationFormProps) => {
    const {
        allocation,
        maxAllocation,
        payeeType,
        energyCompanies, 
        updatePayeeType,
        updateAllocation,
        updatePayment,
        validateWattage,
        wattageError,
        isValid,
        onSave, 
        onCancel, 
        blockName, 
    } = useAllocationForm(props);

    const block = blockName || componentName; 

    const energyCompanyOptions = energyCompanies?.map(
        energyCompany => (
            <option key={energyCompany.uuid} value={energyCompany.uuid}>
                {energyCompany.name}
            </option>
        )
    );

    const enableSubmit = isValid();

    const panels = allocation.wattCapacity ? allocation.wattCapacity / WATTS_PER_PANEL : "";

    const payeeTypes = Object.values(PayeeType).filter((value) => value !== PayeeType.BPAY);

    return (
        <div className={bemName(block)}>
            <Input
                placeholder="Nickname"
                value={allocation.nickname}
                onChange={e => updateAllocation({ nickname: e.target.value })}
            />
            <Input
                placeholder="Panels"
                type="number"
                value={panels}
                min={1}
                max={maxAllocation ? maxAllocation / WATTS_PER_PANEL : 0}
                onChange={e => {
                    const wattCapacity = parseInt(e.target.value, 10) * WATTS_PER_PANEL;
                    updateAllocation({ wattCapacity });
                    validateWattage(wattCapacity);
                }}
            />
            {
                wattageError && 
                <div className={bemName(block, "error")}>{wattageError}</div>
            }
            <Select
                placeholder="Please select..."
                values={payeeTypes}
                selection={payeeType}
                onSelection={type => updatePayeeType(type)}
            />
            {payeeType && payeeType === PayeeType.BANK && (
                <>
                    <Input
                        placeholder="Account name"
                        value={allocation.bankPayee?.accountName}
                        onChange={e => updatePayment({ accountName: e.target.value })}
                    />
                    <Input
                        placeholder="BSB"
                        value={allocation.bankPayee?.bsb}
                        onChange={e => updatePayment({ bsb: e.target.value })}
                    />
                    <Input
                        placeholder="Account number"
                        value={allocation.bankPayee?.accountNumber}
                        onChange={e => updatePayment({ accountNumber: e.target.value })}
                    />
                    <div className={bemName(block, "message")}>Are you sure these numbers are correct? Once submitted, the funds will be sent to this account and cannot be retrieved.</div>
                </>
            )}
            {(payeeType && payeeType === PayeeType.BPAY) && (
                <>
                    <Input
                        placeholder="Biller code"
                        value={allocation.bpayPayee?.billerCode}
                        onChange={e => updatePayment({ billerCode: e.target.value })}
                    />
                    <Input
                        placeholder="Customer reference number"
                        value={allocation.bpayPayee?.customerReferenceNumber}
                        onChange={e => updatePayment({ customerReferenceNumber: e.target.value })}
                    />
                </>
            )}
            {(payeeType && payeeType === PayeeType.ENERGY_COMPANY) && (
                <>
                    <Select<EnergyCompany>
                        placeholder="Please select..."
                        values={energyCompanies ?? []}
                        keyOf={value => value.uuid!}
                        textOf={value => value.name!}
                        selection={allocation.energyCompanyPayee?.energyCompany}
                        onSelection={selection => updatePayment({ energyCompany: selection })}
                    >
                        {energyCompanyOptions}
                    </Select>
                    <Input
                        placeholder="Biller code"
                        value={allocation.energyCompanyPayee?.billerCode}
                        onChange={e => updatePayment({ billerCode: e.target.value })}
                    />
                    <Input
                        placeholder="Customer reference number"
                        value={allocation.energyCompanyPayee?.customerReferenceNumber}
                        onChange={e => updatePayment({ customerReferenceNumber: e.target.value })}
                    />
                </>
            )}
            {payeeType && payeeType === PayeeType.PAY_PAL && (
                <Input
                    placeholder="PayPal account email"
                    value={allocation.payPalPayee?.email}
                    onChange={e => updatePayment({ email: e.target.value })}
                />
            )}
            <div className={bemName(block, "submitLine")}>
                <Button text={"OK"} disabled={!enableSubmit} onClick={() => onSave(allocation)} />
                <Button text={"Cancel"} onClick={onCancel} />
            </div>
        </div>
    );
};
