import { KeyboardEvent, useState } from "react";
import { ReactComponent as DownArrow } from "../res/icons/angles-down.svg";
import { bemName } from "../util/bemName";
import { Button } from "./Button";
import { Input } from "./Input";

import "./Dropdown.scss";

const componentName = "Dropdown";

export type Option<T> = {
    key?: string;
    name: string;
    value: T;
}

type DropdownProps<T> = {
    key?: string;

    placeholder?: string;

    value?: string;
    readOnly?: boolean;

    options?: Option<T>[];
    selection?: T;
    match?: (selection: T | undefined, option: Option<T>) => boolean;

    autoShowList?: boolean;

    onEdit?: (value: string) => void;
    onSelect?: (value: T | undefined) => void;
};

export const Dropdown = <T = string>({ key, placeholder, value, readOnly, options, selection, match, autoShowList, onEdit, onSelect }: DropdownProps<T>) => {
    const [open, setOpen] = useState<boolean>(autoShowList === true);
    const [highlight, setHighlight] = useState<number>();

    const updateValue = (value: string) => {
        onEdit && onEdit(value);
    }

    const updateSelection = (selection: T | undefined) => {
        setHighlight(undefined);

        setOpen(false);

        onSelect && onSelect(selection);
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case "ArrowDown": {
                if (highlight === undefined) {
                    setHighlight(0);
                } else if (highlight === optionElements.length - 1) {
                    setHighlight(0);
                } else {
                    setHighlight(highlight + 1);
                }

                break;
            }

            case "ArrowUp": {
                if (highlight === undefined) {
                    setHighlight(optionElements.length - 1);
                } else if (highlight === 0) {
                    setHighlight(optionElements.length - 1);
                } else {
                    setHighlight(highlight - 1);
                }

                break;
            }

            case "Enter": {
                const selection = options && highlight !== undefined && highlight < options.length ? options[highlight].value : undefined;

                updateSelection(selection);

                break;
            }

            case "Escape": {
                setOpen(false);

                break;
            }
        }
    }

    const optionElements = (options ?? []).map(
        (option, index) => {
            const highlightedModifier = index === highlight ? "highlight" : undefined;

            return (
                <button key={option.key ?? option.name} className={bemName(componentName, "listItem", highlightedModifier)} onClick={() => updateSelection(option.value)}>
                    {option.name}
                </button>
            );
        }
    );

    const hasOptions = options && options.length !== 0;
    const inputModifier = autoShowList ? "noButton" : undefined;
    const listContainerModifier = (open || autoShowList) && hasOptions ? "open" : "closed";

    // console.log(`@@@@ autoShowList:${autoShowList} open:${open} highlight:${highlight} selection:`, selection, options);

    return (
        <div key={key} className={bemName(componentName, "container")} onKeyDown={handleKeyDown}>
            <div className={bemName(componentName, "inputAndButton")}>
                <Input className={bemName(componentName, "input", inputModifier)} placeholder={placeholder} value={value} readOnly={readOnly} onChange={event => updateValue(event.target.value)} />
                {
                    (autoShowList !== true) &&
                    <Button className={bemName(componentName, "button")} onClick={() => setOpen(!open)}>
                        <DownArrow className={bemName(componentName, "icon")} />
                    </Button>
                }
            </div>
            <div className={bemName(componentName, "listContainer", listContainerModifier, autoShowList ? "noButton" : undefined)}>
                <div className={bemName(componentName, "list")}>
                    {optionElements}
                </div>
            </div>
        </div>
    );
}