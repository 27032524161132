import { ReactElement } from "react";
import { bemName } from "../util/bemName";

import "./Switch.scss";

function asArray<T>(value: T[] | T | undefined) {
    if (value === undefined || value === null) {
        return [];
    } else if (!Array.isArray(value)) {
        return [ value ];
    } else {
        return value;
    }
}

const componentName = "Switch"

interface SwitchProps {
    id?: string;
    key?: string;
    case?: string;
    blockName?: string;
    children?: ReactElement[] | ReactElement | undefined;
}

export const Switch = ({id, key, case: selectedCase, children, ...props}: SwitchProps) => {
    const blockName = props.blockName || componentName;

    // console.log("@@@@ case:", selectedCase, asArray<ReactElement>(children));

    const childArray = asArray<ReactElement>(children);

    const selectedChild = 
        childArray
            .filter(
                value => value.key === selectedCase
            )
            [0];

    const defaultChild =
        childArray
            .filter(
                value => value.key === "default"
            )
            [0];

    const child = selectedChild || defaultChild;

    return (
        <div className={bemName(blockName, "container")}>
            {child}
        </div>
    )
}