import { Panel } from "../components/Panel";
import { TrackerView } from "../components/TrackerView";

import "./TrackerPanel.scss";

interface Props {
    maxEntries?: number;
}

export const TrackerPanel: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <Panel title="Tracker">
            <TrackerView maxEvents={props.maxEntries} />
        </Panel>
    );
}

export default TrackerPanel;
