import { useState } from "react";
import { MutationFunction, QueryFunction, UseMutationOptions, UseQueryOptions, UseQueryResult, useMutation, useQuery } from "react-query";
import { v4 as uuid } from "uuid";
import { QueryName } from "./Dependencies";
import { QuerySummary } from "./QuerySummary";

export const useUuid = () => {
    const [ id ] = useState<string>(uuid());
    return id;
}

export function useIdQuery<
    TQueryFnData = unknown, 
    TError = unknown, 
    TData = TQueryFnData
>(
    name: QueryName, 
    queryFunction: QueryFunction<TQueryFnData, QueryName>,
    options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, QueryName>, 'queryKey' | 'queryFn'>,
    querySummary?: QuerySummary
): UseQueryResult<TData, TError> {
    const id = useUuid();

    const query = useQuery<TQueryFnData, TError, TData, QueryName>(
        name,
        queryFunction,
        options,
    );

    querySummary?.updateQuery(id, name, query);

    return query;
}

export function useIdMutation<
    TData = unknown, 
    TError = unknown, 
    TVariables = void, 
    TContext = unknown
>(
    name: QueryName,
    mutationFunction: MutationFunction<TData, TVariables>,
    options: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, "mutationFunction">,
    querySummary?: QuerySummary
) {
    const id = useUuid();

    const mutation = useMutation<TData, TError, TVariables, TContext>(
        mutationFunction,
        options
    );

    querySummary?.updateMutation(id, name, mutation);

    return mutation;
}
