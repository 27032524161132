/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum OrderItemStatus {
    ALLOCATED = 'Allocated',
    CANCEL_PENDING = 'CancelPending',
    CANCELLED = 'Cancelled',
    DRAFT = 'Draft',
    FULFILLED = 'Fulfilled',
    PENDING = 'Pending',
}
