/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TrackerEventType {
    AGREEMENTS = 'Agreements',
    DOCUMENTS = 'Documents',
    ORDER = 'Order',
    ORGANISATION_ALLOCATIONS = 'OrganisationAllocations',
    PAYMENT = 'Payment',
    PAYOUT = 'Payout',
    PROFILE = 'Profile',
    REFUND_ORDER = 'RefundOrder',
    REFUND_PANEL = 'RefundPanel',
    USER_ALLOCATIONS = 'UserAllocations',
}
