import React from "react";
import { bemName } from "../util/bemName";
import LoadingPanel from "../pages/LoadingPanel";

import "./Panel.scss";
import { Breadcrumb, Breadcrumbs } from "./Breadcrumbs";

const componentName = "Panel";

interface Props {
    id?: string;
    key?: string;
    title?: string;
    isLoading?: boolean;
    blockName?: string;
    defaultPadding?: boolean;
    breadcrumbs?: Breadcrumb[];
    children?: React.ReactNode | undefined;
}

export const Panel : React.FunctionComponent<Props> = ({id, key, title, isLoading, blockName, defaultPadding, breadcrumbs, children, ...props}) => {

    if (isLoading) {
        return <LoadingPanel />
    }

    const localBemName = (element?: string, ...modifiers: (string | undefined)[]): string => {
        const blockNames = blockName ? [blockName, componentName] : componentName;

        return (
            (
                defaultPadding === false ?
                    bemName(blockNames, element ? element + "NoPadding" : "noPadding", ...modifiers) + " "
                    :
                    ""
            ) +
            bemName(blockNames, element, ...modifiers)
        )
    }
    return (
        <div className={localBemName()}>
            <div className={localBemName("container")}>
                <div className={localBemName("box")}>
                    {
                        breadcrumbs &&
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                    }
                    {
                        title &&
                        <div className={localBemName("title")}>{title}</div>
                    }
                     
                    <div className={localBemName("content")}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}