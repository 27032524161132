import { DateTime } from "luxon";
import { DashboardData, DefaultService as DashboardService } from "../api/SolarCloudApi";
import { DataBox } from "../components/DataBox";
import { Chart } from "../components/DatasetChart";
import { Panel } from "../components/Panel";
import { bemName } from "../util/bemName";

import "./EnergyPanel.scss";
import { useEffect } from "react";
import { error } from "../components/Messages";
import { useState } from "react";
import { MonthSpinner } from "../components/MonthSpinner";
import { energyCriteria, getFormattedTotal } from "../util/totals";

const componentName = "EnergyPanel";

const emptyData = { datasets: [], totals: [] };

interface Props {
}

export const EnergyPanel: React.FunctionComponent<Props> = (props: Props) => {
    const [display, ] = useState<string>("energy");
    const [month, setMonth] = useState<number>(-1);
    const [year, setYear] = useState<number>(-1);
    const [data, setData] = useState<DashboardData>(emptyData);

    const init = async () => {
        const now = DateTime.now();

        setMonth(now.month);

        setYear(now.year);

        const data = await DashboardService.datsetsGet(now.month, now.year);

        setData(data);
    }

    useEffect(
        () => {
            init()
            .catch(
                (err) => {
                    // logger.log("@@@@ DashboardService error", err);
                    if (err.status !== 401) {
                        error(err.body?.message || err.message, "Energy error");
                    }
                }
            );
        },
        []
    );

    const decrementMonth = async () => {
        const monthYear = DateTime.local(year, month, 1).minus({ month: 1 });

        updateData(monthYear);
    }

    const incrementMonth = async () => {
        const monthYear = DateTime.local(year, month, 1);
        const now = DateTime.now().toLocal();
        if (monthYear.year === now.year && monthYear.month === now.month) {
            return;
        }

        const newMonthYear = DateTime.local(year, month, 1).plus({ month: 1 });

        updateData(newMonthYear);
    }

    const updateData = async (monthYear: DateTime) => {
        try {
            // logger.log("@@@@ Updating dataset data for", monthYear);

            setMonth(monthYear.month);
            setYear(monthYear.year);
    
            setData(emptyData);
    
            const data = await DashboardService.datsetsGet(monthYear.month, monthYear.year);
    
            setData(data);
        } catch (err: any) {
            error(err.body?.message || err.message, "Energy error");
        }
    }

    const monthYear = DateTime.local(year, month, 1);
    
    return (
        <Panel>
            <div className={bemName(componentName, "header")}>
                <div className={bemName(componentName, "title")}>{display === "energy" ? "Energy" : "Income"}</div>
                <div className={bemName(componentName, "spinner")}>
                    <MonthSpinner month={month} year={year} onDecrement={decrementMonth} onIncrement={incrementMonth} />
                </div>
            </div>
            <div className={bemName(componentName, "data")}>
                {
                    energyCriteria
                    .map(
                        (criteria, index) => {
                            const value = getFormattedTotal(criteria.type, criteria.period, data.totals!, energyCriteria);
                      
                        
                            return <DataBox key={index} title={criteria.title} value={value} icon={criteria.icon}/>
                        }
                    )
                }

                    
            </div>
   
            
            <div className={bemName(componentName, "chart")}>
                {
                    display === "energy" &&
                    <Chart datasets={data} dataset="Energy" prefix={false} scale={0} xAxisName="Days" yAxisName="kWh"/>
                }
                {
                    display === "income" &&
                    <Chart datasets={data} dataset="Income" prefix={true} scale={2} xAxisName="Days" yAxisName="A$" />
                }
            </div>
            <div className={bemName(componentName, "caption")}>{monthYear.monthLong} {monthYear.year}</div>
        </Panel>
    );
}

export default EnergyPanel;
