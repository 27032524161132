import { bemName } from "../util/bemName";

import "./Separator.scss";

interface Props {
    text?: string;
    zeroMargin?: boolean;
}

export function Separator(props: Props) {
    const marginModifier = props.zeroMargin ? "zeroMargin" : undefined;

    if (props.text) {
        return (
            <div className={bemName("Separator", undefined, marginModifier)}>
                <div className={bemName("Separator", "line", "pre")} />
                <div className={bemName("Separator", "text")}>{props.text}</div>
                <div className={bemName("Separator", "line", "post")} />
            </div>
        );
    } else {
        return (
            <div className={bemName("Separator", undefined, marginModifier)}>
                <div className={bemName("Separator", "line", "full")} />
            </div>
        );
    }
}