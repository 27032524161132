/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TypeEnum {
    ACCPAY = 'ACCPAY',
    ACCPAYCREDIT = 'ACCPAYCREDIT',
    ACCREC = 'ACCREC',
    ACCRECCREDIT = 'ACCRECCREDIT',
    APOVERPAYMENT = 'APOVERPAYMENT',
    APPREPAYMENT = 'APPREPAYMENT',
    AROVERPAYMENT = 'AROVERPAYMENT',
    ARPREPAYMENT = 'ARPREPAYMENT',
}
