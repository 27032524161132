export const BILL_SYSTEMSIZE_RATIO = 0.0313; // $ per kW
export const WATTS_PER_PANEL = 100;
export const COST_PER_PANEL = 149; // Dollars

export class CostingOptions {
    readonly billSystemSizeRatio: number;
    readonly wattsPerPanel: number;
    readonly costPerPanel: number;
    readonly depositPerPanel: number;

    constructor(
        billSystemSizeRatio: number,
        wattsPerPanel: number,
        costPerPanel: number,
        depositPerPanel: number,
    ) {
        this.billSystemSizeRatio = billSystemSizeRatio;
        this.wattsPerPanel = wattsPerPanel;
        this.costPerPanel = costPerPanel;
        this.depositPerPanel = depositPerPanel;
    }
    
    kilowatts(panels: number) {
        return (panels * this.wattsPerPanel) / 1000;
    }

    cost(panels: number) {
        return panels * this.costPerPanel;
    }

    deposit(panels: number): number {
        return panels * this.depositPerPanel;
    }

    quarterlyIncomePerWatt(watts: number) {
        return (watts / 1000) / this.billSystemSizeRatio;
    }
    
    quarterlyIncomePerPanel(panels: number) {
        return this.quarterlyIncomePerWatt(panels * this.costPerPanel);
    }
    
    quarterlyPanelIncome(panels: number) {
        return this.quarterlyIncomePerWatt(panels * 100);
    }
    
    quarterlyPanelCost(panels: number) {
        return (panels * this.costPerPanel) / (25 * 4);
    }
    
    quarterlyPanelSavings(panels: number) {
        return this.quarterlyPanelIncome(panels) - this.quarterlyPanelCost(panels);
    }

    estimatedIncome(panels: number): number {
        return this.quarterlyPanelIncome(panels) * (25 * 4);
    }
    
    panelsToMeetBill(energyBill: number) {
        const watts = energyBill / this.billSystemSizeRatio;
    
        return Math.ceil(watts / this.wattsPerPanel);
    }
}

export class CostingCalculations {
    readonly options: CostingOptions;

    readonly quarterlyEnergyBill?: number;
    readonly quarterlyEnergyBillCoveragePercent?: number;

    readonly currentPanels?: number;
    readonly extraPanels?: number;
    readonly totalPanels?: number;

    readonly energyBillCoveragePanels?: number;
    readonly energyBillCoverageCost?: number;
    readonly energyBillCoverageDeposit?: number;

    readonly currentKiloWatts?: number;
    readonly extraKiloWatts?: number;
    readonly totalKiloWatts?: number;

    readonly currentEstimatedQuarterlyIncome?: number;
    readonly extraEstimatedQuarterlyIncome?: number;
    readonly totalEstimatedQuarterlyIncome?: number;

    readonly totalCost?: number;
    readonly totalEstimatedIncome?: number;
    readonly totalSavings?: number;

    constructor(
        options: CostingOptions,
        quarterlyEnergyBill: number | undefined,
        currentPanels: number | undefined,
        extraPanels: number | undefined
    ) {
        this.options = options;

        this.quarterlyEnergyBill = quarterlyEnergyBill;

        this.currentPanels = currentPanels;
        this.extraPanels = extraPanels;

        this.totalPanels = this.currentPanels || this.extraPanels ? (this.currentPanels ?? 0) + (this.extraPanels ?? 0) : undefined;
        this.energyBillCoveragePanels = this.quarterlyEnergyBill ? this.options.panelsToMeetBill(this.quarterlyEnergyBill) : undefined;
        this.energyBillCoverageCost = this.energyBillCoveragePanels ? this.options.cost(this.energyBillCoveragePanels!) : undefined;
        this.energyBillCoverageDeposit = this.energyBillCoveragePanels ? this.options.deposit(this.energyBillCoveragePanels) : undefined;

        this.currentKiloWatts = this.currentPanels && this.options.kilowatts(this.currentPanels);
        this.extraKiloWatts = this.extraPanels && this.options.kilowatts(this.extraPanels);
        this.totalKiloWatts = this.totalPanels && this.options.kilowatts(this.totalPanels);

        this.currentEstimatedQuarterlyIncome = this.currentPanels && this.options.quarterlyPanelIncome(this.currentPanels);
        this.extraEstimatedQuarterlyIncome = this.extraPanels && this.options.quarterlyPanelIncome(this.extraPanels);
        this.totalEstimatedQuarterlyIncome = this.totalPanels && this.options.quarterlyPanelIncome(this.totalPanels);
        this.quarterlyEnergyBillCoveragePercent = this.quarterlyEnergyBill && this.totalEstimatedQuarterlyIncome && (this.totalEstimatedQuarterlyIncome / this.quarterlyEnergyBill) * 100;

        this.totalCost = this.totalPanels && this.options.cost(this.totalPanels);
        this.totalEstimatedIncome = this.totalPanels && this.options.estimatedIncome(this.totalPanels);
        this.totalSavings = this.totalEstimatedIncome !== undefined && this.totalCost !== undefined ? this.totalEstimatedIncome - this.totalCost : undefined;
    }
}
