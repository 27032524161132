import { Page } from "../components/Page";
import { TermsAndConditionsForm } from "../forms/TermsAndConditionsForm";
import { useQuerySummary } from "../hooks/QuerySummary";
import { useTermsAndConditionsQuery } from "../hooks/User";

import "./TermsAndConditionsPage.scss";

export const TermsAndConditionsPage = () => {
    const querySummary = useQuerySummary();
    const { data: termsAndConditions } = useTermsAndConditionsQuery(querySummary);

    const { isLoading } = querySummary.get();

    return (
        <Page title="Terms and conditions" isLoading={isLoading}>
            <TermsAndConditionsForm termsAndConditions={termsAndConditions!}/>
        </Page>
    );
}

export default TermsAndConditionsPage;
