import { CountryInfo } from "../api/SolarCloudApi";
import { CountryOptions, useCountries } from "../hooks/Countries";
import { QuerySummary } from "../hooks/QuerySummary";
import "./Country.scss";
import { Select } from "./Select";

interface Props {
    id?: string,
    key?: string,
    country?: CountryInfo | string,
    setCountry: (country?: CountryInfo) => void,
    options?: CountryOptions,
    placeholder?: string,
};

const useCountry = (
    props: Props,
    querySummary?: QuerySummary
) => {
    const { country: countryProperty, setCountry, options } = props;

    const { defaultCountry, setDefaultCountry, countries, countryFromCode } = useCountries(options, querySummary);
  
    const updateCountry = (country?: CountryInfo) => {
      if (options?.allowOverride) {
        setDefaultCountry(country);
      }
  
      setCountry(country);
    };

    const country = typeof countryProperty === "string" ? countryFromCode(countryProperty) : countryProperty;

    return {
      ...props,
      country,
      setCountry: updateCountry,
      countries,
      defaultCountry,
      countryFromCode,
    };
  };
  
export const Country = (props: Props) => {
    const {
        id,
        key,
        placeholder,
        country,
        setCountry,
        countries,
    } = useCountry(props)

    return (
        <Select<CountryInfo>
            id={id}
            key={key}
            placeholder={placeholder}
            values={countries}
            selection={country}
            keyOf={country => country.code!}
            textOf={country => country.name!}
            onSelection={setCountry}
        />
    );
}