import { ReactComponent as CarIcon } from "../res/icons/Car.svg";
import { ReactComponent as CloudIcon } from "../res/icons/Cloud.svg";
import { ReactComponent as DashboardIcon } from "../res/icons/Dashboard.svg";
import { ReactComponent as DocumentIcon } from "../res/icons/Document.svg";
import { ReactComponent as EnergyIcon } from "../res/icons/Energy.svg";
import { ReactComponent as GraphIcon } from "../res/icons/Graph.svg";
import { ReactComponent as PaymentIcon } from "../res/icons/Payment.svg";
import { ReactComponent as SettingsIcon } from "../res/icons/Settings.svg";
import { ReactComponent as SolarPanelIcon } from "../res/icons/SolarPanel.svg";
import { ReactComponent as SpinnerIcon } from "../res/icons/spinner-solid.svg";
import { ReactComponent as StatementIcon } from "../res/icons/Statement.svg";
import { ReactComponent as TrolleyIcon } from "../res/icons/Trolley.svg";

export const SolarCloudIcon = {
    Allocations: <PaymentIcon />,
    Car: <CarIcon />,
    Cloud: <CloudIcon />,
    Contact: <DocumentIcon />,
    Dashboard: <DashboardIcon />,
    Document: <DocumentIcon />,
    Energy: <EnergyIcon />,
    Graph: <GraphIcon />,
    Invoices: <DocumentIcon />,
    Location: <EnergyIcon />,
    Logout: <SettingsIcon />,
    Orders: <DocumentIcon />,
    Password: <SettingsIcon />,
    Payment: <PaymentIcon />,
    Settings: <SettingsIcon />,
    SolarPanel: <SolarPanelIcon />,
    Spinner: <SpinnerIcon />,
    Statement: <StatementIcon />,
    TermsAndConditions: <DocumentIcon />,
    Tracker: <DocumentIcon />,
    Trolley: <TrolleyIcon />,
};

export type SolarCloudIconType = React.ReactElement;
