import { OpenAPI as FinancialsApi, Order } from "../api/SolarCloudApi";
import { Button } from "../components/Button";
import { Panel } from "../components/Panel";
import { bemName } from "../util/bemName";

import { useMyOrdersQuery } from "../hooks/Order";
import { useQuerySummary } from "../hooks/QuerySummary";
import "./OrdersPanel.scss";
import { error } from "../components/Messages";
import { doFetch } from "../util/fetch";

const componentName = "OrdersPanel";

interface Props {
}

export const OrdersPanel: React.FunctionComponent<Props> = () => {
    const querySummary = useQuerySummary();
    const { data: orders } = useMyOrdersQuery(querySummary);

    const isLoading = querySummary.get().isLoading;

    const items =
        isLoading ?
            <div className={bemName(componentName, "loading")}></div>
            :
            orders?.map(
                (order, index) => <OrderItem key={index} order={order} />
            );

    return (
        <Panel title="Orders">
            <div className={bemName(componentName, "container")}>
                {items}
            </div>
        </Panel>
    )
}

type ItemProps = {
    order: Order;
};

const OrderItem: React.FunctionComponent<ItemProps> = ({order}) => {
    const downloadPdf = async () => {
        const token = 
            FinancialsApi.TOKEN === undefined ?
                undefined
                :
                typeof FinancialsApi.TOKEN === "string" ? 
                    FinancialsApi.TOKEN 
                    : 
                    await FinancialsApi.TOKEN({ method: "GET", url: `/api/financials/invoices/orders/${order.uuid}`});

        const pdfResponse = await doFetch(
            `${FinancialsApi.BASE}/api/financials/invoices/orders/${order.uuid}`,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }
        );

        if (!pdfResponse.ok) {
            error("Invoice PDF was not found", "Download invoice");
            return;
        }

        const pdfContents = await pdfResponse.blob();

        var url = window.URL.createObjectURL(pdfContents);

        const anchor = document.createElement("a");
        anchor.download = (order.orderNumberString || order.uuid) + ".pdf";
        anchor.target = "_blank";
        anchor.href = url;
        // logger.log("@@@@ anchor", anchor.href);
        try {
            document.body.append(anchor);

            anchor.click();
        } finally {
            document.body.removeChild(anchor);
        }
    }

    const items = order
        .items
        ?.map(
            (item) => {
                const panels = item.units! / item.product!.wattPanelSize!;
                const price = item.units! * item.unitPrice!;
                return (
                    <div key={item.uuid} className={bemName(componentName, "item")}>
                        <div className={bemName(componentName, "panels")}>Panels: {panels}</div>
                        <div className={bemName(componentName, "itemName")}>Name: {item.product?.name}</div>
                        <div className={bemName(componentName, "panelPrice")}>Total Cost: {Intl.NumberFormat(undefined, {style: "currency", currency: "AUD"}).format(price)}</div>
                    </div>
                );
            }
        );

    return (
        <div className={bemName(componentName, "item")}>
            <div className={bemName(componentName, "date")}>{Intl.DateTimeFormat(undefined, { dateStyle: "long" }).format(new Date(order.created))}</div>
            {/* <div className={bemName(componentName, "orderNumber")}>{order.orderNumber}</div>
            <div className={bemName(componentName, "tax")}>{Intl.NumberFormat(undefined, {style: "currency", currency: "AUD"}).format(order.totalTax!)}</div> */}
            <div className={bemName(componentName, "total")}>{Intl.NumberFormat(undefined, {style: "currency", currency: "AUD"}).format(order.totalPayment!)}</div>
            {/* <div className={bemName(componentName, "status")}>{order.status}</div> */}
            {
            <div className={bemName(componentName, "actions")}><Button text="Download invoice" onClick={() => downloadPdf()}/></div>

            }
            <div className={bemName(componentName, "items")}>
                {items}
            </div>
        </div>
    );
}

export default OrdersPanel;