import React from 'react';

import { Organisation, UserStatus } from '../api/SolarCloudApi';
import { Message } from '../components/Messages';
import { Feature } from '../hooks/Environment';
import { Authentication } from '../util/authentication';
import { logger } from '../util/logger';

export type MatchFunction = (name: string, needsUser?: boolean, status?: UserStatus | Array<UserStatus>, feature?: Feature | Array<Feature>) => boolean;

export type Profile = {
    authentication?: Authentication;
    organisation?: Organisation,
    setOrganisation: (organisation?: Organisation) => Promise<void> | void,
    email?: string,
    setEmail: (email?: string) => Promise<void> | void,
    country?: string,
    setCountry: (country? : string) => Promise<void> | void,
    messages: Message[],
    setMessages: (messages: Message[]) => void,
    match: MatchFunction;
};

const uninitialised = () => { logger.log("ERROR - Attempted to update an unitialised profile") };
const uninitialisedMatch: MatchFunction = (name, needsUser?, status?, feature?) => { logger.error("ERROR - Attempted to match with an uninitiailsed function"); return false; }

export const ProfileContext = React.createContext<Profile>(
    {
        // setUser: uninitialised,
        // setFederatedUser: uninitialised,
        setOrganisation: uninitialised,
        // setUserid: uninitialised,
        setEmail: uninitialised,
        setCountry: uninitialised,
        // setCountryData: uninitialised,
        // setOrder: uninitialised,
        // setCatalog: uninitialised,
        // setTotals: uninitialised,
        // setTrackerEvents: uninitialised,
        // setPowerGenerationInfo: uninitialised,
        // informationUpdated: uninitialised,
        messages: [],
        setMessages: uninitialised,
        match: uninitialisedMatch,
    }
);

export default ProfileContext;
