/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PayeeType {
    BPAY = 'BPay',
    BANK = 'Bank',
    ENERGY_COMPANY = 'EnergyCompany',
    PAY_PAL = 'PayPal',
}
