import { useMutation, useQuery, useQueryClient } from "react-query";
import { v4 as uuid } from 'uuid';
import { MintCard, Order, DefaultService as OrdersService } from '../api/SolarCloudApi';
import { CreditCardData } from "../components/CreditCardInput";
import { logger } from "../util/logger";
import { invalidateQueryDependencies } from "./Dependencies";
import { errorHandler } from "./Error";
import { getBuyPanels, setBuyPanels } from "./LocalStorage";
import { QuerySummary } from "./QuerySummary";
import { useUuid } from "./Utils";
import { Mutex } from "async-mutex";

// OrdersApi.BASE = "";

const newOrderMutext = new Mutex();

const NEW_ORDER_UUID_KEY = "newOrderUuid";

const getNewOrderUuid = () => {
    return localStorage.getItem(NEW_ORDER_UUID_KEY);
}

export const setNewOrderUuid = (uuid?: string | undefined) => {
    if (uuid) {
        localStorage.setItem(NEW_ORDER_UUID_KEY, uuid);
    } else {
        localStorage.removeItem(NEW_ORDER_UUID_KEY);
    }
}

export const useCatalogQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const query = useQuery(
        "catalog",
        async () => {
            // OrdersApi.TOKEN = getAccessToken;

            const response = await OrdersService.productCatalogue();

            return response.collection;
        },
        {
            staleTime: Infinity,
            onSuccess: () => {
                invalidateQueryDependencies("catalog");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateQuery(id, "catalog", query);

    return query;
}

const pendingOrderUpdates: { [uuid: string]: Order } = {};

export const useNewOrderQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const query = useQuery(
        "newOrder",
        async () => {
            const buyPanels = getBuyPanels();

            // logger.log("@@@@ buyPanels:", buyPanels);

            const newOrderUuid = getNewOrderUuid();

            const effectiveUuid = newOrderUuid ?? uuid();

            const pendingOrder = pendingOrderUpdates[effectiveUuid];

            if (pendingOrder) {
                // logger.log("@@@@ newOrder is pending");
                return pendingOrder;
            }

            // OrdersApi.TOKEN = getAccessToken;

            // logger.log("@@@@ Loading newOrder from server", newOrderUuid);

            try {
                const serverOrder = await newOrderMutext.runExclusive(
                    async () => await OrdersService.newOrderGet(effectiveUuid, buyPanels)
                );

                // logger.log("@@@@ Loaded newOrder from server", serverOrder);
    
                setNewOrderUuid(serverOrder.uuid);
                setBuyPanels(undefined);
    
                return serverOrder;
            } catch (error: unknown) {
                // logger.log("@@@@", "newOrder error:", error);

                const newOrderUuid = uuid();

                const serverOrder = await newOrderMutext.runExclusive(
                    async () => await OrdersService.newOrderGet(newOrderUuid, buyPanels)
                );

                // logger.log("@@@@ Loaded newOrder from server", serverOrder);
    
                setNewOrderUuid(serverOrder.uuid);
                setBuyPanels(undefined);
    
                return serverOrder;
            }
        },
        {
            staleTime: Infinity,
            onError: errorHandler,
        }
    );

    querySummary?.updateQuery(id, "newOrder", query);

    return query;
}

export const useIsNewOrderUpdatingQuery = (order?: Order, querySummary?: QuerySummary) => {
    const id = useUuid();
    
    const query = useQuery(
        [ "isOrderUpdating", order?.uuid ],
        () => {
            if (!order?.uuid)
                return Promise.resolve(true);

            return Promise.resolve(pendingOrderUpdates[order.uuid!] !== undefined);
        },
        {
            onError: errorHandler,
        }
    );

    querySummary?.updateQuery(id, "isNewOrderUpdating", query);

    return query;
}

const pendingTimeoutIds: { [uuid: string]: NodeJS.Timeout } = {};

export const useNewOrderMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const queryClient = useQueryClient();

    const mutation = useMutation(
        async (order: Order) => {
            // logger.log("@@@@ mutating newOrder", order);

            const oldTimeoutId = pendingTimeoutIds[order.uuid!];
            if (oldTimeoutId) {
                clearTimeout(oldTimeoutId);
                delete pendingTimeoutIds[order.uuid!];
            }

            queryClient.setQueryData(
                [ "newOrder", order.uuid ], 
                { ...order, correlationId: undefined }
            );

            setNewOrderUuid(order.uuid);

            pendingOrderUpdates[order.uuid!] = order;

            queryClient.invalidateQueries([ "isOrderUpdating", order.uuid ]);

            queryClient.setQueryData("newOrder", order);

            pendingTimeoutIds[order.uuid!] = setTimeout(
                async () => {
                    try {
                        const pendingOrder = {
                            ...order,
                            correlationId: uuid(),
                        };

                        pendingOrderUpdates[pendingOrder.uuid!] = pendingOrder;
            
                        // logger.log("@@@@ Updating server order", pendingOrder);
    
                        // OrdersApi.TOKEN = getAccessToken;
                        const updatedOrder = await newOrderMutext.runExclusive(
                            async () => await OrdersService.newOrderUpsert(pendingOrder.uuid!, pendingOrder)
                        );

                        // logger.log("@@@@ updatedOrder:", updatedOrder);
        
                        if (updatedOrder.correlationId === pendingOrderUpdates[pendingOrder.uuid!].correlationId) {
                            queryClient.setQueryData(
                                [ "newOrder", updatedOrder.uuid ], 
                                {
                                    ...updatedOrder,
                                    correlationId: undefined,
                                }
                            );
    
                            delete pendingOrderUpdates[pendingOrder.uuid!];
    
                            queryClient.invalidateQueries([ "isOrderUpdating", pendingOrder.uuid ]);

                            queryClient.setQueryData(
                                "newOrder",
                                {
                                    ...updatedOrder,
                                    correlationId: undefined,
                                }
                            );

                            setNewOrderUuid(updatedOrder.uuid);

                            // logger.log("@@@@ Updated server order", updatedOrder);
                        } else {
                            // logger.log("@@@@ CorrelationIds do not match", updatedOrder.correlationId, pendingOrderUpdates[pendingOrder.uuid!].correlationId);
                        }
                    } catch (error: unknown) {
                        logger.error("@@@@ Failed to updated server order", order);
                    }
                },
                333
            );

            return order;
        },
        {
            onSuccess: () => {
                invalidateQueryDependencies("newOrder");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "newOrder", mutation);

    return mutation;
}

export const useNewOrderCheckoutMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const queryClient = useQueryClient();

    const mutation = useMutation(
        async (order: Order) => {
            // logger.log("@@@@ mutating useNewOrderCheckoutMutation", order);

            const updatedOrder = await newOrderMutext.runExclusive(
                async () => await OrdersService.newOrderCheckout(order.uuid!)
            );

            setNewOrderUuid(updatedOrder.uuid);

            queryClient.setQueryData(
                "newOrder",
                {
                    ...updatedOrder,
                    correlationId: undefined,
                }
            );

            return order;
        },
        {
            onSuccess: () => {
                invalidateQueryDependencies("newOrderCheckout");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "newOrderCheckout", mutation);

    return mutation;
}

export const useNewOrderCheckoutCancelMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const queryClient = useQueryClient();

    const mutation = useMutation(
        async (order: Order) => {
            // logger.log("@@@@ mutating useNewOrderCheckoutCancelMutation", order);

            const updatedOrder = await newOrderMutext.runExclusive(
                async () => await OrdersService.newOrderCheckoutCancel(order.uuid!)
            );

            // logger.log("@@@@ mutated useNewOrderCheckoutCancelMutation", order);

            setNewOrderUuid(updatedOrder.uuid);

            queryClient.setQueryData(
                "newOrder",
                {
                    ...updatedOrder,
                    correlationId: undefined,
                }
            );

            return updatedOrder;
        },
        {
            onSuccess: () => {
                invalidateQueryDependencies("newOrderCheckout");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "newOrderCheckout", mutation);

    return mutation;
}

export const useNewOrderMintPaymentMutation = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const queryClient = useQueryClient();

    const mutation = useMutation(
        async ({ order, cardData }: { order: Order, cardData: CreditCardData }) => {
            // logger.log("@@@@ mutating useNewOrderMintPaymentMutation", order, cardData);

            const mintCardData: MintCard = {
                number: cardData.number,
                expiryMonth: parseInt(cardData.expiry!.substring(0, 2)),
                expiryYear: parseInt(cardData.expiry!.substring(2, 4)),
                cvc: cardData.cvc!,
                holderName: cardData.name,
            }

            const updatedOrder = await newOrderMutext.runExclusive(
                async () => await OrdersService.newOrderMintPayment(order.uuid!, mintCardData)
            );

            setNewOrderUuid(updatedOrder.uuid);

            queryClient.setQueryData(
                "newOrder",
                {
                    ...updatedOrder,
                    correlationId: undefined,
                }
            );

            queryClient.invalidateQueries("authentication");

            return order;
        },
        {
            onSuccess: () => {
                invalidateQueryDependencies("newOrderMintPayment");
            },
            onError: errorHandler,
        }
    );

    querySummary?.updateMutation(id, "newOrderMintPayment", mutation);

    return mutation;
}

export const useMyOrdersQuery = (querySummary?: QuerySummary) => {
    const id = useUuid();

    const query = useQuery(
        "myorders",
        async () => {
            // OrdersApi.TOKEN = getAccessToken;

            const response = await OrdersService.myOrderSearch();

            return response.collection;
        },
        {
            staleTime: 300000,
            onError: errorHandler,
        }
    );

    querySummary?.updateQuery(id, "myOrders", query);

    return query;
}
