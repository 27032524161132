/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StatusEnum {
    AUTHORISED = 'AUTHORISED',
    DELETED = 'DELETED',
    DRAFT = 'DRAFT',
    PAID = 'PAID',
    SUBMITTED = 'SUBMITTED',
    VOIDED = 'VOIDED',
}
