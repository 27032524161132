import { Order } from "../api/SolarCloudApi";
import { FormControl, NavigationButtons } from "../panel/BuyPanelsPanel";
import { bemName } from "../util/bemName";
import { PaymentSummaryForm } from "./PaymentSummaryForm";

import "./PaymentAcknoledgeForm.scss";
import ReferAFriend from "../components/ReferAFriend";
import { TestimonialForm } from "./TestimonialForm";

const componentName = "PaymentAcknowledgeForm";

type PaymentAcknowledgeFormProps = {
    order?: Order;
    formControl: FormControl;
}

export const PaymentAcknowledgeForm = (props: PaymentAcknowledgeFormProps) => {
    const { order, formControl } = props;

    return (
        <div className={bemName(componentName)}>
            <div className={bemName(componentName, "paymentDetails")}>
                <PaymentSummaryForm order={order} />
            </div>
            <div className={bemName(componentName, "textSection")}>
                <p className={bemName(componentName, "text")}>We’d like to thank you for your payment
made today. </p>
            </div>
            <NavigationButtons formControl={formControl} />
            <TestimonialForm />
            <ReferAFriend/>
        </div>
    );
}